﻿.featured {
    margin-bottom: 50px;

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    h3 {
        font-weight: 300;
        font-size: 1em;
        text-align: center;
        margin: 15px auto;
        padding: 0 8px;

        @include media('<=phone') {
            font-size: 0.95em !important;
            min-height: 40px;
        }
    }

    span {
        text-align: center;
        color: $color-text;
        display: block;
        margin: 0 auto 20px;
        padding: 0 8px;
    }

    .section {
        margin: 0 -5px;
    }

    .dw-md-3 {
        @include media('<desktop','>tablet') {
            width: 315px !important;
        }

        @include media('>tablet') {
            width: 200px;
            padding: 0 7px;
        }
    }
}


.feat__prod {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    border: 1px solid $color-light-grey;
    margin: 0 auto 10px;

    span {
        color: $color-dark-grey;
        font-size: .85em;
        min-height: 50px;

        @include media('<=phone') {
            display: none;
        }
    }

    .feat__prod--image {
        background-color: $color-light-pink;

        img {
            margin: auto;
            display: block;
        }
    }
}


