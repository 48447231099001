﻿@import 'global/vars';
@import 'global/typography';
@import 'vendor/includemedia';
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-transition: transform .1s ease-in-out;
    -ms-transition: transform .1s ease-in-out;
    transition: background-color .1s linear, color .1s, transform .1s ease-in-out, max-height 0.3s ease-in-out, opacity .2s ease-in-out, background-image 0.2s ease-in-out, box-shadow 0.2s;
}

.top-banner {
    position: relative;
    text-align: center;
    padding: 5px;
    background-color: #75c4ed;
    color: #fff;
    overflow: hidden;

    .container.xmass {
        width: 90%;

        span {
           color:#f35446; font-weight:700;
        }
    }

    .gift-1 {
        position: absolute;
        left: 5px;
    }

    .gift-2 {
        position: absolute;
        right: 5px;
    }
}

@media screen and (max-width: 800px ) {
    .top-banner {
        .container.xmass {
            width: 75%;
        }

        .gift-1 {
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }

        .gift-2 {
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.top-orange-bar {
    background-color: #F18C18;
    color: #FFF;
    text-align: center;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 30px;
    font-size: 1.2rem;
    @include media('<=tablet') {
        font-size:0.9rem;
        img {
            display: none !important;
        }
    }
}

.rel {position: relative;}

.lnk {
    cursor: pointer;

    &.white {
        color: #fff;
    }

    &.ul {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.center-img img{display: block; margin: 0 auto;}

.content-w {width: max-content;}

.mb-15{margin-bottom: 15px;}
.mb-30{margin-bottom: 30px;}

.mt-5{margin-top: 5px;}

.grey-bord{border: 1px solid #ccc;}

.dblue-bg {background-color: #1D4384;}

.white-txt {color: #fff;}

.rad-3 {border-radius: 3px;}

.py-15 {padding-top: 15px; padding-bottom: 15px;}
.py-30 {padding-top: 30px; padding-bottom: 30px;}

.cent-block {margin: 0 auto;}

.fl-dir-col {
    flex-direction: column;
}

.bord {
    border: 1px solid #ccc;
}

#baskettotal {
    
    @include media ('>=tablet') {
       position: sticky;
       top: 15px;
       margin-bottom: 30px;
    }


}

#discountform.checkout-discount { position: sticky; top: 200px; }

.flx-bx {display: flex;
         &.fl-al-cent {align-items: center;}
         &.fl-ju-cent {justify-content: center;}
         &.gap-30 {gap: 30px;}
}

.fl-col-rev {
    display: flex;
    flex-direction: column-reverse;
}

.container.large {
    max-width: 1600px;
    width: auto;
}

a:focus {
    outline: none;
}

.blue-txt {
    color: #1D4384;
}

h1.loc::after {
    @include media('<=tablet') {
        display: none !important;
    }
}

h1.loc::before {
    @include media('<=tablet') {
        display: none !important;
    }
}

h1.alt{
    &:before {display: none !important;}
    &:after {display: none !important;}
    text-align: left;
    line-height: 1.2em;
    .sub {font-size: 0.8em; font-weight: 500;}
}

h2.alt {
    &:before {
        display: none !important;
    }

    &:after {
        display: none !important;
    }

    text-align: left;
    line-height: 1.2em;
    margin: 0px;
    font-weight: 500;

    .sub {
        font-size: 0.8em;
        font-weight: 500;
    }
}

h3.alt {
    &:before {
        display: none !important;
    }

    &:after {
        display: none !important;
    }

    text-align: left;
    line-height: 1.2em;
    margin: 0px;
    font-weight: 500;

    .sub {
        font-size: 0.8em;
        font-weight: 500;
    }
}

.feat__pop {
    position: relative;
    display: block;
    margin-bottom: 30px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;

    i {
        height: 70px;
        width: 70px;
        text-align: center;
        bottom: 15px;
        left: 30px;
        position: absolute;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        -ms-transform: translate(-50% -50%);
        background-color: #72c5ec;
        color: #fff;
        line-height: 70px;
        border-radius: 100%;
        font-size: 2em;
        z-index: 9999;

        @media screen and (max-width:500px) {
            font-size: 1.5em;
            height: 50px;
            width: 50px;
            line-height: 50px;
        }
    }

    figcaption {
        background-color: #45444480;
        display: block;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: $color-white;
        margin: 0;
        padding: 15px 10px;
        transition: background-color .25s ease;
    }

    @include media('>phone') {
        &:hover figcaption {
            background-color: $color-orange;
        }
    }

    &.on figcaption {
        background-color: $color-orange;
    }
}

body {
    color: $color-text;
    background-color: $color-white;
    margin: 0;
    padding: 0;
    text-size-adjust: 100%;
}

.alice-blue {
    color: $color-primary;
}

h3.subhead {
    display: inline;
}

.back--top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    text-decoration: none;
    z-index: 9999;
    font-size: 2em;
    display: none;
    cursor: pointer;

    i {
        color: $color-primary;
    }

    &:hover {
        i {
            color: $color-orange;
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}

.custom--highlight {
    color: $color-ligh-blue;
}

.findAddress {
    margin: 10px 0 !important;
}

.brands {
    margin-top: 40px;

    &.home {
        margin-top: 0;
    }
}

.bottom__images {
    margin-bottom: 20px;

    @include media('<=tablet') {
        margin-bottom: 15px;
    }


    .gallery__image {
        float: left;


        @include media('>tablet') {
            width: 16.666%;
        }

        @include media('<=tablet') {
            width: 33.333%;
        }

        @include media('<=550px') {
            width: 50%;
        }
    }

    .brands {
        margin: 30px auto;

        @include media('<=phone') {
            margin: 25px auto;
        }

        img {
            margin: auto;
        }
    }
}

.notice {
    background-color: $color-primary;
    padding: 25px;
    text-align: center;
    color: $color-white;
    margin-bottom: 55px;

    .notice__caps {
        i {
            margin-right: 10px;
        }

        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2em;
    }
}

.contact--password {
    .btn--black {
        line-height: 40px !important;
        height: 40px !important;
        margin: 40px auto !important;
    }

    .internal--body {
        margin: auto;
    }
}

.contact--privacy {
    font-size: 0.75em;
    display: block;
    margin-bottom: 15px;
    text-align: center;
}

.contact__map {
    margin-top: 20px;

    iframe {
        width: 100%;
        min-height: 375px;
        border: 0;
        margin: auto;
        display: block;

        @include media('<=tablet') {
            margin-bottom: 20px;
        }
    }
}

.dash--break {
    margin: 0 5px;
    color: $color-primary;
}

.breadcrumb {
    font-size: .8em;
    margin: 25px 0 30px;
    font-weight: 300;

    a {
        font-weight: 700;
    }

    @include media('<=tablet') {
        margin: 15px 0;
    }
}

.breadcrumb--hide {
    display: none;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
    display: block;
}

.white-bg {background-color: #fff;}
.grey-bg {background-color: #eaeaea; }
.lgrey-bg {background-color: #f9f9f9; }

.p-10, .p10{padding: 10px;}
.p-15, .p15{padding: 15px;}
.p-20, .p20{padding: 20px;}
.p-30, .p30{padding: 30px;}
.pt-20, .pt20{padding-top: 20px;}
.pt-30, .pt30{padding-top: 30px;}
.px-15, .px30{padding-left: 15px; padding-right: 15px;}
.px-30, .px30{padding-left: 30px; padding-right: 30px;}

.r-5, .r5 {border-radius: 5px;}

.mt-15 {margin-top: 15px;}
.mt-30 {margin-top: 30px;}
.mt-20 {margin-top: 20px;}
.mt-15 {margin-top: 15px;}
.mb-15 {margin-bottom: 15px;}
.mb-30 {margin-bottom: 30px;}

.dblue-bord {border: 1px solid #1D4384; }

.blue-txt {color: #1D4384;}
.orange-txt {color: #F18C18;}

.nomarg {margin: 0px !important;}

.min-w-150 {min-width: 150px;}

.new-txt-lnk {width: max-content; display: flex; gap: 15px; font-size: 2.0em; text-decoration: none; align-items: center; font-weight: 200;}

.md-txt {font-size: 1.2em;}
.lg-txt {font-size: 1.7em;}
.xlg-txt {font-size: 5em;}

.fw100 {
    font-weight: 100;
}

.fw200 {
    font-weight: 200;
}

.fw300 {
    font-weight: 300;
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.fw800 {
    font-weight: 800;
}

.fw900 {
    font-weight: 900;
}

.g-recaptcha div, .g-recaptcha {
    height: 50px;
    max-width: 302px;
    margin: auto;
}

.g-recaptcha div {
    margin-top: -14px;
}

.g-recaptcha {
    border-right: 1px solid #d3d3d3;
    overflow: hidden;
    border-bottom: 1px solid #d3d3d3;
    border-top: 1px solid #d3d3d3;
    margin-top: 0px;
}

#map {
    height: 250px;
    width: 100%;
    margin-top: 10px;
    max-width: 718px;
    &.full-w {
        max-width: 100% !important;
        iframe {min-height: 250px;}
    }
}

.breadcrumb {
    a {
        text-decoration: none;
    }
}

.search--title {
    &:before, &:after {
        border-top: none;
    }
}

.contact {
    h1 {
        margin-bottom: 25px;
        font-weight: 500;
    }
}

.contact__address {
    color: $color-text;

    @include media('<=phone') {
        margin-bottom: 10px;
    }

    i {
        background-color: $color-orange;
        height: 30px;
        width: 30px;
        border-radius: 50px;
        text-align: center;
        line-height: 30px;
        color: $color-white;
        margin-right: 15px;
    }

    span.supporticon {
        margin-right: 15px;
        height: 30px;
        width: 30px;
        background-image: url('../img/support-icon.jpg');
        float: left;
        display: block;
    }

    .social__media {
        text-align: left;
    }

    .fa-phone {
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .contact--text {
        color: $color-text;
        font-weight: 400;
        font-size: 0.85em;
        padding: 0 40px 0 0;
        margin: 20px 0;
    }

    .contact--text.bottom {
        margin-bottom: 0;
    }

    .contact--head {
        color: $color-text;
        font-weight: 600;
        font-size: 1em;
        display: inline-block;
    }

    .contact--times {
        color: $color-orange;
        font-size: 1em;
        font-weight: 600;
    }

    span {
        color: $color-bg-hero;
        font-weight: 500;
        font-size: 1.2em;

        a {
            font-size: 0.8em;
            color: $color-orange;
            margin-left: 5px;

            @include media('<tablet','>phone') {
                display: block;
                margin-left: 0;
            }
        }
    }
}

.contact--times {
    color: $color-orange;
    font-weight: bold;
}

.contact--dets {
    margin-bottom: 35px;
    display: block;
    font-weight: 600;

    @include media('<=phone') {
        margin-bottom: 15px;
    }

    .custom--highlight {
        a {
            color: $color-ligh-blue;

            &:hover {
                color: $color-primary;
            }
        }
    }

    a {
        font-weight: 700;
        color: $color-orange;
        text-decoration: none;

        &:hover {
            color: $color-primary;
        }
    }
}

.auto-overfl {overflow: auto;}

.social--icons {
    color: $color-primary;
    font-weight: normal;
    margin-right: 25px;
    font-size: 1.4em;

    &:hover {
        color: $color-text;
    }
}


.socials {
    display: block;
    margin-top: 15px;
}

.contact__form {
    background-color: $color-grey;
    border: 1px solid $color-grey;
    border-radius: 2px;
    padding: 30px 35px;
    text-align: center;
    margin: auto;

    @include media('<desktop','>tablet') {
        min-height: 788px;
    }

    i {
        display: inline-block;
        color: $color-primary;
        font-size: 2em;
        margin-right: 15px;
    }

    h2 {
        display: inline-block;
        margin-bottom: 15px;
        color: $color-bg-hero;
        font-size: 1.2em;
        font-weight: 500;

        &:before, &:after {
            border-top: none;
        }
    }

    .editor-label {
        text-align: left;
        margin-bottom: 12px;
        color: $color-text;
        font-weight: 400;
        font-size: 0.85em;
    }

    .editor-field {
        margin-bottom: 20px;

        em {
            text-align: left;
            font-size: 0.85em;
            display: block;
        }

        input, textarea {
            border-radius: 2px;
        }

        textarea {
            height: 100px;
        }

        
    }

    .g-recaptcha {
        margin: 15px auto;
    }

    .required {
        color: $color-primary;
    }

    .btn {
        height: 40px;
        line-height: 40px;
        @include primary-font-stack;
        font-weight: 500;
        margin-top: 30px;
    }

    .btn--thin {
        line-height: 40px;
        height: 40px;
        margin: 40px auto !important;
    }
}

button {
    outline: 0;
    font-family: inherit;
}

input[type="submit"], input[type="button"] {
    cursor: pointer;
    text-align: center;
    max-width: 100%;
    line-height: 40px;
    height: 40px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 3px;
}

input[type="submit"], input[type="text"], input[type="password"] {
    -webkit-appearance: none;
}

.editor-label {
    label {
        color: #1D4384;
        font-size: 1.18em;
    }
}

.editor-field {
    input [type=number], input[type=password], input[type=tel], input[type=date], select {
        border: 1px solid #1D4384;
        height: 55px;
        border-radius: 3px;
        color: #2e6ad0;
    }

    .side-btn {
        display: flex;
        gap: 15px;
        max-width: 760px;

        .btn {
            height: 55px;
            margin: 0px;
            font-size: 1.3em;
            width: 310px;
        }
    }
}

.editor-check {
    color: #1D4384;
    font-size: 1.18em;


    label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    input {
        display: none;
    }

    i {
        display: flex;
        width: 35px;
        height: 35px;
        border: 1px solid #1D4384;
        border-radius: 3px;
        margin-right: 15px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        color: transparent;
    }

    &.on {
        i {
            color: #1D4384;
        }
    }
}

.map-container {
    width: 100%;
    iframe {
        width: 100%;
        max-width: 100%;

    }
}

.btn {
    cursor: pointer;
    width: 100%;
    line-height: 1;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    font-size: 0.9em;
    font-weight: 500;
    font-style: normal;
    border-radius: 3px;
    text-decoration: none;
    padding: 0px 15px;

    &.new {
        height: auto;
        min-height: 40px; 
        padding: 10px 15px;
        line-height: 1;
        
    }

    



    &.minw {
        min-width: 100px;
    }

    &.large {
        height: 55px;
        max-width: 100%;
    }

    &.blue {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;

        &:hover {
            background-color: $color-orange;
            border: 1px solid $color-orange;
            color: $color-white;
        }
    }

    &.lg-txt {font-size: 1.5em;}

    &.no-rad{border-radius: 0px;}
    &.no-marg{margin: 0px;}
} 

.btn--right {
    float: right;
}

.btn--primary.confirm--selection {
    &:hover {
        background-color: $color-orange;
        border: 1px solid $color-orange;
    }
}

.btn--primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $color-white;

    &:hover {
        background-color: $color-orange;
        border: 1px solid $color-orange;
        color: $color-white;
    }
}

.warrant-btn {
    max-width: 420px;
    margin: 30px auto 20px auto;

    i {
        font-size: 1.3em;
    }
}

.btn--dark-blue {
    background-color: $color-bg-hero;
    border: 1px solid $color-bg-hero;
    color: $color-white;

    &:hover {
        background-color: $color-orange;
        border: 1px solid $color-orange;
        color: $color-white;
    }
}

.btn--spec {
    img {
        display: inline-block;
        margin-right: 15px;
    }
}

.btn--blue {
    background-color: $color-baby-blue;
    border: 1px solid $color-baby-blue;
    color: $color-white;


    &:hover {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;
    }

    &.selected {
        background-color: #F18C18;
        border: 1px solid #F18C18;

        &:hover {
            background-color: #F18C18;
            border: 1px solid #F18C18;
        }
    }
}

.btn--orange {
    background-color: $color-orange;
    border: 1px solid $color-orange;
    color: $color-white;

    &:hover {
        background-color: $color-baby-blue;
        border: 1px solid $color-baby-blue;
        color: $color-white;
    }
}

.btn--pink {
    background-color: $color-pink;
    border: 1px solid $color-pink;
    color: $color-white;

    &:hover {
        background-color: $color-dark-pink;
        border: 1px solid $color-dark-pink;
        color: $color-white;
    }
}

.btn--grey {
    background-color: $color-text;
    border: 1px solid $color-text;
    color: $color-white;

    &:hover {
        background-color: $color-dark-grey;
        border: 1px solid $color-dark-grey;
        color: $color-white;
    }
}

.btn--lgrey {
    background-color: $color-grey;
    border: 1px solid $color-grey;
    color: $color-text;

    &:hover {
        background-color: $color-dark-grey;
        border: 1px solid $color-dark-grey;
        color: $color-white;
    }
}

.btn--black {
    background-color: $color-dark;
    border: 1px solid $color-dark;
    color: $color-white;

    &:hover {
        background-color: $color-black;
        border: 1px solid $color-black;
        color: $color-white;
    }
}

.btn--green {
    background-color: $color-green;
    border: 1px solid $color-green;
    color: $color-white;

    &:hover {
        background-color: $color-dark-green;
        border: 1px solid $color-dark-green;
        color: $color-white;
    }
}

.btn--white {
    background-color: $color-white;
    border: 1px solid $color-white;
    color: $color-bg-hero;

    &:hover {
        background-color: $color-baby-blue;
        border: 1px solid $color-baby-blue;
        color: $color-white;
    }
}

.loading-help .loading-special {
    position: relative;
    height: 40px;
    border: none;
    display: block;
    text-align: center;
    font-family: inherit;
}


.loading-help .new-loader {
    display: inline-block;
    color: $color-white;
    font-size: 4px;
    margin: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    margin-bottom: 2px;
}


.loading-help .load-text {
    line-height: 40px;
    display: inline-block;
    color: $color-white;
    text-align: center;
    font-size: 0.9em;
    -webkit-appearance: none;
    font-weight: 500;
    margin-left: 20px;
}

#askquestionform .load-text {
    color: #1D4384;
}
#askquestionform .new-loader {
    color: #1D4384;
}

input[type="text"], input[type="password"], input[type="tel"], input[type="number"] {
    width: 100%;
    height: 40px;
    padding: 0 0 0 10px;
    border: none;
    color: $color-primary;
    border: 1px solid $color-search;
    font-family: inherit;
    border-radius: 3px;
}

textarea {
    border: 1px solid #d4d4d4;
    padding: 10px 0 0 10px;
    color: $color-primary;
    min-height: 70px;
    width: 100%;
    font-family: inherit;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif;
}

select {
    background-color: $color-white;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: none;
    color: $color-text;
    border: 1px solid #d4d4d4;
    padding: 0 0 0 5px;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif;
}

.center-block {
    margin: 0 auto;
}

.max-w-570 {
    max-width: 640px;
}

.yell-txt {
    color: #FF8D00 !important;
}

textarea:focus, input:focus, select {
    outline: none;
}

h1 {
    font-size: 2em;
    color: $color-bg-hero;
    font-weight: 500;
}

h2 {
    font-size: 1.8em;
    font-style: normal;
    font-weight: 300;
    color: $color-bg-hero;
}

h3 {
    font-size: 1.3em;
    font-weight: 500;
    color: $color-bg-hero;
}

h1, h2, h3 {
    position: relative;
    overflow-x: clip;
    text-align: center;

    &:before {
        @include media('>desktop') {
            margin-left: -265px;
        }

        margin-left: -140px;
        text-align: right;
    }

    &:before, &:after {
        @include media('>phone') {
            position: absolute;
            top: 51%;
            overflow: hidden;
            width: 100%;
            height: 1px;
            content: '';
            border-top: 1px solid $color-bg-hero;

            @include media('>desktop') {
                max-width: 250px;
            }

            max-width: 125px;
        }
    }

    &:after {
        margin-left: 15px;

        @include media('<=375px') {
            margin-left: 0;
        }
    }
}

h2.altstyle {
    &:before {
        display: none;
    }

    &:after {
        display: none;
    }

    text-align: left !important;
    font-size: 2.4em;
    width: 100%;
    max-width: 100%;
    padding: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-weight: 500;
    line-height: 1.3;

    @include media('>tablet') {
        font-size: 2.4em;
    }

    @include media('<=tablet') {
        font-size: 1.4em;
    }
}


h3.altstyle {
    &:before {
        display: none;
    }

    &:after {
        display: none;
    }

    text-align: left !important;
    font-size: 1.9em;
    width: 100%;
    max-width: 100%;
    padding: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-weight: 400;
    line-height: 1.3;

    @include media('>tablet') {
        font-size: 1.9em;
    }

    @include media('<=tablet') {
        font-size: 1.2em;
    }
}

.center-txt {
    text-align: center;

    em {
        font-size: 0.75em;
    }
}

.orang-txt {
    color: #ff6a00;
}

#content {
    position: relative;
    z-index: 99;



    &:before {
        position: absolute;
        content: "";
        left: 0px;
        width: 100%;
        display: block;
        top: 0px;
        z-index: 100;
    }

    &.noshadow {
        &:before {
            box-shadow: none;
        }
    }

    @include media('<=tablet') {
        margin-top: 75px;
        overflow: hidden;
    }
}

a {
    text-decoration: underline;
    color: $color-primary;

    @include media('>phone') {
        &:hover {
            text-decoration: none;
            color: $color-orange;
        }
    }
}
/*Error Validation*/
.OK {
    padding: 10px;
    color: $color-white;
    background-color: $color-primary;
    margin: 20px 0;
    text-align: left;
    border-radius: 3px;
}

.build {
    .OK {
        max-width: 600px;
        margin: 30px auto;

        .msg {
            text-align: center;
            display: block;
        }

        .btn {
            max-width: 300px;
            margin: 15px auto 0px;
        }
    }
}

#atbMessage {
    margin: 15px auto 0px auto;
    text-align: center;
    max-width: 440px;

    .OK {
        text-align: center;
        background-color: $color-primary;
    }
}

.OK ul {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #fff;
}

.KO {
    padding: 10px;
    color: $color-white;
    background-color: $color-top-dark;
    margin-bottom: 10px;
    text-align: left;
    line-height:1.2;
    border-radius: 3px;
    min-height: 40px; 
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        color: $color-white;
    }
    li {
        color: $color-white !important;
    }
}

.Generic {
    padding: 10px;
    color: $color-white;
    background-color: $color-top-dark;
    margin-bottom: 10px;
}

.field-validation-error {
    color: $color-red !important;
    display: block;
    text-align: left;
    font-size: 0.8em;
    line-height: normal;
    line-height: initial;
    height: auto;
}

.validation-summary-errors ul, .validation-summary-errors ol {
    color: $color-red;
    margin-bottom: 15px;
}

.ui-autocomplete {
    /* display: block !important;*/
    background-color: #fff;
    width: 523px !important;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 99999;
    border-radius: 2px;
    border: 1px solid #e1e1e1;
    transform: translateY(20px);
    max-height: 300px;
    display: block;
    overflow: auto;
    max-width: 419px;
}

.ui-menu-item {
    color: $color-primary;
    cursor: pointer;
}

.ui-menu-item a {
    color: $color-text;
    display: block;
    padding: 10px 15px;
    font-size: 0.9em;
    text-decoration: none;
}

.ui-menu-item a:hover {
    background-color: $color-light-grey;
    color: $color-primary;
}

.ui-helper-hidden-accessible {
    display: none;
}

.outside i {
    display: none;
}

.background-lazy {
    position: relative;
    background-color: transparent;
    background-image: none !important;
    background: none !important;
}

.background-lazy:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: inline-block;
    color: $color-white;
    font-size: 5px;
    margin: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -o-animation: load4 1.3s infinite linear;
    -moz-animation: load4 1.3s infinite linear;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    content: "";
}

.ajaxLoader {
    min-height: 40px;
    line-height: 35px;
    text-align: center;
}

.ajaxLoader i {
    display: inline-block;
    color: $color-primary;
    font-size: 5px;
    margin: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -o-animation: load4 1.3s infinite linear;
    -moz-animation: load4 1.3s infinite linear;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    content: "";
}

@media screen and (max-width: 991px) {
    #headerright .navigation {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: auto;
        background-color: $color-white;
    }
}

@media screen and (min-width: 768px) {
    #delivery-options label .details strong {
        width: 45%;
        float: left;
    }

    #delivery-options label .details span {
        width: 55%;
        float: right;
        padding-left: 15px;
        position: relative;
    }
}

@media screen and (max-width: 767px) {
    #headerright .accounts {
        text-align: center;
        margin-top: 20px;
    }

    #headerright .log.browse {
        padding: 10px;
    }

    #headerright .log.browse.navopen {
        background-color: #004d94;
    }

    #headerright .log.browse.navopen a, #headerright .log.browse.navopen i {
        color: #fff;
    }

    #headerright .log, #headerright .cart {
        float: none;
        display: inline-block !important;
    }

    .address-checked {
        padding: 10px 10px 0;
    }

    #delivery-options label.delivery-opt {
        display: flex;
        gap: 10px;
        padding: 20px 10px;
        justify-content: center;
        align-items: center;
    }

    #delivery-options label .rb {
        position: static;
        transform: unset;
        flex-grow: 1;
        flex-shrink: 1;
    }

    #delivery-options .delivery-opt .details span > i {
        position: static;
        display: inline-block;
        transform: unset;
        font-size: .7em;
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin-right: 5px;
    }

    #delivery-options label span.price {
        right: 10px;
        position: static;
        transform: unset;
        text-align: center;
    }

    #delivery-options label span.details {
        flex-grow: 1;
    }

    #delivery-options label span.details > span {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    #delivery-options label .details strong, #delivery-options label .details span {
        display: block;
        position: relative;
    }
}


@media screen and (max-width:400px) {
    .prod-listing .dw-xs-6 {
        width: 100%;
        float: none;
    }

    .basket .row.regular .col1 {
        padding: 0;
        min-height: 0;
    }

    .basket .row.regular .col1 img {
        display: none;
    }

    .basket .btn {
        max-width: 100%;
    }

    .basket .btn.grey {
        margin: 15px 0 0 0;
    }
}


@-ms-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@-o-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@-moz-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@-webkit-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

.marg-top-30 {
    margin-top: 30px !important;
}

.marg-top-15 {
    margin-top: 15px !important;
}

.section.sml-spc {
    margin: 0px -5px;

    .bs {
        padding: 0px 5px;
    }
}
.gap-15 {gap: 15px;}
.gap-20 {gap: 20px;}

.center-txt, .center-text {text-align: center;}

.full-w {width: 100%;}

.full-w {
    width: 100%;
}

.flx-bx {
    display: flex;
}

.fl-al-cent {
    align-items: center;
}

.fl-ju-cent {
    justify-content: center;
}

.fl-ju-end {
    justify-content: end;
}

.fl-ju-space {
    justify-content: space-between;
}

.fl-ju-space-a {
    justify-content: space-around;
}

.fl-wrap {
    flex-wrap: wrap;
}

.fl-r-rev {
    flex-direction: row-reverse;
}

.fl-al-end {
    align-items: end;
}

.fl-al-flend {
    align-items: flex-end;
}

.center-block {
    margin: 0 auto;
}

.max-w-100 {
    max-width: 100px
}

.max-w-110 {
    max-width: 110px
}

.max-w-120 {
    max-width: 120px
}

.max-w-130 {
    max-width: 130px
}

.max-w-140 {
    max-width: 140px
}

.max-w-150 {
    max-width: 150px
}

.max-w-160 {
    max-width: 160px
}

.max-w-170 {
    max-width: 170px
}

.max-w-180 {
    max-width: 180px
}

.max-w-190 {
    max-width: 190px
}

.max-w-200 {
    max-width: 200px
}

.max-w-210 {
    max-width: 210px
}

.max-w-220 {
    max-width: 220px
}

.max-w-230 {
    max-width: 230px
}

.max-w-240 {
    max-width: 240px
}

.max-w-250 {
    max-width: 250px
}

.max-w-260 {
    max-width: 260px
}

.max-w-270 {
    max-width: 270px
}

.max-w-280 {
    max-width: 280px
}

.max-w-290 {
    max-width: 290px
}

.max-w-300 {
    max-width: 300px
}

.max-w-310 {
    max-width: 310px
}

.max-w-320 {
    max-width: 320px
}

.max-w-330 {
    max-width: 330px
}

.max-w-340 {
    max-width: 340px
}

.max-w-350 {
    max-width: 350px
}

.max-w-360 {
    max-width: 360px
}

.max-w-370 {
    max-width: 370px
}

.max-w-380 {
    max-width: 380px
}

.max-w-390 {
    max-width: 390px
}

.max-w-400 {
    max-width: 400px
}

.max-w-410 {
    max-width: 410px
}

.max-w-420 {
    max-width: 420px
}

.max-w-430 {
    max-width: 430px
}

.max-w-440 {
    max-width: 440px
}

.max-w-450 {
    max-width: 450px
}

.max-w-460 {
    max-width: 460px
}

.max-w-470 {
    max-width: 470px
}

.max-w-480 {
    max-width: 480px
}

.max-w-490 {
    max-width: 490px
}

.max-w-500 {
    max-width: 500px
}

.max-w-510 {
    max-width: 510px
}

.max-w-520 {
    max-width: 520px
}

.max-w-530 {
    max-width: 530px
}

.max-w-540 {
    max-width: 540px
}

.max-w-550 {
    max-width: 550px
}

.max-w-560 {
    max-width: 560px
}

.max-w-570 {
    max-width: 570px
}

.max-w-580 {
    max-width: 580px
}

.max-w-590 {
    max-width: 590px
}

.max-w-600 {
    max-width: 600px
}

.max-w-610 {
    max-width: 610px
}

.max-w-620 {
    max-width: 620px
}

.max-w-630 {
    max-width: 630px
}

.max-w-640 {
    max-width: 640px
}

.max-w-650 {
    max-width: 650px
}

.max-w-660 {
    max-width: 660px
}

.max-w-670 {
    max-width: 670px
}

.max-w-680 {
    max-width: 680px
}

.max-w-690 {
    max-width: 690px
}

.max-w-700 {
    max-width: 700px
}

.max-w-710 {
    max-width: 710px
}

.max-w-720 {
    max-width: 720px
}

.max-w-730 {
    max-width: 730px
}

.max-w-740 {
    max-width: 740px
}

.max-w-750 {
    max-width: 750px
}

.max-w-760 {
    max-width: 760px
}

.max-w-770 {
    max-width: 770px
}

.max-w-780 {
    max-width: 780px
}

.max-w-790 {
    max-width: 790px
}

.max-w-800 {
    max-width: 800px
}

.max-w-810 {
    max-width: 810px
}

.max-w-820 {
    max-width: 820px
}

.max-w-830 {
    max-width: 830px
}

.max-w-840 {
    max-width: 840px
}

.max-w-850 {
    max-width: 850px
}

.max-w-860 {
    max-width: 860px
}

.max-w-870 {
    max-width: 870px
}

.max-w-880 {
    max-width: 880px
}

.max-w-890 {
    max-width: 890px
}

.max-w-900 {
    max-width: 900px
}

.max-w-910 {
    max-width: 910px
}

.max-w-920 {
    max-width: 920px
}

.max-w-930 {
    max-width: 930px
}

.max-w-940 {
    max-width: 940px
}

.max-w-950 {
    max-width: 950px
}

.max-w-960 {
    max-width: 960px
}

.max-w-970 {
    max-width: 970px
}

.max-w-980 {
    max-width: 980px
}

.max-w-990 {
    max-width: 990px
}

.max-w-1000 {
    max-width: 1000px
}

.max-w-1010 {
    max-width: 1010px
}

.max-w-1020 {
    max-width: 1020px
}

.max-w-1030 {
    max-width: 1030px
}

.max-w-1040 {
    max-width: 1040px
}

.max-w-1050 {
    max-width: 1050px
}

.max-w-1060 {
    max-width: 1060px
}

.max-w-1070 {
    max-width: 1070px
}

.max-w-1080 {
    max-width: 1080px
}

.max-w-1090 {
    max-width: 1090px
}

.max-w-1100{
    max-width: 1100px;
}

.max-w-1200 {
    max-width: 1200px
}

.max-w-1210 {
    max-width: 1210px
}

.max-w-1220 {
    max-width: 1220px
}

.max-w-1230 {
    max-width: 1230px
}

.max-w-1240 {
    max-width: 1240px
}

.max-w-1250 {
    max-width: 1250px
}

.max-w-1260 {
    max-width: 1260px
}

.max-w-1270 {
    max-width: 1270px
}

.max-w-1280 {
    max-width: 1280px
}

.max-w-1290 {
    max-width: 1290px
}

.max-w-1300 {
    max-width: 1300px
}

.max-w-1310 {
    max-width: 1310px
}

.max-w-1320 {
    max-width: 1320px
}

.max-w-1330 {
    max-width: 1330px
}

.max-w-1340 {
    max-width: 1340px
}

.max-w-1350 {
    max-width: 1350px
}

.max-w-1360 {
    max-width: 1360px
}

.max-w-1370 {
    max-width: 1370px
}

.max-w-1380 {
    max-width: 1380px
}

.max-w-1390 {
    max-width: 1390px
}

.max-w-1400 {
    max-width: 1400px
}

.max-w-1410 {
    max-width: 1410px
}

.max-w-1420 {
    max-width: 1420px
}

.max-w-1430 {
    max-width: 1430px
}

.max-w-1440 {
    max-width: 1440px
}

.max-w-1450 {
    max-width: 1450px
}

.max-w-1460 {
    max-width: 1460px
}

.max-w-1470 {
    max-width: 1470px
}

.max-w-1480 {
    max-width: 1480px
}

.max-w-1490 {
    max-width: 1490px
}

.max-w-1500 {
    max-width: 1500px
}

.max-w-1510 {
    max-width: 1510px
}

.max-w-1520 {
    max-width: 1520px
}

.max-w-1530 {
    max-width: 1530px
}

.max-w-1540 {
    max-width: 1540px
}

.max-w-1550 {
    max-width: 1550px
}

.max-w-1560 {
    max-width: 1560px
}

.max-w-1570 {
    max-width: 1570px
}

.max-w-1580 {
    max-width: 1580px
}

.max-w-1590 {
    max-width: 1590px
}

.max-w-1600 {
    max-width: 1600px
}

.max-w-1610 {
    max-width: 1610px
}

.max-w-1620 {
    max-width: 1620px
}

.max-w-1630 {
    max-width: 1630px
}

.max-w-1640 {
    max-width: 1640px
}

.max-w-1650 {
    max-width: 1650px
}

.max-w-1660 {
    max-width: 1660px
}

.max-w-1670 {
    max-width: 1670px
}

.max-w-1680 {
    max-width: 1680px
}

.max-w-1690 {
    max-width: 1690px
}

.max-w-1700 {
    max-width: 1700px
}

.max-w-1710 {
    max-width: 1710px
}

.max-w-1720 {
    max-width: 1720px
}

.max-w-1730 {
    max-width: 1730px
}

.max-w-1740 {
    max-width: 1740px
}

.max-w-1750 {
    max-width: 1750px
}

.max-w-1760 {
    max-width: 1760px
}

.max-w-1770 {
    max-width: 1770px
}

.max-w-1780 {
    max-width: 1780px
}

.max-w-1790 {
    max-width: 1790px
}

.max-w-1800 {
    max-width: 1800px
}

.max-w-1810 {
    max-width: 1810px
}

.max-w-1820 {
    max-width: 1820px
}

.max-w-1830 {
    max-width: 1830px
}

.max-w-1840 {
    max-width: 1840px
}

.max-w-1850 {
    max-width: 1850px
}

.max-w-1860 {
    max-width: 1860px
}

.max-w-1870 {
    max-width: 1870px
}

.max-w-1880 {
    max-width: 1880px
}

.max-w-1890 {
    max-width: 1890px
}

.max-w-1900 {
    max-width: 1900px
}

.max-w-1910 {
    max-width: 1910px
}

.max-w-1920 {
    max-width: 1920px
}

.max-w-1930 {
    max-width: 1930px
}

.max-w-1940 {
    max-width: 1940px
}

.max-w-1950 {
    max-width: 1950px
}

.max-w-1960 {
    max-width: 1960px
}

.max-w-1970 {
    max-width: 1970px
}

.max-w-1980 {
    max-width: 1980px
}

.max-w-1990 {
    max-width: 1990px
}

.max-w-2000 {
    max-width: 2000px
}

.max-w-2010 {
    max-width: 2010px
}

.max-w-2020 {
    max-width: 2020px
}

.max-w-2030 {
    max-width: 2030px
}

.max-w-2040 {
    max-width: 2040px
}

.max-w-2050 {
    max-width: 2050px
}

.max-w-2060 {
    max-width: 2060px
}

.max-w-2070 {
    max-width: 2070px
}

.max-w-2080 {
    max-width: 2080px
}

.max-w-2090 {
    max-width: 2090px
}

.min-h-300 {min-height: 300px;}
.min-h-330 {min-height: 330px;}
.min-h-540 {min-height: 540px;}
.min-h-600 {min-height: 600px;}