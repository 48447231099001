﻿.usps {
	border-bottom: 1px solid $color-line;

	a {
		color: $color-text;

		&:hover {
			.usp--bold {
				color: $color-orange;
			}
		}
	}
}

.usps {
	.bx-controls-direction {
		@include media('>tablet') {
			display: none;
		}
	}

	.bx-controls-direction a.fa-chevron-left {
		@include media('<phone') {
			left: 0px;
		}
	}
}

.usps__full {
	max-width: 1050px;
	margin: auto;

	@include media("<=tablet", ">phone") {
		max-width: 700px;

		.inner {
			max-width: 310px;
			margin: 0 auto;
		}

		.bx-wrapper .bx-controls-direction a.fa-chevron-left {
		}

		.bx-wrapper {
			.bx-controls-direction {
				.fa-chevron-left {
					left: -30px;
				}

				.fa-chevron-right {
					right: -30px;
				}
			}
		}
	}

	@include media("<=phone") {
		max-width: 300px;
		text-align: center;


		@include media(">xs-phone") {
			height: 80px;

			.bx-wrapper {
				height: 80px;

				.bx-viewport {
					height: 80px;
				}

				.usps__area {
					height: 80px;
				}
			}
		}

		@include media("<=xs-phone") {
			height: 135px;

			.bx-wrapper {
				height: 135px;

				.bx-viewport {
					height: 135px;
				}

				.usps__area {
					height: 135px;
				}
			}
		}

		.inner {
			text-align: left;
			display: inline-block;
		}

		.usps__area {
			width: 300px !important;
		}

		.bx-wrapper {


			.bx-controls-direction {
				.fa-chevron-left {
					left: -60px;
                    @include media("<=xs-phone") {
                        left: -30px;
                    }
				}

				.fa-chevron-right {
					right: -60px;
                    @include media("<=xs-phone") {
                        right: -30px;
                    }
				}
			}
		}
	}

	@include media("<=xs-phone") {
		max-width: 250px;

		.usp__circle {
			display: block;
			text-align: center;

			i {
				margin: 0 auto;
			}

			margin: 0px auto 10px;
		}

		.usps__area {
			width: 250px !important;
		}

		.bx-viewport {
			height: 136px !important;
		}

		.usp--around {
			text-align: center;
		}
	}
}

.usps__area {
	padding: 15px 0;

	@include media('<=tablet') {
		

	}

	
}

.usp__circle {
	vertical-align: middle;
	display: inline-block;
	margin-right: 10px;
	height: 50px;
	width: 50px;
	border: 1px solid $color-line;
	border-radius: 50%;

	i {
		text-align: center;
		color: $color-primary;
		display: block;
		font-size: 1.5em;
		line-height: 50px;
	}

	
}

.usp--around {
	display: inline-block;
	vertical-align: middle;
}

.usp--bold {
	color: $color-bg-hero;
	font-weight: 500;

	@include media('<desktop','>tablet') {
		font-size: 0.8em;
	}
}

.usp--text {
	display: block;
	font-size: 0.9em;
	color: $color-text;

	@include media('<desktop','>tablet') {
		font-size: 0.75em;
	}
}
