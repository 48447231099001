﻿﻿﻿*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:before, .container:after, .section:before, .section:after {
    display: table;
    content: " ";
}

.container:after, .section:after {
    clear: both;
}

.clear {
    clear: both;
    display: block;
}

.svg-inline--fa.fa-w-18 {
    width: 60px;
    color: #2e6ad0;
}

.mb-30 { margin-bottom: 30px; }
.mb-50 { margin-bottom: 50px; }

.my-10 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12 {
    position: relative;
    min-height: 1px;
    display: block;
}

@media (min-width:768px) {
    .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
    .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
    .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
    .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
    .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
    .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
    .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
    .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
    .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
    .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
    .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
    .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
    .container {
        padding: 0 15px;
    }

    .section {
        margin-right: -15px;
        margin-left: -15px;
    }


}

@media (max-width:767px) {
    .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
    .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
    .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
    .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
    .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
    .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
    .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
    .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
    .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
    .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
    .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
    .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
    .container {
        padding: 0 10px;
    }

    .section {
        margin-right: -10px;
        margin-left: -10px;
    }

    .hidden-sm { display: none; }

}

@media (max-width: 568px) {
    .px-sm-12 { padding-left: 12px; padding-right: 12px; }

    .vspace-sm-30 { height: 30px; width: 100%; }
}

.dw-xs-1, .dw-xs-2, .dw-xs-3, .dw-xs-4, .dw-xs-5, .dw-xs-6, .dw-xs-7, .dw-xs-8, .dw-xs-9, .dw-xs-10, .dw-xs-11 {
    float: left;
}

.dw-xs-12 {
    width: 100%;
}

.dw-xs-11 {
    width: 91.66666666666666%;
}

.dw-xs-10 {
    width: 83.33333333333334%;
}

.dw-xs-9 {
    width: 75%;
}

.dw-xs-8 {
    width: 66.66666666666666%;
}

.dw-xs-7 {
    width: 58.333333333333336%;
}

.dw-xs-6 {
    width: 50%;
}

.dw-xs-5 {
    width: 41.66666666666667%;
}

.dw-xs-4 {
    width: 33.33333333333333%;
}

.dw-xs-3 {
    width: 25%;
}

.dw-xs-2 {
    width: 16.666666666666664%;
}

.dw-xs-1 {
    width: 8.333333333333332%;
}



@media (min-width: 768px) {
    .container {
        width: 750px;
    }

    .dw-sm-1, .dw-sm-2, .dw-sm-3, .dw-sm-4, .dw-sm-5, .dw-sm-6, .dw-sm-7, .dw-sm-8, .dw-sm-9, .dw-sm-10, .dw-sm-11 {
        float: left;
    }

    .dw-sm-12 {
        width: 100%;
    }

    .dw-sm-11 {
        width: 91.66666666666666%;
    }

    .dw-sm-10 {
        width: 83.33333333333334%;
    }

    .dw-sm-9 {
        width: 75%;
    }

    .dw-sm-8 {
        width: 66.66666666666666%;
    }

    .dw-sm-7 {
        width: 58.333333333333336%;
    }

    .dw-sm-6 {
        width: 50%;
    }

    .dw-sm-5 {
        width: 41.66666666666667%;
    }

    .dw-sm-4 {
        width: 33.33333333333333%;
    }

    .dw-sm-3 {
        width: 25%;
    }

    .dw-sm-2 {
        width: 16.666666666666664%;
    }

    .dw-sm-1 {
        width: 8.333333333333332%;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }

    .dw-md-1, .dw-md-2, .dw-md-3, .dw-md-4, .dw-md-5, .dw-md-6, .dw-md-7, .dw-md-8, .dw-md-9, .dw-md-10, .dw-md-11 {
        float: left;
    }

    .dw-md-12 {
        width: 100%;
    }

    .dw-md-11 {
        width: 91.66666666666666%;
    }

    .dw-md-10 {
        width: 83.33333333333334%;
    }

    .dw-md-9 {
        width: 75%;
    }

    .dw-md-8 {
        width: 66.66666666666666%;
    }

    .dw-md-7 {
        width: 58.333333333333336%;
    }

    .dw-md-6 {
        width: 50%;
    }

    .dw-md-5 {
        width: 41.66666666666667%;
    }

    .dw-md-4 {
        width: 33.33333333333333%;
    }

    .dw-md-3 {
        width: 25%;
    }

    .dw-md-2 {
        width: 16.666666666666664%;
    }

    .dw-md-1 {
        width: 8.333333333333332%;
    }

    .pl-md-0 { padding-left: 0; }
    .pl-md-40 { padding-left: 40px; }
    .pl-md-50 { padding-left: 50px; }

    .pr-md-0 { padding-right: 0; }
    .pr-md-5 { padding-right: 5px; }
    .pr-md-50 { padding-right: 50px; }
    .pr-md-65 { padding-right: 65px; }

    .pt-md-0 { padding-top: 0 !important; }
    .pt-md-20 { padding-top: 20px; }

    .mt-md-10 { margin-top: 10px; }

    .vspace-md-80 { height: 80px; width: 100%; }

}

@media (min-width: 1600px) {
    .container {
        &.wide {
            width: min(calc(100vw - 500px), 1500px);
        }
    }
}

@media (min-width: 1230px) and (max-width: 1599px) {
    .container {
        width: 1230px;

        &.wide {
            width: min(calc(100vw - 300px), 1500px);
        }
    }
}

@media (min-width: 1230px) {
    
    .container {
        width: 1230px;
    }

    .dw-lg-1, .dw-lg-2, .dw-lg-3, .dw-lg-4, .dw-lg-5, .dw-lg-6, .dw-lg-7, .dw-lg-8, .dw-lg-9, .dw-lg-10, .dw-lg-11 {
        float: left;
    }

    .dw-lg-12 {
        width: 100%;
    }

    .dw-lg-11 {
        width: 91.66666666666666%;
    }

    .dw-lg-10 {
        width: 83.33333333333334%;
    }

    .dw-lg-9 {
        width: 75%;
    }

    .dw-lg-8 {
        width: 66.66666666666666%;
    }

    .dw-lg-7 {
        width: 58.333333333333336%;
    }

    .dw-lg-6 {
        width: 50%;
    }

    .dw-lg-5 {
        width: 41.66666666666667%;
    }

    .dw-lg-4 {
        width: 33.33333333333333%;
    }

    .dw-lg-3 {
        width: 25%;
    }

    .dw-lg-2 {
        width: 16.666666666666664%;
    }

    .dw-lg-1 {
        width: 8.333333333333332%;
    }

    .pr-lg-0 {
        padding-right: 0;
    }

    .pr-lg-5 {
        padding-right: 5px;
    }

    .pr-lg-50 {
        padding-right: 50px;
    }

    .pr-lg-65 {
        padding-right: 65px;
    }

}

@-ms-viewport {
    width: device-width;
}

.visible-xs {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-xs.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-xs.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-xs.visible-lg {
        display: block !important;
    }
}

.visible-sm {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-sm.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-sm.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-sm.visible-lg {
        display: block !important;
    }
}

.visible-md {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-md.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-md.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-md.visible-lg {
        display: block !important;
    }
}

.visible-lg {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-lg.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-lg.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-lg.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-lg {
        display: block !important;
    }
}

.hidden-xs {
    display: block !important;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-xs.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-xs.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-xs.hidden-lg {
        display: none !important;
    }
}

.hidden-sm {
    display: block !important;
}


@media (max-width: 767px) {
    .hidden-sm.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-sm.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-sm.hidden-lg {
        display: none !important;
    }
}

.hidden-md {
    display: block !important;
}

@media (max-width: 767px) {
    .hidden-md.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-md.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-md.hidden-lg {
        display: none !important;
    }
}

.hidden-lg {
    display: block !important;
}


@media (max-width: 767px) {
    .hidden-lg.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-lg.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-lg.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-lg {
        display: none !important;
    }
}


.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    z-index: 1;
}

.carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
    line-height: 1;
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
    display: block;
}

.carousel-inner > .active {
    left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner > .next {
    left: 100%;
}

.carousel-inner > .prev {
    left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
    left: 0;
}

.carousel-inner > .active.left {
    left: -100%;
}

.carousel-inner > .active.right {
    left: 100%;
}

/*.carousel-inner .item a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}*/

.carousel-indicators {
    position: absolute;
    z-index: 9;
    bottom: 20px;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    list-style: none;
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $color-indicators;
    border-radius: 10px;
}

.carousel-indicators .active {
    background-color: $color-primary;
}

.carousel-caption {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 10;
    color: $color-white;
    padding: 0 40px;
}

@media (min-width:1230px) {
    .carousel-caption {
        width: 1230px;
    }
}

@media (max-width:1229px) and (min-width:992px) {
    .carousel-caption {
        max-width: 970px;
    }
}

@media (max-width:991px) and (min-width:768px) {
    .carousel-caption {
        max-width: 750px;
    }
}

@media (max-width:767px) {
    .carousel-caption {
        padding: 0 10px;
    }

    .carousel-caption span {
        display: none;
    }
}

.hor-padd-15{padding: 0px 15px;}

.section.flx {
    display: flex;

    .fl-al-cent {
        align-items: center;
    }

    &:before {
        display: none;
    }

    &:after {
        display: none;
    }

    flex-wrap: wrap;

    .list-row {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        height: 100%;
        justify-content: center;

        &.left {
            align-items: flex-start;
            text-align: left;
        }

        &.right {
            align-items: flex-end;
            text-align: right;
        }
    }
}

.pl-0 { padding-left: 0; }
.pl-5 { padding-left: 5px; }
.pl-50 { padding-left: 50px; }
.pl-65 { padding-left: 65px;}

.pr-0 { padding-right: 0; }
.pr-5 { padding-right: 5px; }
.pr-50 { padding-right: 50px; }
.pr-65 { padding-right: 65px ;}

.pt-0 { padding-top: 0; }

.mt-20 { margin-top: 20px; }
.mt-50 { margin-top: 50px; }
.mt-80 { margin-top: 80px; }

.mb-30 { margin-bottom: 30px; }

.max-w-670 { max-width: 670px; }
.max-w-750 { max-width: 750px; }
.max-w-950 { max-width: 950px; }
.max-w-970 { max-width: 970px; }

.marg-auto { margin: auto; }