﻿.gallery {
    h2 {
        font-size: 1.5em;
        font-weight: 500;
        color: $color-bg-hero;

        &:before, &:after {
            border-top: none;
        }
    }
}

.gallery-slider--inner .slide {
    height: 205px;
    text-align: center;
    position: relative;
    cursor: pointer;

    .overlay {
        background-color: #1D4384;
        opacity: 0.6;
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }

    strong {
        font-weight: 500;
        color: #fff;
        font-size: 1.2em;
        text-shadow: 0 1px 2px #000;
    }

    .gallery__content {
        position: absolute;
        bottom: 0%;
        width: 100%;
        z-index: 999;

        .btn {
            border-radius: 0;
            margin: 0;
        }
    }
}

#videos {
    .section {
        margin: 0 -5px;

        .dw-sm-4 {
            padding: 0 5px;
        }

        .dw-sm-4:nth-child(3n+1) {
            clear: both;
        }
    }

    .bx-wrapper .bx-controls-direction a.fa-chevron-left {
        left: -50px;

        @include media('<=tablet') {
            left: -5px;
        }
    }

    .bx-wrapper .bx-controls-direction a.fa-chevron-right {
        right: -50px;

        @include media('<=tablet') {
            right: -5px;
        }
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gallery__video {
    position: relative;
    height: 218px;
    max-width: 390px;
    margin: 15px 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &:hover {
        i {
            color: $color-orange;
        }
    }
}

.gallery__background {
    background-size: cover;
    background: url('../img/pht-video.jpg') no-repeat center;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    i {
        color: $color-white;
        font-size: 2em;
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%,-50%);
        opacity: 0.6;
    }

    .fa-play:before {
        margin-left: 5px;
    }
}
