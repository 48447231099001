﻿﻿﻿﻿﻿.navigation--middle {
	margin-top: 42px;
	padding-left: 11px;
	padding-right: 0;

	@include media('>tablet') {
		width: calc(100% - 560px);
		position: relative;
	}

	@include media('<=tablet') {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		padding: 15px;
		margin: 0;
		background-color: $color-white;
		box-shadow: 0 8px 10px rgba(0,0,0,.050980392156862744);
		border-bottom: 1px solid $color-light-grey;
		-webkit-box-shadow: 0px 8px 10px #0000000D;
		box-shadow: 0px 8px 10px #0000000D;
	}
}
.nav--links {
	text-decoration: none;
	@include media('<desktop','>tablet') {
		font-size: 0.75em !important;
	}

	@include media('>tablet') {
		color: $color-text;
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		line-height: 50px;
		&:hover i.arrow--item {
			color: $color-primary;
		}
	}

	@include media('<=tablet') {
		border-bottom: 1px solid $color-light-grey;
		width: 86%;
		float: left;
		padding: 15px 30px;
		font-weight: 700;
		color: $color-bg-hero;
	}

	@include media('<=phone') {
		width: 86%;
	}
}

.navigation {
	@include media('>=tablet') {
		background-color: $color-grey;
		height: 50px;
	}
}

﻿﻿﻿.navigation.nav--shut {
	@include media('<=tablet') {
		height: 0;
		overflow: hidden;
	}
}

.nav__sub.nav--opened {
	position: relative;
}

.nav--opened {
	@include media('<=tablet') {
		height: auto;
		width: 100%;
		z-index: 9999;
		color: $color-primary;
	}
}

i.arrow--desk {
	margin-left: 6px;
	font-size: 1.1em;
	height: 50px;
	line-height: 50px;
	padding-right: 10px;
	position: absolute;
	color: $color-ligh-blue;

	@include media('<=tablet') {
		display: none;
	}
}

.nav--shut {
	@include media('<=tablet') {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.nav__sub.nav--shut {
	@include media('<=tablet') {
		height: 0;
		overflow: hidden;
	}
}

.nav__item.offers {
	@include media('<=tablet') {
		.arrow--item {
			display: none;
		}
	}
}

 @include media('<desktop','>tablet') {
        .nav__item:nth-child(2) .nav--ad{ display:none; }
        .nav__item:nth-child(2) ul.hot--tubs{ width:70%; }

}

.nav__item {

	@include media('>tablet') {
		float: left;
		width: 14.8%;
		padding: 0 10px;
		&:hover {
			.nav__sub {
				visibility: visible;
				overflow: visible;
				height: auto;
			}

			.greyblock {
				height: 5px;
				visibility: visible;
			}

			.nav--fix {
				a, i {
					color: $color-primary;
				}
			}
		}
	}
}

.nav--links-home {
	@include media('>tablet') {
		width: 6.3%;
	}

	span {
		@include media('>tablet') {
			display: none;
		}

		@include media('<=tablet') {
			display: block;
		}
	}

	.nav--links--on {
		i {
			color: $color-primary;
		}
	}

	i {
		@include media('>tablet') {
			display: block;
			color: $color-ligh-blue;
			font-size: 1.6em;
			margin-top: -5px;
		}

		@include media('<=tablet') {
			display: none;
		}

		&:hover {
			color: $color-primary;
		}
	}

	.nav--links {
		@include media('<=tablet') {
			width: 100%;
		}
	}
}
.nav__item--last {

	@include media('<desktop','>tablet') {
		width: 31% !important;
	}

	@include media('>tablet') {
		float: left;
		width: 29.991%;
		text-align: center;
		margin-top: -20px;
		font-weight: 500;
	}

	@include media('<=tablet') {
		display: none;
	}

	span {
		font-size: 0.75em;
	}

	a {
		color: $color-orange;
		font-size: 1.5em;
		font-weight: 600;
		text-decoration: none;
	}
}

.nav {
	@include media('>tablet') {
		list-style: none;
		margin: 0;
		padding: 0;
		height: 50px;
	}
}

.nav--links.on {
	@include media('>tablet') {
		color: $color-primary;
		background-color: $color-white;
	}
}

.arrow--item {
	@include media('<=tablet') {
		width: 14%;
		float: right;
		height: 44px;
		line-height: 40px!important;
		margin: auto;
		text-align: center;
		border-bottom: 1px solid $color-light-grey;
		font-size: 1.4em;
		margin: 12px 0 auto auto;
	}
}

.nav-sub--sub {
	color: $color-text;
	font-size: 0.9em;
	display: inline;
	padding-left: 18px;
	position: relative;
	text-decoration: none;
	font-weight: normal;
	&:hover {
		color: $color-primary;
	}

	@include media('<=tablet') {
		font-size: 1em;
		clear: both;
		padding: 0 25px;
		margin: 15px 0;
        display:block;
	}
}

.nav--arrow i.arrow--item {
	@include media('>tablet') {
		display: none;
	}
}

.nav__sub--small {
	@include media('>tablet') {
		padding: 20px 15px !important;
		min-width: 175px;
		min-height: auto!important;
	}
}

.nav__sub {
	@include primary-font-stack;

	@include media('>tablet') {
		visibility: hidden;
		position: absolute;
		top: 100%;
		width: 100%;
		height: 0;
		left: 0%;
		background-color: $color-grey;
		padding: 40px 0 20px;
		z-index: 598;
		min-height: 278px;
	
	}

	.nav__advert {
		position: relative;
		display: block;
		width: 20%;
		float: left;
		margin-right: 15px;

		@include media('<desktop','>tablet') {
			margin-right: 10px;
		}
	}

	.nav__advert.float--right {
		float: right !important;
	}

	.nav--ad {
		margin-right: 0;
	}

	.nav__advert {
		@include media('<=tablet') {
			float: none;
			display: inline-block;
			width: 45%;
			margin: 10px 15px;

			img {
				margin-right: 10px;
				width: 100%;
				display: inline;
			}
		}

		@include media('<=phone') {
			width: 86%;
			margin: 10px 15px;

			img {
				margin-right: 10px;
				width: 100%;
				display: inline;
			}
		}
	}
}

.nav__access {
	@include media('<desktop','>tablet') {
		width: 530px !important;
	}

	@include media('>tablet') {
		float: left;
		width: 666px;
	}

	.nav__sub-sub--items {
		@include media('<desktop','>tablet') {
			width: 27%!important;
		}

		@include media('>tablet') {
			width: 29%;
		}
	}
}

.nav--links--on {
	@include media('<desktop','>tablet') {
		font-size: 0.85em !important;
	}

	@include media('>tablet') {
		color: $color-primary;
		font-size: 0.95em;
	}

	a {
		color: $color-primary;
	}
}

.nav--fix {
	@include media('>tablet') {
		position: relative;
		display: block;
		text-align: center;
	}
}

.nav__item--new {
	@include media('>tablet') {
		width: 16%;
		position: relative;
	}
}

.nav__item--why {
	@include media('>tablet') {
		width: 18.5%;
		position: relative;
	}
}

.nav__item--subs:nth-child(3) {
	@include media('>tablet') {
		width: 17%;
	}
}

.nav--about {
	@include media('>tablet') {
		width: 12.5%;
	}

	.nav--links {
		@include media('<=tablet') {
			width: 100%;
		}
	}
}

.nav--contact {
	@include media('<desktop','>tablet') {
		width: 11% !important;
	}

	@include media('>tablet') {
		width: 11%;
	}

	.nav--links {
		@include media('<=tablet') {
			width: 100%;
		}
	}
}

.nav__sub--list {
	@include media('>tablet') {
		border: 0;
		text-align: left;
		list-style: none;
		margin: 5px 0;
	}

	@include media('<tablet') {
		padding: 0 25px;
		list-style: none;
	}

	i {
		margin-right: 10px;
		color: $color-orange;
		position: absolute;
		left: 0;
		top: 4px;
	}

	.see--all i {
		position: static;
	}
}

.sub--list {
	@include media('>=tablet') {
		height: 100%;
	}
}

.nav__sub--items {

	@include media('>tablet') {
		height: auto;
		display: block;
	}

	@include media('<=tablet') {
		background-color: $color-white;
		padding: 0;
	}
}
.nav__sub--title {
	list-style: none;
	color: $color-bg-hero;
	font-weight: 500;
	margin-bottom: 15px;


	a {
		color: $color-bg-hero;
		text-decoration: none;
		font-weight: 500;

		&:hover {
			color: $color-primary;
		}
	}

	@include media('<=tablet') {
		padding: 0 25px;
		margin-top: 15px;
		display: block;
	}
}

.nav__sub-prods {
	a {
		color: $color-text;
		font-weight: normal;
	}
}

.nav__sub-sub--items {
	@include media('>tablet') {
		width: 16%;
		float: left;
		margin-right: 30px;
		border-right: 1px solid $color-nav-border;
		padding-right: 15px;
	}

	.sub-sub--list {
		float: none;
		padding: 0;
	}
}

.hot--sub-title {
	@include media('>tablet') {
		float: left;
		display: block;
		width: 55%;
	}
}


.hot--tubs {
	@include media('>tablet') {
		float: left;
		width: 50%;
        
		column-count: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		margin-right: 0;
		border-right: none;
		position: relative;
		display: block;
		overflow: hidden;
		display:grid;
		grid-template-columns:repeat(2, auto);
		min-width:fit-content;
		grid-column-gap:10px;
		&::before {
			height: 500px;
			width: 1px;
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			transform: translateX(-15px);
			background-color: $color-nav-border;
		}
	}

   

	li {
		@include media('>tablet') {
			display: block;
			min-height: 25px;
			margin: 0;
			position: relative;

			a {
				min-height: 20px;
			}
		}
	}

	li {
		@include media('>tablet') {
			height: 2px;
		}
	}

	li:nth-child(7), li:nth-child(8), li:nth-child(6), li:nth-child(5) {
		@include media('>tablet') {
			border-right: 0;
		}
	}
}


.nav--hottubs {
	@include media('>tablet') {
		margin-top: -40px;
	}
}

.nav__sub-sub--items:nth-child(3) {
	border-right: none;
}

.nav__sub-new {
	@include media('>tablet') {
		float: left;
		margin-right: 0;
		border-right: none;
		position: relative;
	}

	.nav__sub--title {
		@include media('>tablet') {
			float: left;
			display: block;
			width: 55%;
		}
	}

	li {
		@include media('>tablet') {
			display: block;
			margin: 0;
			height: 100%!important;
            margin-bottom:5px;
		}
	}
/*
	li:nth-child(-n+3) {
		@include media('>tablet') {
			border-right: 1px solid $color-nav-border;
			height: 2px;
		}
	}*/
}

.greyblock {
	@include media('>tablet') {
		position: absolute;
		bottom: -5px;
		width: 100%;
		left: 0;
		visibility: hidden;
		height: 0px;
		z-index: 9999;
	}
}

.all--accessories {
	display: none!important;
}

.nav-sub__all {
	a {
		color: $color-white;
		display: block;
		text-align: center;
		width: 100%;
		height: 50px;
		line-height: 50px;
		margin: 80px auto 0;
	}

}

.see--all {
	font-size: 0.82em;
	color: $color-text;
	display: block;
	text-decoration: none;
	font-weight: normal;

	@include media('>1025px') {
		position: absolute;
		bottom: 15px;
	}



	i {
		margin-left: 10px;
		position: initial;
	}

	&:hover {
		color: $color-primary;
	}
}