@charset "UTF-8";
/* BREAKPOINTS ------------ */
/*$breakpoints: ( 
    'phone': 320px, 
    'tablet': 768px, 
    'desktop': 1024px 
) !default;*/
/* COLOURS ------------ */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap");
html {
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-transition: transform 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out;
  transition: background-color 0.1s linear, color 0.1s, transform 0.1s ease-in-out, max-height 0.3s ease-in-out, opacity 0.2s ease-in-out, background-image 0.2s ease-in-out, box-shadow 0.2s;
}

.top-banner {
  position: relative;
  text-align: center;
  padding: 5px;
  background-color: #75c4ed;
  color: #fff;
  overflow: hidden;
}
.top-banner .container.xmass {
  width: 90%;
}
.top-banner .container.xmass span {
  color: #f35446;
  font-weight: 700;
}
.top-banner .gift-1 {
  position: absolute;
  left: 5px;
}
.top-banner .gift-2 {
  position: absolute;
  right: 5px;
}

@media screen and (max-width: 800px) {
  .top-banner .container.xmass {
    width: 75%;
  }
  .top-banner .gift-1 {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .top-banner .gift-2 {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.top-orange-bar {
  background-color: #F18C18;
  color: #FFF;
  text-align: center;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  font-size: 1.2rem;
}
@media (max-width: 991px) {
  .top-orange-bar {
    font-size: 0.9rem;
  }
  .top-orange-bar img {
    display: none !important;
  }
}

.rel {
  position: relative;
}

.lnk {
  cursor: pointer;
}
.lnk.white {
  color: #fff;
}
.lnk.ul {
  text-decoration: underline;
}
.lnk.ul:hover {
  text-decoration: none;
}

.center-img img {
  display: block;
  margin: 0 auto;
}

.content-w {
  width: max-content;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-5 {
  margin-top: 5px;
}

.grey-bord {
  border: 1px solid #ccc;
}

.dblue-bg {
  background-color: #1D4384;
}

.white-txt {
  color: #fff;
}

.rad-3 {
  border-radius: 3px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.cent-block {
  margin: 0 auto;
}

.fl-dir-col {
  flex-direction: column;
}

.bord {
  border: 1px solid #ccc;
}

@media (min-width: 991px) {
  #baskettotal {
    position: sticky;
    top: 15px;
    margin-bottom: 30px;
  }
}

#discountform.checkout-discount {
  position: sticky;
  top: 200px;
}

.flx-bx {
  display: flex;
}
.flx-bx.fl-al-cent {
  align-items: center;
}
.flx-bx.fl-ju-cent {
  justify-content: center;
}
.flx-bx.gap-30 {
  gap: 30px;
}

.fl-col-rev {
  display: flex;
  flex-direction: column-reverse;
}

.container.large {
  max-width: 1600px;
  width: auto;
}

a:focus {
  outline: none;
}

.blue-txt {
  color: #1D4384;
}

@media (max-width: 991px) {
  h1.loc::after {
    display: none !important;
  }
}

@media (max-width: 991px) {
  h1.loc::before {
    display: none !important;
  }
}

h1.alt {
  text-align: left;
  line-height: 1.2em;
}
h1.alt:before {
  display: none !important;
}
h1.alt:after {
  display: none !important;
}
h1.alt .sub {
  font-size: 0.8em;
  font-weight: 500;
}

h2.alt {
  text-align: left;
  line-height: 1.2em;
  margin: 0px;
  font-weight: 500;
}
h2.alt:before {
  display: none !important;
}
h2.alt:after {
  display: none !important;
}
h2.alt .sub {
  font-size: 0.8em;
  font-weight: 500;
}

h3.alt {
  text-align: left;
  line-height: 1.2em;
  margin: 0px;
  font-weight: 500;
}
h3.alt:before {
  display: none !important;
}
h3.alt:after {
  display: none !important;
}
h3.alt .sub {
  font-size: 0.8em;
  font-weight: 500;
}

.feat__pop {
  position: relative;
  display: block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.feat__pop i {
  height: 70px;
  width: 70px;
  text-align: center;
  bottom: 15px;
  left: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50% -50%);
  background-color: #72c5ec;
  color: #fff;
  line-height: 70px;
  border-radius: 100%;
  font-size: 2em;
  z-index: 9999;
}
@media screen and (max-width: 500px) {
  .feat__pop i {
    font-size: 1.5em;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
.feat__pop figcaption {
  background-color: rgba(69, 68, 68, 0.5019607843);
  display: block;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  margin: 0;
  padding: 15px 10px;
  transition: background-color 0.25s ease;
}
@media (min-width: 768px) {
  .feat__pop:hover figcaption {
    background-color: #ff8d00;
  }
}
.feat__pop.on figcaption {
  background-color: #ff8d00;
}

body {
  color: #666666;
  background-color: #fff;
  margin: 0;
  padding: 0;
  text-size-adjust: 100%;
}

.alice-blue {
  color: #2e6ad0;
}

h3.subhead {
  display: inline;
}

.back--top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-decoration: none;
  z-index: 9999;
  font-size: 2em;
  display: none;
  cursor: pointer;
}
.back--top i {
  color: #2e6ad0;
}
.back--top:hover i {
  color: #ff8d00;
}

.grecaptcha-badge {
  display: none !important;
}

.custom--highlight {
  color: #7eccf0;
}

.findAddress {
  margin: 10px 0 !important;
}

.brands {
  margin-top: 40px;
}
.brands.home {
  margin-top: 0;
}

.bottom__images {
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .bottom__images {
    margin-bottom: 15px;
  }
}
.bottom__images .gallery__image {
  float: left;
}
@media (min-width: 992px) {
  .bottom__images .gallery__image {
    width: 16.666%;
  }
}
@media (max-width: 991px) {
  .bottom__images .gallery__image {
    width: 33.333%;
  }
}
@media (max-width: 550px) {
  .bottom__images .gallery__image {
    width: 50%;
  }
}
.bottom__images .brands {
  margin: 30px auto;
}
@media (max-width: 767px) {
  .bottom__images .brands {
    margin: 25px auto;
  }
}
.bottom__images .brands img {
  margin: auto;
}

.notice {
  background-color: #2e6ad0;
  padding: 25px;
  text-align: center;
  color: #fff;
  margin-bottom: 55px;
}
.notice .notice__caps {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2em;
}
.notice .notice__caps i {
  margin-right: 10px;
}

.contact--password .btn--black {
  line-height: 40px !important;
  height: 40px !important;
  margin: 40px auto !important;
}
.contact--password .internal--body {
  margin: auto;
}

.contact--privacy {
  font-size: 0.75em;
  display: block;
  margin-bottom: 15px;
  text-align: center;
}

.contact__map {
  margin-top: 20px;
}
.contact__map iframe {
  width: 100%;
  min-height: 375px;
  border: 0;
  margin: auto;
  display: block;
}
@media (max-width: 991px) {
  .contact__map iframe {
    margin-bottom: 20px;
  }
}

.dash--break {
  margin: 0 5px;
  color: #2e6ad0;
}

.breadcrumb {
  font-size: 0.8em;
  margin: 25px 0 30px;
  font-weight: 300;
}
.breadcrumb a {
  font-weight: 700;
}
@media (max-width: 991px) {
  .breadcrumb {
    margin: 15px 0;
  }
}

.breadcrumb--hide {
  display: none;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  display: block;
}

.white-bg {
  background-color: #fff;
}

.grey-bg {
  background-color: #eaeaea;
}

.lgrey-bg {
  background-color: #f9f9f9;
}

.p-10, .p10 {
  padding: 10px;
}

.p-15, .p15 {
  padding: 15px;
}

.p-20, .p20 {
  padding: 20px;
}

.p-30, .p30 {
  padding: 30px;
}

.pt-20, .pt20 {
  padding-top: 20px;
}

.pt-30, .pt30 {
  padding-top: 30px;
}

.px-15, .px30 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-30, .px30 {
  padding-left: 30px;
  padding-right: 30px;
}

.r-5, .r5 {
  border-radius: 5px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.dblue-bord {
  border: 1px solid #1D4384;
}

.blue-txt {
  color: #1D4384;
}

.orange-txt {
  color: #F18C18;
}

.nomarg {
  margin: 0px !important;
}

.min-w-150 {
  min-width: 150px;
}

.new-txt-lnk {
  width: max-content;
  display: flex;
  gap: 15px;
  font-size: 2em;
  text-decoration: none;
  align-items: center;
  font-weight: 200;
}

.md-txt {
  font-size: 1.2em;
}

.lg-txt {
  font-size: 1.7em;
}

.xlg-txt {
  font-size: 5em;
}

.fw100 {
  font-weight: 100;
}

.fw200 {
  font-weight: 200;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.g-recaptcha div, .g-recaptcha {
  height: 50px;
  max-width: 302px;
  margin: auto;
}

.g-recaptcha div {
  margin-top: -14px;
}

.g-recaptcha {
  border-right: 1px solid #d3d3d3;
  overflow: hidden;
  border-bottom: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  margin-top: 0px;
}

#map {
  height: 250px;
  width: 100%;
  margin-top: 10px;
  max-width: 718px;
}
#map.full-w {
  max-width: 100% !important;
}
#map.full-w iframe {
  min-height: 250px;
}

.breadcrumb a {
  text-decoration: none;
}

.search--title:before, .search--title:after {
  border-top: none;
}

.contact h1 {
  margin-bottom: 25px;
  font-weight: 500;
}

.contact__address {
  color: #666666;
}
@media (max-width: 767px) {
  .contact__address {
    margin-bottom: 10px;
  }
}
.contact__address i {
  background-color: #ff8d00;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-right: 15px;
}
.contact__address span.supporticon {
  margin-right: 15px;
  height: 30px;
  width: 30px;
  background-image: url("../img/support-icon.jpg");
  float: left;
  display: block;
}
.contact__address .social__media {
  text-align: left;
}
.contact__address .fa-phone {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.contact__address .contact--text {
  color: #666666;
  font-weight: 400;
  font-size: 0.85em;
  padding: 0 40px 0 0;
  margin: 20px 0;
}
.contact__address .contact--text.bottom {
  margin-bottom: 0;
}
.contact__address .contact--head {
  color: #666666;
  font-weight: 600;
  font-size: 1em;
  display: inline-block;
}
.contact__address .contact--times {
  color: #ff8d00;
  font-size: 1em;
  font-weight: 600;
}
.contact__address span {
  color: #1d4384;
  font-weight: 500;
  font-size: 1.2em;
}
.contact__address span a {
  font-size: 0.8em;
  color: #ff8d00;
  margin-left: 5px;
}
@media (max-width: 990px) and (min-width: 768px) {
  .contact__address span a {
    display: block;
    margin-left: 0;
  }
}

.contact--times {
  color: #ff8d00;
  font-weight: bold;
}

.contact--dets {
  margin-bottom: 35px;
  display: block;
  font-weight: 600;
}
@media (max-width: 767px) {
  .contact--dets {
    margin-bottom: 15px;
  }
}
.contact--dets .custom--highlight a {
  color: #7eccf0;
}
.contact--dets .custom--highlight a:hover {
  color: #2e6ad0;
}
.contact--dets a {
  font-weight: 700;
  color: #ff8d00;
  text-decoration: none;
}
.contact--dets a:hover {
  color: #2e6ad0;
}

.auto-overfl {
  overflow: auto;
}

.social--icons {
  color: #2e6ad0;
  font-weight: normal;
  margin-right: 25px;
  font-size: 1.4em;
}
.social--icons:hover {
  color: #666666;
}

.socials {
  display: block;
  margin-top: 15px;
}

.contact__form {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 30px 35px;
  text-align: center;
  margin: auto;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .contact__form {
    min-height: 788px;
  }
}
.contact__form i {
  display: inline-block;
  color: #2e6ad0;
  font-size: 2em;
  margin-right: 15px;
}
.contact__form h2 {
  display: inline-block;
  margin-bottom: 15px;
  color: #1d4384;
  font-size: 1.2em;
  font-weight: 500;
}
.contact__form h2:before, .contact__form h2:after {
  border-top: none;
}
.contact__form .editor-label {
  text-align: left;
  margin-bottom: 12px;
  color: #666666;
  font-weight: 400;
  font-size: 0.85em;
}
.contact__form .editor-field {
  margin-bottom: 20px;
}
.contact__form .editor-field em {
  text-align: left;
  font-size: 0.85em;
  display: block;
}
.contact__form .editor-field input, .contact__form .editor-field textarea {
  border-radius: 2px;
}
.contact__form .editor-field textarea {
  height: 100px;
}
.contact__form .g-recaptcha {
  margin: 15px auto;
}
.contact__form .required {
  color: #2e6ad0;
}
.contact__form .btn {
  height: 40px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 30px;
}
.contact__form .btn--thin {
  line-height: 40px;
  height: 40px;
  margin: 40px auto !important;
}

button {
  outline: 0;
  font-family: inherit;
}

input[type=submit], input[type=button] {
  cursor: pointer;
  text-align: center;
  max-width: 100%;
  line-height: 40px;
  height: 40px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 3px;
}

input[type=submit], input[type=text], input[type=password] {
  -webkit-appearance: none;
}

.editor-label label {
  color: #1D4384;
  font-size: 1.18em;
}

.editor-field input [type=number], .editor-field input[type=password], .editor-field input[type=tel], .editor-field input[type=date], .editor-field select {
  border: 1px solid #1D4384;
  height: 55px;
  border-radius: 3px;
  color: #2e6ad0;
}
.editor-field .side-btn {
  display: flex;
  gap: 15px;
  max-width: 760px;
}
.editor-field .side-btn .btn {
  height: 55px;
  margin: 0px;
  font-size: 1.3em;
  width: 310px;
}

.editor-check {
  color: #1D4384;
  font-size: 1.18em;
}
.editor-check label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.editor-check input {
  display: none;
}
.editor-check i {
  display: flex;
  width: 35px;
  height: 35px;
  border: 1px solid #1D4384;
  border-radius: 3px;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: transparent;
}
.editor-check.on i {
  color: #1D4384;
}

.map-container {
  width: 100%;
}
.map-container iframe {
  width: 100%;
  max-width: 100%;
}

.btn {
  cursor: pointer;
  width: 100%;
  line-height: 1;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  font-size: 0.9em;
  font-weight: 500;
  font-style: normal;
  border-radius: 3px;
  text-decoration: none;
  padding: 0px 15px;
}
.btn.new {
  height: auto;
  min-height: 40px;
  padding: 10px 15px;
  line-height: 1;
}
.btn.minw {
  min-width: 100px;
}
.btn.large {
  height: 55px;
  max-width: 100%;
}
.btn.blue {
  background-color: #2e6ad0;
  border: 1px solid #2e6ad0;
  color: #fff;
}
.btn.blue:hover {
  background-color: #ff8d00;
  border: 1px solid #ff8d00;
  color: #fff;
}
.btn.lg-txt {
  font-size: 1.5em;
}
.btn.no-rad {
  border-radius: 0px;
}
.btn.no-marg {
  margin: 0px;
}

.btn--right {
  float: right;
}

.btn--primary.confirm--selection:hover {
  background-color: #ff8d00;
  border: 1px solid #ff8d00;
}

.btn--primary {
  background-color: #2e6ad0;
  border: 1px solid #2e6ad0;
  color: #fff;
}
.btn--primary:hover {
  background-color: #ff8d00;
  border: 1px solid #ff8d00;
  color: #fff;
}

.warrant-btn {
  max-width: 420px;
  margin: 30px auto 20px auto;
}
.warrant-btn i {
  font-size: 1.3em;
}

.btn--dark-blue {
  background-color: #1d4384;
  border: 1px solid #1d4384;
  color: #fff;
}
.btn--dark-blue:hover {
  background-color: #ff8d00;
  border: 1px solid #ff8d00;
  color: #fff;
}

.btn--spec img {
  display: inline-block;
  margin-right: 15px;
}

.btn--blue {
  background-color: #72c5ec;
  border: 1px solid #72c5ec;
  color: #fff;
}
.btn--blue:hover {
  background-color: #2e6ad0;
  border: 1px solid #2e6ad0;
  color: #fff;
}
.btn--blue.selected {
  background-color: #F18C18;
  border: 1px solid #F18C18;
}
.btn--blue.selected:hover {
  background-color: #F18C18;
  border: 1px solid #F18C18;
}

.btn--orange {
  background-color: #ff8d00;
  border: 1px solid #ff8d00;
  color: #fff;
}
.btn--orange:hover {
  background-color: #72c5ec;
  border: 1px solid #72c5ec;
  color: #fff;
}

.btn--pink {
  background-color: #d57ab4;
  border: 1px solid #d57ab4;
  color: #fff;
}
.btn--pink:hover {
  background-color: #b26695;
  border: 1px solid #b26695;
  color: #fff;
}

.btn--grey {
  background-color: #666666;
  border: 1px solid #666666;
  color: #fff;
}
.btn--grey:hover {
  background-color: #a4a4a4;
  border: 1px solid #a4a4a4;
  color: #fff;
}

.btn--lgrey {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  color: #666666;
}
.btn--lgrey:hover {
  background-color: #a4a4a4;
  border: 1px solid #a4a4a4;
  color: #fff;
}

.btn--black {
  background-color: #231f20;
  border: 1px solid #231f20;
  color: #fff;
}
.btn--black:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff;
}

.btn--green {
  background-color: #2e8925;
  border: 1px solid #2e8925;
  color: #fff;
}
.btn--green:hover {
  background-color: #0c3c07;
  border: 1px solid #0c3c07;
  color: #fff;
}

.btn--white {
  background-color: #fff;
  border: 1px solid #fff;
  color: #1d4384;
}
.btn--white:hover {
  background-color: #72c5ec;
  border: 1px solid #72c5ec;
  color: #fff;
}

.loading-help .loading-special {
  position: relative;
  height: 40px;
  border: none;
  display: block;
  text-align: center;
  font-family: inherit;
}

.loading-help .new-loader {
  display: inline-block;
  color: #fff;
  font-size: 4px;
  margin: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  margin-bottom: 2px;
}

.loading-help .load-text {
  line-height: 40px;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 0.9em;
  -webkit-appearance: none;
  font-weight: 500;
  margin-left: 20px;
}

#askquestionform .load-text {
  color: #1D4384;
}

#askquestionform .new-loader {
  color: #1D4384;
}

input[type=text], input[type=password], input[type=tel], input[type=number] {
  width: 100%;
  height: 40px;
  padding: 0 0 0 10px;
  border: none;
  color: #2e6ad0;
  border: 1px solid #dfdfdf;
  font-family: inherit;
  border-radius: 3px;
}

textarea {
  border: 1px solid #d4d4d4;
  padding: 10px 0 0 10px;
  color: #2e6ad0;
  min-height: 70px;
  width: 100%;
  font-family: inherit;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
}

select {
  background-color: #fff;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: none;
  color: #666666;
  border: 1px solid #d4d4d4;
  padding: 0 0 0 5px;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
}

.center-block {
  margin: 0 auto;
}

.max-w-570 {
  max-width: 640px;
}

.yell-txt {
  color: #FF8D00 !important;
}

textarea:focus, input:focus, select {
  outline: none;
}

h1 {
  font-size: 2em;
  color: #1d4384;
  font-weight: 500;
}

h2 {
  font-size: 1.8em;
  font-style: normal;
  font-weight: 300;
  color: #1d4384;
}

h3 {
  font-size: 1.3em;
  font-weight: 500;
  color: #1d4384;
}

h1, h2, h3 {
  position: relative;
  overflow-x: clip;
  text-align: center;
}
h1:before, h2:before, h3:before {
  margin-left: -140px;
  text-align: right;
}
@media (min-width: 1231px) {
  h1:before, h2:before, h3:before {
    margin-left: -265px;
  }
}
@media (min-width: 768px) {
  h1:before, h1:after, h2:before, h2:after, h3:before, h3:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 100%;
    height: 1px;
    content: "";
    border-top: 1px solid #1d4384;
    max-width: 125px;
  }
}
@media (min-width: 768px) and (min-width: 1231px) {
  h1:before, h1:after, h2:before, h2:after, h3:before, h3:after {
    max-width: 250px;
  }
}
h1:after, h2:after, h3:after {
  margin-left: 15px;
}
@media (max-width: 375px) {
  h1:after, h2:after, h3:after {
    margin-left: 0;
  }
}

h2.altstyle {
  text-align: left !important;
  font-size: 2.4em;
  width: 100%;
  max-width: 100%;
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-weight: 500;
  line-height: 1.3;
}
h2.altstyle:before {
  display: none;
}
h2.altstyle:after {
  display: none;
}
@media (min-width: 992px) {
  h2.altstyle {
    font-size: 2.4em;
  }
}
@media (max-width: 991px) {
  h2.altstyle {
    font-size: 1.4em;
  }
}

h3.altstyle {
  text-align: left !important;
  font-size: 1.9em;
  width: 100%;
  max-width: 100%;
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-weight: 400;
  line-height: 1.3;
}
h3.altstyle:before {
  display: none;
}
h3.altstyle:after {
  display: none;
}
@media (min-width: 992px) {
  h3.altstyle {
    font-size: 1.9em;
  }
}
@media (max-width: 991px) {
  h3.altstyle {
    font-size: 1.2em;
  }
}

.center-txt {
  text-align: center;
}
.center-txt em {
  font-size: 0.75em;
}

.orang-txt {
  color: #ff6a00;
}

#content {
  position: relative;
  z-index: 99;
}
#content:before {
  position: absolute;
  content: "";
  left: 0px;
  width: 100%;
  display: block;
  top: 0px;
  z-index: 100;
}
#content.noshadow:before {
  box-shadow: none;
}
@media (max-width: 991px) {
  #content {
    margin-top: 75px;
    overflow: hidden;
  }
}

a {
  text-decoration: underline;
  color: #2e6ad0;
}
@media (min-width: 768px) {
  a:hover {
    text-decoration: none;
    color: #ff8d00;
  }
}

/*Error Validation*/
.OK {
  padding: 10px;
  color: #fff;
  background-color: #2e6ad0;
  margin: 20px 0;
  text-align: left;
  border-radius: 3px;
}

.build .OK {
  max-width: 600px;
  margin: 30px auto;
}
.build .OK .msg {
  text-align: center;
  display: block;
}
.build .OK .btn {
  max-width: 300px;
  margin: 15px auto 0px;
}

#atbMessage {
  margin: 15px auto 0px auto;
  text-align: center;
  max-width: 440px;
}
#atbMessage .OK {
  text-align: center;
  background-color: #2e6ad0;
}

.OK ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #fff;
}

.KO {
  padding: 10px;
  color: #fff;
  background-color: #333333;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.2;
  border-radius: 3px;
  min-height: 40px;
}
.KO ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #fff;
}
.KO li {
  color: #fff !important;
}

.Generic {
  padding: 10px;
  color: #fff;
  background-color: #333333;
  margin-bottom: 10px;
}

.field-validation-error {
  color: #ff4c0d !important;
  display: block;
  text-align: left;
  font-size: 0.8em;
  line-height: normal;
  line-height: initial;
  height: auto;
}

.validation-summary-errors ul, .validation-summary-errors ol {
  color: #ff4c0d;
  margin-bottom: 15px;
}

.ui-autocomplete {
  /* display: block !important;*/
  background-color: #fff;
  width: 523px !important;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 99999;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  transform: translateY(20px);
  max-height: 300px;
  display: block;
  overflow: auto;
  max-width: 419px;
}

.ui-menu-item {
  color: #2e6ad0;
  cursor: pointer;
}

.ui-menu-item a {
  color: #666666;
  display: block;
  padding: 10px 15px;
  font-size: 0.9em;
  text-decoration: none;
}

.ui-menu-item a:hover {
  background-color: #ccc;
  color: #2e6ad0;
}

.ui-helper-hidden-accessible {
  display: none;
}

.outside i {
  display: none;
}

.background-lazy {
  position: relative;
  background-color: transparent;
  background-image: none !important;
  background: none !important;
}

.background-lazy:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  color: #fff;
  font-size: 5px;
  margin: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -o-animation: load4 1.3s infinite linear;
  -moz-animation: load4 1.3s infinite linear;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  content: "";
}

.ajaxLoader {
  min-height: 40px;
  line-height: 35px;
  text-align: center;
}

.ajaxLoader i {
  display: inline-block;
  color: #2e6ad0;
  font-size: 5px;
  margin: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -o-animation: load4 1.3s infinite linear;
  -moz-animation: load4 1.3s infinite linear;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  content: "";
}

@media screen and (max-width: 991px) {
  #headerright .navigation {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: auto;
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  #delivery-options label .details strong {
    width: 45%;
    float: left;
  }
  #delivery-options label .details span {
    width: 55%;
    float: right;
    padding-left: 15px;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  #headerright .accounts {
    text-align: center;
    margin-top: 20px;
  }
  #headerright .log.browse {
    padding: 10px;
  }
  #headerright .log.browse.navopen {
    background-color: #004d94;
  }
  #headerright .log.browse.navopen a, #headerright .log.browse.navopen i {
    color: #fff;
  }
  #headerright .log, #headerright .cart {
    float: none;
    display: inline-block !important;
  }
  .address-checked {
    padding: 10px 10px 0;
  }
  #delivery-options label.delivery-opt {
    display: flex;
    gap: 10px;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
  }
  #delivery-options label .rb {
    position: static;
    transform: unset;
    flex-grow: 1;
    flex-shrink: 1;
  }
  #delivery-options .delivery-opt .details span > i {
    position: static;
    display: inline-block;
    transform: unset;
    font-size: 0.7em;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin-right: 5px;
  }
  #delivery-options label span.price {
    right: 10px;
    position: static;
    transform: unset;
    text-align: center;
  }
  #delivery-options label span.details {
    flex-grow: 1;
  }
  #delivery-options label span.details > span {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  #delivery-options label .details strong, #delivery-options label .details span {
    display: block;
    position: relative;
  }
}
@media screen and (max-width: 400px) {
  .prod-listing .dw-xs-6 {
    width: 100%;
    float: none;
  }
  .basket .row.regular .col1 {
    padding: 0;
    min-height: 0;
  }
  .basket .row.regular .col1 img {
    display: none;
  }
  .basket .btn {
    max-width: 100%;
  }
  .basket .btn.grey {
    margin: 15px 0 0 0;
  }
}
@-ms-keyframes load4 {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@-o-keyframes load4 {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@-moz-keyframes load4 {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@-webkit-keyframes load4 {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
.marg-top-30 {
  margin-top: 30px !important;
}

.marg-top-15 {
  margin-top: 15px !important;
}

.section.sml-spc {
  margin: 0px -5px;
}
.section.sml-spc .bs {
  padding: 0px 5px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.center-txt, .center-text {
  text-align: center;
}

.full-w {
  width: 100%;
}

.full-w {
  width: 100%;
}

.flx-bx {
  display: flex;
}

.fl-al-cent {
  align-items: center;
}

.fl-ju-cent {
  justify-content: center;
}

.fl-ju-end {
  justify-content: end;
}

.fl-ju-space {
  justify-content: space-between;
}

.fl-ju-space-a {
  justify-content: space-around;
}

.fl-wrap {
  flex-wrap: wrap;
}

.fl-r-rev {
  flex-direction: row-reverse;
}

.fl-al-end {
  align-items: end;
}

.fl-al-flend {
  align-items: flex-end;
}

.center-block {
  margin: 0 auto;
}

.max-w-100 {
  max-width: 100px;
}

.max-w-110 {
  max-width: 110px;
}

.max-w-120 {
  max-width: 120px;
}

.max-w-130 {
  max-width: 130px;
}

.max-w-140 {
  max-width: 140px;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-160 {
  max-width: 160px;
}

.max-w-170 {
  max-width: 170px;
}

.max-w-180 {
  max-width: 180px;
}

.max-w-190 {
  max-width: 190px;
}

.max-w-200 {
  max-width: 200px;
}

.max-w-210 {
  max-width: 210px;
}

.max-w-220 {
  max-width: 220px;
}

.max-w-230 {
  max-width: 230px;
}

.max-w-240 {
  max-width: 240px;
}

.max-w-250 {
  max-width: 250px;
}

.max-w-260 {
  max-width: 260px;
}

.max-w-270 {
  max-width: 270px;
}

.max-w-280 {
  max-width: 280px;
}

.max-w-290 {
  max-width: 290px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-310 {
  max-width: 310px;
}

.max-w-320 {
  max-width: 320px;
}

.max-w-330 {
  max-width: 330px;
}

.max-w-340 {
  max-width: 340px;
}

.max-w-350 {
  max-width: 350px;
}

.max-w-360 {
  max-width: 360px;
}

.max-w-370 {
  max-width: 370px;
}

.max-w-380 {
  max-width: 380px;
}

.max-w-390 {
  max-width: 390px;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-410 {
  max-width: 410px;
}

.max-w-420 {
  max-width: 420px;
}

.max-w-430 {
  max-width: 430px;
}

.max-w-440 {
  max-width: 440px;
}

.max-w-450 {
  max-width: 450px;
}

.max-w-460 {
  max-width: 460px;
}

.max-w-470 {
  max-width: 470px;
}

.max-w-480 {
  max-width: 480px;
}

.max-w-490 {
  max-width: 490px;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-510 {
  max-width: 510px;
}

.max-w-520 {
  max-width: 520px;
}

.max-w-530 {
  max-width: 530px;
}

.max-w-540 {
  max-width: 540px;
}

.max-w-550 {
  max-width: 550px;
}

.max-w-560 {
  max-width: 560px;
}

.max-w-570 {
  max-width: 570px;
}

.max-w-580 {
  max-width: 580px;
}

.max-w-590 {
  max-width: 590px;
}

.max-w-600 {
  max-width: 600px;
}

.max-w-610 {
  max-width: 610px;
}

.max-w-620 {
  max-width: 620px;
}

.max-w-630 {
  max-width: 630px;
}

.max-w-640 {
  max-width: 640px;
}

.max-w-650 {
  max-width: 650px;
}

.max-w-660 {
  max-width: 660px;
}

.max-w-670 {
  max-width: 670px;
}

.max-w-680 {
  max-width: 680px;
}

.max-w-690 {
  max-width: 690px;
}

.max-w-700 {
  max-width: 700px;
}

.max-w-710 {
  max-width: 710px;
}

.max-w-720 {
  max-width: 720px;
}

.max-w-730 {
  max-width: 730px;
}

.max-w-740 {
  max-width: 740px;
}

.max-w-750 {
  max-width: 750px;
}

.max-w-760 {
  max-width: 760px;
}

.max-w-770 {
  max-width: 770px;
}

.max-w-780 {
  max-width: 780px;
}

.max-w-790 {
  max-width: 790px;
}

.max-w-800 {
  max-width: 800px;
}

.max-w-810 {
  max-width: 810px;
}

.max-w-820 {
  max-width: 820px;
}

.max-w-830 {
  max-width: 830px;
}

.max-w-840 {
  max-width: 840px;
}

.max-w-850 {
  max-width: 850px;
}

.max-w-860 {
  max-width: 860px;
}

.max-w-870 {
  max-width: 870px;
}

.max-w-880 {
  max-width: 880px;
}

.max-w-890 {
  max-width: 890px;
}

.max-w-900 {
  max-width: 900px;
}

.max-w-910 {
  max-width: 910px;
}

.max-w-920 {
  max-width: 920px;
}

.max-w-930 {
  max-width: 930px;
}

.max-w-940 {
  max-width: 940px;
}

.max-w-950 {
  max-width: 950px;
}

.max-w-960 {
  max-width: 960px;
}

.max-w-970 {
  max-width: 970px;
}

.max-w-980 {
  max-width: 980px;
}

.max-w-990 {
  max-width: 990px;
}

.max-w-1000 {
  max-width: 1000px;
}

.max-w-1010 {
  max-width: 1010px;
}

.max-w-1020 {
  max-width: 1020px;
}

.max-w-1030 {
  max-width: 1030px;
}

.max-w-1040 {
  max-width: 1040px;
}

.max-w-1050 {
  max-width: 1050px;
}

.max-w-1060 {
  max-width: 1060px;
}

.max-w-1070 {
  max-width: 1070px;
}

.max-w-1080 {
  max-width: 1080px;
}

.max-w-1090 {
  max-width: 1090px;
}

.max-w-1100 {
  max-width: 1100px;
}

.max-w-1200 {
  max-width: 1200px;
}

.max-w-1210 {
  max-width: 1210px;
}

.max-w-1220 {
  max-width: 1220px;
}

.max-w-1230 {
  max-width: 1230px;
}

.max-w-1240 {
  max-width: 1240px;
}

.max-w-1250 {
  max-width: 1250px;
}

.max-w-1260 {
  max-width: 1260px;
}

.max-w-1270 {
  max-width: 1270px;
}

.max-w-1280 {
  max-width: 1280px;
}

.max-w-1290 {
  max-width: 1290px;
}

.max-w-1300 {
  max-width: 1300px;
}

.max-w-1310 {
  max-width: 1310px;
}

.max-w-1320 {
  max-width: 1320px;
}

.max-w-1330 {
  max-width: 1330px;
}

.max-w-1340 {
  max-width: 1340px;
}

.max-w-1350 {
  max-width: 1350px;
}

.max-w-1360 {
  max-width: 1360px;
}

.max-w-1370 {
  max-width: 1370px;
}

.max-w-1380 {
  max-width: 1380px;
}

.max-w-1390 {
  max-width: 1390px;
}

.max-w-1400 {
  max-width: 1400px;
}

.max-w-1410 {
  max-width: 1410px;
}

.max-w-1420 {
  max-width: 1420px;
}

.max-w-1430 {
  max-width: 1430px;
}

.max-w-1440 {
  max-width: 1440px;
}

.max-w-1450 {
  max-width: 1450px;
}

.max-w-1460 {
  max-width: 1460px;
}

.max-w-1470 {
  max-width: 1470px;
}

.max-w-1480 {
  max-width: 1480px;
}

.max-w-1490 {
  max-width: 1490px;
}

.max-w-1500 {
  max-width: 1500px;
}

.max-w-1510 {
  max-width: 1510px;
}

.max-w-1520 {
  max-width: 1520px;
}

.max-w-1530 {
  max-width: 1530px;
}

.max-w-1540 {
  max-width: 1540px;
}

.max-w-1550 {
  max-width: 1550px;
}

.max-w-1560 {
  max-width: 1560px;
}

.max-w-1570 {
  max-width: 1570px;
}

.max-w-1580 {
  max-width: 1580px;
}

.max-w-1590 {
  max-width: 1590px;
}

.max-w-1600 {
  max-width: 1600px;
}

.max-w-1610 {
  max-width: 1610px;
}

.max-w-1620 {
  max-width: 1620px;
}

.max-w-1630 {
  max-width: 1630px;
}

.max-w-1640 {
  max-width: 1640px;
}

.max-w-1650 {
  max-width: 1650px;
}

.max-w-1660 {
  max-width: 1660px;
}

.max-w-1670 {
  max-width: 1670px;
}

.max-w-1680 {
  max-width: 1680px;
}

.max-w-1690 {
  max-width: 1690px;
}

.max-w-1700 {
  max-width: 1700px;
}

.max-w-1710 {
  max-width: 1710px;
}

.max-w-1720 {
  max-width: 1720px;
}

.max-w-1730 {
  max-width: 1730px;
}

.max-w-1740 {
  max-width: 1740px;
}

.max-w-1750 {
  max-width: 1750px;
}

.max-w-1760 {
  max-width: 1760px;
}

.max-w-1770 {
  max-width: 1770px;
}

.max-w-1780 {
  max-width: 1780px;
}

.max-w-1790 {
  max-width: 1790px;
}

.max-w-1800 {
  max-width: 1800px;
}

.max-w-1810 {
  max-width: 1810px;
}

.max-w-1820 {
  max-width: 1820px;
}

.max-w-1830 {
  max-width: 1830px;
}

.max-w-1840 {
  max-width: 1840px;
}

.max-w-1850 {
  max-width: 1850px;
}

.max-w-1860 {
  max-width: 1860px;
}

.max-w-1870 {
  max-width: 1870px;
}

.max-w-1880 {
  max-width: 1880px;
}

.max-w-1890 {
  max-width: 1890px;
}

.max-w-1900 {
  max-width: 1900px;
}

.max-w-1910 {
  max-width: 1910px;
}

.max-w-1920 {
  max-width: 1920px;
}

.max-w-1930 {
  max-width: 1930px;
}

.max-w-1940 {
  max-width: 1940px;
}

.max-w-1950 {
  max-width: 1950px;
}

.max-w-1960 {
  max-width: 1960px;
}

.max-w-1970 {
  max-width: 1970px;
}

.max-w-1980 {
  max-width: 1980px;
}

.max-w-1990 {
  max-width: 1990px;
}

.max-w-2000 {
  max-width: 2000px;
}

.max-w-2010 {
  max-width: 2010px;
}

.max-w-2020 {
  max-width: 2020px;
}

.max-w-2030 {
  max-width: 2030px;
}

.max-w-2040 {
  max-width: 2040px;
}

.max-w-2050 {
  max-width: 2050px;
}

.max-w-2060 {
  max-width: 2060px;
}

.max-w-2070 {
  max-width: 2070px;
}

.max-w-2080 {
  max-width: 2080px;
}

.max-w-2090 {
  max-width: 2090px;
}

.min-h-300 {
  min-height: 300px;
}

.min-h-330 {
  min-height: 330px;
}

.min-h-540 {
  min-height: 540px;
}

.min-h-600 {
  min-height: 600px;
}

html {
  font-family: "Poppins", sans-serif;
}

/* BREAKPOINTS ------------ */
/*$breakpoints: ( 
    'phone': 320px, 
    'tablet': 768px, 
    'desktop': 1024px 
) !default;*/
/* COLOURS ------------ */
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
  font-display: swap;
}
table.dataTable {
  clear: both;
  max-width: none !important;
  border-collapse: separate !important;
}

.dataTables_length {
  margin-bottom: 20px;
}

table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: auto;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 10px 0 0 0;
  white-space: nowrap;
  font-size: 0.8em;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
  font-family: "Glyphicons Halflings";
  opacity: 0.5;
}

table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: "\e150";
}

table.dataTable thead .sorting_asc:after {
  content: "\e155";
}

table.dataTable thead .sorting_desc:after {
  content: "\e156";
}

table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  color: #eee;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

table.dataTable tbody tr.highlight.odd td {
  background-color: #FFDDDD !important;
}

table.dataTable tbody tr.highlight.even td {
  background-color: #FFEEEE !important;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
  vertical-align: middle;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=dw-]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=dw-]:last-child {
  padding-right: 0;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  top: 50%;
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  line-height: 16px;
  content: "+";
  background-color: #e3000f;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.child td:before {
  display: none;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  line-height: 14px;
  content: "+";
  background-color: #e3000f;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}

table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

table.dataTable > tbody > tr.child ul.dtr-details li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details li:last-child {
  border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
div.dtr-bs-modal table.table tr:first-child td {
  border-top: none;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #e3000f;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #e3000f;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #333;
  border-color: #333;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  color: #333;
}

.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-bottom: 0px !important;
  background-color: #fff;
}

.table-striped > tbody > tr {
  background-color: #fff;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*=dw-] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*=dw-],
table th[class*=dw-] {
  position: static;
  display: table-cell;
  float: none;
}

.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
  background-color: #e8e8e8;
}

.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
  background-color: #dff0d8;
}

.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover {
  background-color: #d0e9c6;
}

.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
  background-color: #d9edf7;
}

.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover {
  background-color: #c4e3f3;
}

.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover {
  background-color: #faf2cc;
}

.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
  background-color: #f2dede;
}

.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin-top: 10px;
  }
  .dataTables_filter {
    margin-bottom: 15px;
  }
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > thead > tr > th {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
}
.dtp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999999999;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dtp > .dtp-content {
  background: #fff;
  max-width: 300px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  max-height: 565px;
  position: relative;
  left: 50%;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background: #ff8d00;
  color: #fff;
  text-align: center;
  padding: 0.3em;
}

.dtp div.dtp-date, .dtp div.dtp-time {
  background: #2e6ad0;
  text-align: center;
  color: #fff;
  padding: 10px;
}

.dtp div.dtp-date > div {
  padding: 0;
  margin: 0;
}

.dtp div.dtp-actual-month {
  font-size: 1.5em;
}

.dtp div.dtp-actual-num {
  font-size: 3em;
  line-height: 0.9;
}

.dtp div.dtp-actual-maxtime {
  font-size: 3em;
  line-height: 0.9;
}

.dtp div.dtp-actual-year {
  font-size: 1.5em;
  color: #fff;
}

.dtp div.dtp-picker {
  padding: 1em;
  text-align: center;
}

.dtp div.dtp-picker-month, .dtp div.dtp-actual-time {
  font-weight: 500;
  text-align: center;
}

.dtp div.dtp-picker-month {
  padding-bottom: 20px !important;
  text-transform: uppercase !important;
}

.dtp .dtp-close {
  position: absolute;
  top: 4px;
  right: 1em;
}

.dtp .dtp-close > a {
  color: #fff;
}

.dtp .dtp-close > a > i {
  font-size: 1em;
}

.dtp table.dtp-picker-days {
  margin: 0;
  min-height: 251px;
  width: 100%;
}

.dtp table.dtp-picker-days, .dtp table.dtp-picker-days tr, .dtp table.dtp-picker-days tr > td {
  border: none;
}

.dtp table.dtp-picker-days tr > td {
  font-weight: 500;
  font-size: 0.9em;
  text-align: center;
  padding: 0.5em 0.3em;
}

.dtp table.dtp-picker-days tr > td > span.dtp-select-day {
  color: #BDBDBD !important;
}

.dtp table.dtp-picker-days tr > td > a, .dtp .dtp-picker-time > a {
  color: #212121;
  text-decoration: none;
  padding: 0.4em 0.5em 0.5em 0.4em;
  border-radius: 50% !important;
}

.dtp table.dtp-picker-days tr > td > a.selected {
  background: #2e6ad0;
  color: #fff;
}

.dtp table.dtp-picker-days tr > th {
  color: #757575;
  text-align: center;
  font-weight: 700;
  padding: 0.4em 0.3em;
}

.dtp .p10 > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.4em;
}

.dtp .p10 {
  width: 10%;
  display: inline-block;
}

.dtp .p20 {
  width: 20%;
  display: inline-block;
}

.dtp .p60 {
  width: 60%;
  display: inline-block;
}

.dtp .p80 {
  width: 80%;
  display: inline-block;
}

.dtp a.dtp-meridien-am, .dtp a.dtp-meridien-pm {
  position: relative;
  top: 10px;
  color: #212121;
  font-weight: 500;
  padding: 0.7em 0.5em;
  border-radius: 50% !important;
  text-decoration: none;
  background: #eee;
  font-size: 1em;
}

.dtp .dtp-actual-meridien a.selected {
  background: #689F38;
  color: #fff;
}

.dtp .dtp-picker-time > .dtp-select-hour {
  cursor: pointer;
}

.dtp .dtp-picker-time > .dtp-select-minute {
  cursor: pointer;
}

.dtp .dtp-buttons {
  padding: 0 1em 1em 1em;
  text-align: right;
}

.dtp.hidden, .dtp .hidden {
  display: none;
}

.dtp .invisible {
  visibility: hidden;
}

.dtp .left {
  float: left;
}

.dtp .right {
  float: right;
}

.dtp .clearfix {
  clear: both;
}

.dtp .center {
  text-align: center;
}

.dtp-btn-cancel {
  background-color: #ddd;
  color: #666;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  line-height: 38px;
  height: 40px;
  border: 1px solid #ddd;
  width: 100px;
  float: left;
  cursor: pointer;
}

.dtp-btn-cancel:hover {
  background-color: #fff;
}

/*.dtp-btn-ok{ display:none;}*/
.dtp-btn-ok {
  background-color: #ddd;
  color: #666;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  line-height: 38px;
  height: 40px;
  border: 1px solid #ddd;
  width: 100px;
  float: right;
  cursor: pointer;
}

﻿﻿*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container:before, .container:after, .section:before, .section:after {
  display: table;
  content: " ";
}

.container:after, .section:after {
  clear: both;
}

.clear {
  clear: both;
  display: block;
}

.svg-inline--fa.fa-w-18 {
  width: 60px;
  color: #2e6ad0;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.my-10 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12 {
  position: relative;
  min-height: 1px;
  display: block;
}

@media (min-width: 768px) {
  .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
  .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
  .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
  .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
  .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
  .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
  .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
  .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
  .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
  .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
  .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
  .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
  .container {
    padding: 0 15px;
  }
  .section {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 767px) {
  .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
  .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
  .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
  .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
  .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
  .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
  .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
  .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
  .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
  .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
  .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
  .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
  .container {
    padding: 0 10px;
  }
  .section {
    margin-right: -10px;
    margin-left: -10px;
  }
  .hidden-sm {
    display: none;
  }
}
@media (max-width: 568px) {
  .px-sm-12 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .vspace-sm-30 {
    height: 30px;
    width: 100%;
  }
}
.dw-xs-1, .dw-xs-2, .dw-xs-3, .dw-xs-4, .dw-xs-5, .dw-xs-6, .dw-xs-7, .dw-xs-8, .dw-xs-9, .dw-xs-10, .dw-xs-11 {
  float: left;
}

.dw-xs-12 {
  width: 100%;
}

.dw-xs-11 {
  width: 91.6666666667%;
}

.dw-xs-10 {
  width: 83.3333333333%;
}

.dw-xs-9 {
  width: 75%;
}

.dw-xs-8 {
  width: 66.6666666667%;
}

.dw-xs-7 {
  width: 58.3333333333%;
}

.dw-xs-6 {
  width: 50%;
}

.dw-xs-5 {
  width: 41.6666666667%;
}

.dw-xs-4 {
  width: 33.3333333333%;
}

.dw-xs-3 {
  width: 25%;
}

.dw-xs-2 {
  width: 16.6666666667%;
}

.dw-xs-1 {
  width: 8.3333333333%;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
  .dw-sm-1, .dw-sm-2, .dw-sm-3, .dw-sm-4, .dw-sm-5, .dw-sm-6, .dw-sm-7, .dw-sm-8, .dw-sm-9, .dw-sm-10, .dw-sm-11 {
    float: left;
  }
  .dw-sm-12 {
    width: 100%;
  }
  .dw-sm-11 {
    width: 91.6666666667%;
  }
  .dw-sm-10 {
    width: 83.3333333333%;
  }
  .dw-sm-9 {
    width: 75%;
  }
  .dw-sm-8 {
    width: 66.6666666667%;
  }
  .dw-sm-7 {
    width: 58.3333333333%;
  }
  .dw-sm-6 {
    width: 50%;
  }
  .dw-sm-5 {
    width: 41.6666666667%;
  }
  .dw-sm-4 {
    width: 33.3333333333%;
  }
  .dw-sm-3 {
    width: 25%;
  }
  .dw-sm-2 {
    width: 16.6666666667%;
  }
  .dw-sm-1 {
    width: 8.3333333333%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .dw-md-1, .dw-md-2, .dw-md-3, .dw-md-4, .dw-md-5, .dw-md-6, .dw-md-7, .dw-md-8, .dw-md-9, .dw-md-10, .dw-md-11 {
    float: left;
  }
  .dw-md-12 {
    width: 100%;
  }
  .dw-md-11 {
    width: 91.6666666667%;
  }
  .dw-md-10 {
    width: 83.3333333333%;
  }
  .dw-md-9 {
    width: 75%;
  }
  .dw-md-8 {
    width: 66.6666666667%;
  }
  .dw-md-7 {
    width: 58.3333333333%;
  }
  .dw-md-6 {
    width: 50%;
  }
  .dw-md-5 {
    width: 41.6666666667%;
  }
  .dw-md-4 {
    width: 33.3333333333%;
  }
  .dw-md-3 {
    width: 25%;
  }
  .dw-md-2 {
    width: 16.6666666667%;
  }
  .dw-md-1 {
    width: 8.3333333333%;
  }
  .pl-md-0 {
    padding-left: 0;
  }
  .pl-md-40 {
    padding-left: 40px;
  }
  .pl-md-50 {
    padding-left: 50px;
  }
  .pr-md-0 {
    padding-right: 0;
  }
  .pr-md-5 {
    padding-right: 5px;
  }
  .pr-md-50 {
    padding-right: 50px;
  }
  .pr-md-65 {
    padding-right: 65px;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-20 {
    padding-top: 20px;
  }
  .mt-md-10 {
    margin-top: 10px;
  }
  .vspace-md-80 {
    height: 80px;
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .container.wide {
    width: min(100vw - 500px, 1500px);
  }
}
@media (min-width: 1230px) and (max-width: 1599px) {
  .container {
    width: 1230px;
  }
  .container.wide {
    width: min(100vw - 300px, 1500px);
  }
}
@media (min-width: 1230px) {
  .container {
    width: 1230px;
  }
  .dw-lg-1, .dw-lg-2, .dw-lg-3, .dw-lg-4, .dw-lg-5, .dw-lg-6, .dw-lg-7, .dw-lg-8, .dw-lg-9, .dw-lg-10, .dw-lg-11 {
    float: left;
  }
  .dw-lg-12 {
    width: 100%;
  }
  .dw-lg-11 {
    width: 91.6666666667%;
  }
  .dw-lg-10 {
    width: 83.3333333333%;
  }
  .dw-lg-9 {
    width: 75%;
  }
  .dw-lg-8 {
    width: 66.6666666667%;
  }
  .dw-lg-7 {
    width: 58.3333333333%;
  }
  .dw-lg-6 {
    width: 50%;
  }
  .dw-lg-5 {
    width: 41.6666666667%;
  }
  .dw-lg-4 {
    width: 33.3333333333%;
  }
  .dw-lg-3 {
    width: 25%;
  }
  .dw-lg-2 {
    width: 16.6666666667%;
  }
  .dw-lg-1 {
    width: 8.3333333333%;
  }
  .pr-lg-0 {
    padding-right: 0;
  }
  .pr-lg-5 {
    padding-right: 5px;
  }
  .pr-lg-50 {
    padding-right: 50px;
  }
  .pr-lg-65 {
    padding-right: 65px;
  }
}
@-ms-viewport {
  width: device-width;
}
.visible-xs {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-xs.visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-xs.visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-xs.visible-lg {
    display: block !important;
  }
}
.visible-sm {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-sm.visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-sm.visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-sm.visible-lg {
    display: block !important;
  }
}
.visible-md {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-md.visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md.visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-md.visible-lg {
    display: block !important;
  }
}
.visible-lg {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-lg.visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-lg.visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-lg.visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-lg {
    display: block !important;
  }
}
.hidden-xs {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-xs.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-xs.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-xs.hidden-lg {
    display: none !important;
  }
}
.hidden-sm {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-sm.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-sm.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-sm.hidden-lg {
    display: none !important;
  }
}
.hidden-md {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-md.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-md.hidden-lg {
    display: none !important;
  }
}
.hidden-lg {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-lg.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-lg.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-lg.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-lg {
    display: none !important;
  }
}
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  z-index: 1;
}

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  line-height: 1;
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

/*.carousel-inner .item a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}*/
.carousel-indicators {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #8c8c8c;
  border-radius: 10px;
}

.carousel-indicators .active {
  background-color: #2e6ad0;
}

.carousel-caption {
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #fff;
  padding: 0 40px;
}

@media (min-width: 1230px) {
  .carousel-caption {
    width: 1230px;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .carousel-caption {
    max-width: 970px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .carousel-caption {
    max-width: 750px;
  }
}
@media (max-width: 767px) {
  .carousel-caption {
    padding: 0 10px;
  }
  .carousel-caption span {
    display: none;
  }
}
.hor-padd-15 {
  padding: 0px 15px;
}

.section.flx {
  display: flex;
  flex-wrap: wrap;
}
.section.flx .fl-al-cent {
  align-items: center;
}
.section.flx:before {
  display: none;
}
.section.flx:after {
  display: none;
}
.section.flx .list-row {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100%;
  justify-content: center;
}
.section.flx .list-row.left {
  align-items: flex-start;
  text-align: left;
}
.section.flx .list-row.right {
  align-items: flex-end;
  text-align: right;
}

.pl-0 {
  padding-left: 0;
}

.pl-5 {
  padding-left: 5px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-65 {
  padding-left: 65px;
}

.pr-0 {
  padding-right: 0;
}

.pr-5 {
  padding-right: 5px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-65 {
  padding-right: 65px;
}

.pt-0 {
  padding-top: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-30 {
  margin-bottom: 30px;
}

.max-w-670 {
  max-width: 670px;
}

.max-w-750 {
  max-width: 750px;
}

.max-w-950 {
  max-width: 950px;
}

.max-w-970 {
  max-width: 970px;
}

.marg-auto {
  margin: auto;
}

﻿ .bx-wrapper {
  position: relative;
  padding: 0;
  *zoom: 1;
}

/** THEME
===================================*/
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  right: 45%;
  bottom: -50%;
  z-index: 999;
  margin: auto;
  display: block;
}

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(../img/bx_loader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: 0.85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #9fcee6;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-indent: -9999px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #3a468a;
}

.bx-wrapper .bx-next {
  right: 0px;
}

.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}

.homeslide .bx-wrapper .bx-controls-direction a {
  display: none;
}

.bx-wrapper img {
  display: block;
  margin: auto;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  z-index: 9999;
  font-size: 1.4em;
  color: #1d4384;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
}

.rightslider .bx-wrapper .bx-controls-direction a {
  top: 50%;
  color: #fff;
  font-size: 4em;
}

.bx-wrapper .bx-controls-direction a:hover {
  color: #ff8d00;
}

.bx-wrapper .bx-controls-direction a.fa-chevron-left {
  left: 0;
}
@media (min-width: 992px) {
  .bx-wrapper .bx-controls-direction a.fa-chevron-left {
    left: 10px;
  }
}
.bx-wrapper .bx-controls-direction a.fa-chevron-left::before {
  margin-left: -2px;
}

.bx-wrapper .bx-controls-direction a.fa-chevron-right {
  right: 5px;
}
@media (min-width: 992px) {
  .bx-wrapper .bx-controls-direction a.fa-chevron-right {
    right: 10px;
  }
}
.bx-wrapper .bx-controls-direction a.fa-chevron-right::before {
  margin-left: 2px;
}

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: 0.85em;
  padding: 10px;
}

﻿ .fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
  opacity: 1 !important;
}

.fancybox-wrap .title {
  color: #004489;
  font-size: 1.2em;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 45px !important;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
  margin: 0 auto;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../img/fancybox/fancybox_sprite.png");
}

.fancybox-close:hover, .fancybox-prev span:hover, .fancybox-next span:hover {
  background-image: url("../img/fancybox/fancybox_sprite_orange.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../img/fancybox/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}

.fancybox-prev {
  left: -50px;
}

.fancybox-next {
  right: -50px;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: visible;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */
.fancybox-lock {
  overflow-y: scroll;
  margin-right: 0 !important;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 99;
  background: url(../img/fancybox/fancybox_overlay.png);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: hidden;
  overflow-y: hidden;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: #2e6ad0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("../img/fancybox/fancybox_sprite@2x.png");
    background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url("../img/fancybox/fancybox_loading@2x.gif");
    background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
  }
}
﻿﻿.hero {
  position: relative;
  z-index: 90;
}
﻿﻿.hero:hover {
  z-index: 9999;
}

.hero__logged-in {
  background-color: #f6eef3;
  text-align: center;
  color: #2e6ad0;
  font-size: 0.9em;
  padding: 5px;
  font-weight: 600;
  display: none;
}

.hero__top {
  color: #fff;
  background-color: #1d4384;
  display: block;
}
.hero__top .flex {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.hero__top .flex .contactlink {
  color: #2e6ad0;
  text-decoration: none;
  background-color: #fff;
  padding: 5px 10px;
  height: 41px;
  display: inline-block;
  width: auto;
  font-weight: 500;
  line-height: 32px;
  font-size: 0.85em;
  border-radius: 3px 3px 0px 0px;
}
@media (min-width: 992px) {
  .hero__top .dw-xs-2 {
    width: 20.6%;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .hero__top .dw-xs-2 {
    width: 21%;
  }
}
@media (max-width: 990px) {
  .hero__top .dw-xs-2 {
    width: 17%;
  }
}
@media (max-width: 766px) {
  .hero__top .dw-xs-2 {
    width: 130px;
  }
}
.hero__top .dw-xs-2 .fa-twitter {
  margin-left: 50%;
}
.hero__top .dw-xs-2 .contactlink:hover {
  text-decoration: underline;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .hero__top .dw-xs-3 {
    width: 23% !important;
  }
}
@media (min-width: 992px) {
  .hero__top .dw-xs-3 {
    width: 20%;
  }
}
@media (max-width: 990px) {
  .hero__top .dw-xs-3 {
    width: 27%;
  }
}
@media (max-width: 766px) {
  .hero__top .dw-xs-3 {
    display: none;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .hero__top .dw-xs-8 {
    width: 55.4% !important;
  }
}
@media (min-width: 992px) {
  .hero__top .dw-xs-8 {
    width: 79.4%;
  }
}
@media (max-width: 990px) {
  .hero__top .dw-xs-8 {
    width: 83%;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .hero__top .dw-xs-8 {
    width: 70%;
    float: right;
    padding-right: 10px;
  }
}
@media screen and (max-width: 420px) {
  .hero__top .dw-xs-8 {
    width: 65%;
  }
}
@media screen and (max-width: 330px) {
  .hero__top .dw-xs-8 {
    width: 60%;
  }
}

.hero__social {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  position: relative;
}
@media (max-width: 1230px) {
  .hero__social a.fab {
    display: none;
  }
}

.hero--icons {
  color: #fff;
  text-decoration: none;
}
.hero--icons:hover {
  color: #7eccf0;
}

.hero__text {
  text-align: right;
  font-size: 1.1em;
}
.hero__text a {
  color: #ff8d00;
  font-weight: 700;
  text-decoration: none;
}
.hero__text span {
  font-weight: 700;
}
@media (min-width: 992px) {
  .hero__text .tabdown-txt {
    display: none;
  }
}
@media (max-width: 991px) {
  .hero__text {
    font-size: 0.9em;
    text-align: right;
  }
  .hero__text .tabup-txt {
    display: none;
  }
  .hero__text i {
    transform: scaleX(-1);
    color: #ff8d00;
  }
}
@media (max-width: 767px) {
  .hero__text {
    font-size: 1.1em;
  }
}

.hero__link {
  text-align: right;
  margin-top: 5px;
}
.hero__link a {
  color: #7eccf0;
  font-weight: bold;
  margin-left: 5px;
  text-decoration: none;
}
.hero__link a:hover {
  text-decoration: underline;
}
@media (max-width: 990px) {
  .hero__link {
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .hero__link {
    display: none;
  }
}

.hero__logo {
  margin: 10px 0;
  padding-left: 0;
  /*	@include media('<desktop','>tablet') {
  		width: 27.6% !important;
  	}

  	@include media('<=tablet','>phone') {
  		width: 35%;
  	}
  */
}
@media (min-width: 992px) {
  .hero__logo {
    width: 180px;
  }
}
@media (max-width: 991px) {
  .hero__logo {
    text-align: center;
    padding-left: 15px;
    margin-bottom: 0px;
  }
}

a.fadeout {
  opacity: 0.3;
  cursor: initial;
}
a.fadeout:hover span {
  color: #666666;
}

.hero--link {
  color: #818285;
}
.hero--link:hover {
  color: #2e6ad0;
}

.hero__accounts {
  margin-top: 10px;
  float: right;
  font-size: 0.85em;
}
@media (min-width: 1230px) {
  .hero__accounts {
    width: 1000px;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .hero__accounts {
    width: 360px;
    margin-top: 20px;
    float: right;
  }
  .hero__accounts .section {
    margin: 0 -10px;
  }
  .hero__accounts .section .bs {
    padding: 0px 10px;
  }
}
@media (max-width: 991px) {
  .hero__accounts {
    position: static;
    margin-top: 35px;
  }
}
@media (max-width: 767px) {
  .hero__accounts {
    margin-top: 30px;
  }
}

.account--widgets a, .account--cart a {
  text-decoration: none;
  font-weight: 500;
}
.account--widgets i, .account--cart i {
  vertical-align: middle;
}

.account--widgets, .account--cart {
  float: left;
  text-align: center;
  position: relative;
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .account--widgets, .account--cart {
    font-size: 0.9em;
    margin-left: 0;
    display: block;
  }
  .account--widgets .account--text, .account--cart .account--text {
    font-size: 0.8em;
  }
}

@media (max-width: 991px) {
  .account--widgets.browse {
    width: 33%;
    float: right;
  }
}
@media (max-width: 767px) {
  .account--widgets.browse {
    width: 47%;
  }
}
.account--widgets.browse .account--text {
  margin: 8px auto 0;
}

@media (min-width: 991px) {
  .accounts__widget {
    background-color: #eaeaea;
    padding: 15px 0 0;
    height: 90px;
    border-radius: 3px;
  }
}

.account--total {
  font-size: 1.1em;
  display: inline-block;
  color: #666666;
}
@media (max-width: 767px) {
  .account--total {
    display: none;
  }
}
.account--total span {
  color: #1d4384;
  font-weight: 800;
}

.account--text {
  font-size: 1.2em;
  display: inline-block;
  color: #666666;
  margin-left: 15px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .account--text {
    font-size: 1em;
  }
}
@media (max-width: 991px) {
  .account--text {
    font-weight: 700;
    margin-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .account--text {
    margin-left: 0;
    display: block;
  }
}

.hero--user:hover .hero__account {
  visibility: visible;
}

.account--small {
  color: #666666;
  display: block;
}
.account--small a {
  color: #666666;
}
.account--small a:hover {
  color: #2e6ad0;
}
.account--small a span {
  color: #7eccf0;
}
@media (max-width: 767px) {
  .account--small a {
    display: none;
  }
}
@media (max-width: 320px) {
  .account--small a {
    font-size: 0.5em;
  }
}

#noofitems {
  margin-right: 5px;
  display: block;
  position: absolute;
  top: -5px;
  left: 30%;
  background-color: #7eccf0;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 0.8em;
  font-weight: 700;
}
@media (max-width: 767px) {
  #noofitems {
    left: 33px;
  }
}

.account--icons {
  display: block;
  text-align: center;
  font-size: 2.4em;
  margin: 5px 0;
  color: #1d4384;
  min-height: 30px;
}

.hero__account {
  visibility: hidden;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: absolute;
  left: 18px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .hero__account {
    left: 0px;
  }
}
.hero__account a {
  margin-bottom: 5px;
  display: block;
}

.show--items {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -12px;
  right: 7px;
  background-color: #999;
  border-radius: 50%;
  color: #eee;
  font-size: 0.9em;
  line-height: 20px;
}

.hero__company [type=text] {
  border: 1px solid #dfdfdf;
  height: 48px;
  outline: none;
  padding-right: 50px;
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
}

.hero--magnify {
  background-color: #fff;
  color: #1d4384;
  outline: none;
  border: 0;
  position: absolute;
  right: 0;
  height: 48px;
  line-height: 48px;
  width: 50px;
  font-size: 1.3em;
  cursor: pointer;
  border-radius: 0px 3px 3px 0px;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  top: 0px;
}
@media (max-width: 991px) {
  .hero--magnify {
    right: 15px;
    top: 15px;
  }
}

.account--cart:hover .basket__items, .account--cart:hover .triangle {
  visibility: visible;
  display: block;
}
@media (max-width: 767px) {
  .account--cart:hover .basket__items, .account--cart:hover .triangle {
    visibility: hidden;
    display: none;
  }
}
@media (max-width: 991px) {
  .account--cart {
    float: left;
    width: 52%;
  }
}
@media (max-width: 767px) {
  .account--cart {
    width: 25%;
  }
}

.basket-hover {
  position: relative;
}

.basket__items {
  position: absolute;
  top: 100%;
  z-index: 9;
  right: 0;
  visibility: hidden;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  width: 405px;
}
.basket__items h3 {
  color: #2e6ad0;
  font-weight: normal;
  margin-bottom: 35px;
  text-align: left;
}
.basket__items .btn {
  font-size: 1.1em;
  margin-bottom: 0;
}

.triangle-around {
  position: relative;
  margin-top: 30px;
}

.triangle {
  border-style: solid;
  border-color: transparent #eaeaea transparent transparent;
  width: 0;
  position: absolute;
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  border-width: 20px 20px 20px 0;
  right: 35%;
  top: -30px;
  z-index: 1;
  visibility: hidden;
}

.triangle:after {
  content: " ";
  position: absolute;
  border-style: solid;
  border-color: transparent #eaeaea transparent transparent;
  width: 0;
  border-width: 20px 20px 20px 0;
  top: -20px;
  left: 1.6px;
}

.basket__hover--total {
  padding: 25px;
}

.basket-hover--total {
  font-weight: 500;
  color: #666666;
  font-size: 1.33em;
  float: left;
  text-transform: uppercase;
}

.basket-hover-total--amount {
  display: inline-block;
  float: right;
  color: #1d4384;
  font-weight: 500;
  font-size: 1.83em;
}

.basket--amount {
  text-align: left;
  color: #666666;
  font-weight: 500;
  font-size: 1.1em;
  margin: 10px 0 35px 0;
}
.basket--amount span {
  color: #7eccf0;
}

.christmas-banner {
  position: relative;
  background-image: url("../img/banners/xmass-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  padding: 30px 0;
}
.christmas-banner .container.xmass {
  width: 1600px;
}
.christmas-banner .container.xmass .xmass-grid {
  display: grid;
  grid-template-columns: auto auto;
}
.christmas-banner .container.xmass h1:after, .christmas-banner .container.xmass h2:after {
  display: none;
}
.christmas-banner .container.xmass h1, .christmas-banner .container.xmass h2 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
}
.christmas-banner .container.xmass h1 {
  font-size: 5.5rem;
  margin-left: -300px;
}
.christmas-banner .container.xmass h2 {
  font-size: 3rem;
}
.christmas-banner .container.xmass .closed {
  font-size: 2rem;
}
.christmas-banner .container.xmass .sub-title {
  margin-top: 75px;
  font-family: Playfair Display, serif;
  font-size: 1.9rem;
  color: #1d4384;
  font-weight: 600;
}
.christmas-banner .container.xmass .xmass-text {
  max-width: 700px;
}

.carousel--badge {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  background-repeat: no-repeat;
}

.carousel__bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 560px;
}
@media (max-width: 991px) {
  .carousel__bg {
    height: auto;
  }
}

.carousel {
  height: 560px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .carousel {
    height: auto;
  }
}

@media (max-width: 767px) {
  .carousel-caption {
    position: relative;
    top: auto;
    left: auto;
    transform: initial;
  }
}
.carousel-caption .title {
  margin: 30px 0 0;
  color: #fff;
  font-size: 1.5em;
  font-weight: 400;
  text-shadow: 0px 0px 8px rgb(0, 0, 0);
}
@media (max-width: 991px) {
  .carousel-caption .title {
    font-size: 1.2em;
  }
}
.carousel-caption span {
  font-size: 3.8em;
  display: block;
  max-width: 600px;
  font-weight: 500;
  line-height: 1.4;
  color: #1d4384;
}
@media (max-width: 767px) {
  .carousel-caption span {
    font-size: 2.8em;
  }
}
@media screen and (max-width: 410px) {
  .carousel-caption span {
    font-size: 2em;
  }
}
.carousel-caption .btn--primary {
  max-width: 260px;
  display: block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 55px 0;
  font-size: 1em;
}
.carousel-caption .icons {
  margin-top: 60px;
}
.carousel-caption .icons .icons__area {
  border: none;
  background-color: transparent;
}
.carousel-caption .icons .icons__area .fa-inverse {
  color: #2e6ad0;
}
.carousel-caption .icons .icons--fa {
  color: #fff;
  float: left;
}
.carousel-caption .icons .icons--text {
  color: #fff;
  font-size: 0.8em !important;
}

.carousel .item {
  height: 560px;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
@media (max-width: 767px) {
  .carousel .item {
    height: 400px;
  }
}

.carousel .item .container {
  position: relative;
  height: 560px;
}
@media (max-width: 767px) {
  .carousel .item .container {
    height: auto;
    min-height: 400px;
  }
}

.carousel-indicators {
  display: none;
}

.carousel-control {
  position: absolute;
  color: #fff;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50% -50%);
  z-index: 5;
  font-size: 1.8em;
  z-index: 99;
}

@media (min-width: 1802px) {
  .carousel--left {
    left: 10%;
  }
}
@media (max-width: 1799px) and (min-width: 1301px) {
  .carousel--left {
    left: 2%;
  }
}
@media (max-width: 1298px) and (min-width: 1231px) {
  .carousel--left {
    left: 12px;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .carousel--left {
    left: 2%;
  }
}
@media (max-width: 991px) {
  .carousel--left {
    left: 15px;
  }
}
@media (max-width: 767px) {
  .carousel--left {
    display: none;
  }
}

@media (min-width: 1802px) {
  .carousel--right {
    right: 10%;
  }
}
@media (max-width: 1799px) and (min-width: 1301px) {
  .carousel--right {
    right: 2%;
  }
}
@media (max-width: 1298px) and (min-width: 1231px) {
  .carousel--right {
    right: 10px;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .carousel--right {
    right: 2%;
  }
}
@media (max-width: 991px) {
  .carousel--right {
    right: 15px;
  }
}
@media (max-width: 767px) {
  .carousel--right {
    display: none;
  }
}

.usps {
  border-bottom: 1px solid #ccc;
}
.usps a {
  color: #666666;
}
.usps a:hover .usp--bold {
  color: #ff8d00;
}

@media (min-width: 992px) {
  .usps .bx-controls-direction {
    display: none;
  }
}
@media (max-width: 766px) {
  .usps .bx-controls-direction a.fa-chevron-left {
    left: 0px;
  }
}

.usps__full {
  max-width: 1050px;
  margin: auto;
}
@media (max-width: 991px) and (min-width: 768px) {
  .usps__full {
    max-width: 700px;
  }
  .usps__full .inner {
    max-width: 310px;
    margin: 0 auto;
  }
  .usps__full .bx-wrapper .bx-controls-direction .fa-chevron-left {
    left: -30px;
  }
  .usps__full .bx-wrapper .bx-controls-direction .fa-chevron-right {
    right: -30px;
  }
}
@media (max-width: 767px) {
  .usps__full {
    max-width: 300px;
    text-align: center;
  }
}
@media (max-width: 767px) and (min-width: 401px) {
  .usps__full {
    height: 80px;
  }
  .usps__full .bx-wrapper {
    height: 80px;
  }
  .usps__full .bx-wrapper .bx-viewport {
    height: 80px;
  }
  .usps__full .bx-wrapper .usps__area {
    height: 80px;
  }
}
@media (max-width: 767px) and (max-width: 400px) {
  .usps__full {
    height: 135px;
  }
  .usps__full .bx-wrapper {
    height: 135px;
  }
  .usps__full .bx-wrapper .bx-viewport {
    height: 135px;
  }
  .usps__full .bx-wrapper .usps__area {
    height: 135px;
  }
}
@media (max-width: 767px) {
  .usps__full .inner {
    text-align: left;
    display: inline-block;
  }
  .usps__full .usps__area {
    width: 300px !important;
  }
  .usps__full .bx-wrapper .bx-controls-direction .fa-chevron-left {
    left: -60px;
  }
}
@media (max-width: 767px) and (max-width: 400px) {
  .usps__full .bx-wrapper .bx-controls-direction .fa-chevron-left {
    left: -30px;
  }
}
@media (max-width: 767px) {
  .usps__full .bx-wrapper .bx-controls-direction .fa-chevron-right {
    right: -60px;
  }
}
@media (max-width: 767px) and (max-width: 400px) {
  .usps__full .bx-wrapper .bx-controls-direction .fa-chevron-right {
    right: -30px;
  }
}
@media (max-width: 400px) {
  .usps__full {
    max-width: 250px;
  }
  .usps__full .usp__circle {
    display: block;
    text-align: center;
    margin: 0px auto 10px;
  }
  .usps__full .usp__circle i {
    margin: 0 auto;
  }
  .usps__full .usps__area {
    width: 250px !important;
  }
  .usps__full .bx-viewport {
    height: 136px !important;
  }
  .usps__full .usp--around {
    text-align: center;
  }
}

.usps__area {
  padding: 15px 0;
}
.usp__circle {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
  height: 50px;
  width: 50px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.usp__circle i {
  text-align: center;
  color: #2e6ad0;
  display: block;
  font-size: 1.5em;
  line-height: 50px;
}

.usp--around {
  display: inline-block;
  vertical-align: middle;
}

.usp--bold {
  color: #1d4384;
  font-weight: 500;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .usp--bold {
    font-size: 0.8em;
  }
}

.usp--text {
  display: block;
  font-size: 0.9em;
  color: #666666;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .usp--text {
    font-size: 0.75em;
  }
}

.ranges {
  margin-top: 40px;
}
.ranges h1 {
  margin: 0;
  text-align: center;
  font-weight: 500;
  color: #1d4384;
  position: relative;
}
@media (max-width: 767px) {
  .ranges h1 {
    font-size: 1.7em;
  }
}
@media (max-width: 320px) {
  .ranges h1 {
    font-size: 1.5em;
  }
}
.ranges h1:before {
  margin-left: -265px;
  text-align: right;
}
.ranges h1:after {
  margin-left: 15px;
}
.ranges h1:before, .ranges h1:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
  height: 1px;
  content: "";
  border-top: 1px solid #1d4384;
}
.ranges .bx-wrapper {
  margin: auto;
}
.ranges .bx-wrapper .bx-controls-direction a {
  top: 50%;
  color: #1d4384;
}
.ranges .bx-wrapper .bx-controls-direction .fa-chevron-left {
  left: -40px;
}
@media (max-width: 990px) {
  .ranges .bx-wrapper .bx-controls-direction .fa-chevron-left {
    left: -20px;
  }
}
@media (max-width: 767px) {
  .ranges .bx-wrapper .bx-controls-direction .fa-chevron-left {
    left: -10px;
  }
}
.ranges .bx-wrapper .bx-controls-direction .fa-chevron-right {
  right: -40px;
}
@media (max-width: 990px) {
  .ranges .bx-wrapper .bx-controls-direction .fa-chevron-right {
    right: -20px;
  }
}
@media (max-width: 767px) {
  .ranges .bx-wrapper .bx-controls-direction .fa-chevron-right {
    right: -10px;
  }
}
.ranges .bx-viewport {
  margin-bottom: 50px;
}
.ranges img {
  margin: auto;
}
@media (max-width: 990px) {
  .ranges.location {
    padding-top: 25px;
  }
}

.sale--items {
  margin-bottom: 15px;
}
@media (max-width: 990px) {
  .sale--items.dw-sm-6:nth-child(2n+1) {
    clear: both;
  }
}
@media screen and (max-width: 768px) and (min-width: 550px) {
  .dw-xs-12.sale--items, .listing--boot {
    width: 50% !important;
    float: left;
  }
}

@media (min-width: 991px) {
  .range__around:nth-child(4n+1) {
    clear: both;
  }
}

@media (min-width: 991px) {
  .range__around.range--slider:nth-child(4n+1) {
    clear: none;
  }
}

@media (min-width: 991px) {
  .dw-md-3.sale--items:nth-child(5) {
    clear: both;
  }
}

@media (max-width: 767px) {
  .related--prod {
    margin: 0 24px;
  }
}
.related--prod .range--price {
  text-align: center;
  width: initial;
  display: block;
  min-height: 75px;
}
.related--prod .range--reserve {
  float: right;
}
@media (max-width: 550px) {
  .related--prod {
    margin: 0 2.2%;
  }
}
@media (max-width: 400px) {
  .related--prod {
    margin: 0 1.2%;
  }
}
@media (max-width: 330px) {
  .related--prod {
    margin: 0%;
  }
}

.location-page-banner {
  height: 265px;
  background-position: center;
  background-size: cover;
}

.no--slider .range__around {
  margin-right: 10px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .no--slider .range__around {
    width: 23.999%;
  }
}
@media (max-width: 991px) {
  .no--slider .range__around {
    width: 31.9%;
  }
}
@media (max-width: 767px) {
  .no--slider .range__around {
    width: 48.333% !important;
  }
}
@media (max-width: 665px) {
  .no--slider .range__around {
    width: 100% !important;
  }
}
.no--slider .range__around:nth-child(4) {
  margin-right: 0;
}

.flx-section {
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .flx-section.reverse {
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .flx-section {
    flex-direction: column;
    grid-gap: 15px;
  }
  .flx-section.reverse {
    flex-direction: column-reverse;
  }
}

h3.loc:after {
  display: none !important;
}

h3.loc:before {
  display: none !important;
}

.range__intro {
  text-align: center;
  font-weight: 400;
  margin: 30px auto 40px;
  max-width: 980px;
}
.range__intro.loc-txt {
  text-align: start;
}
@media (max-width: 767px) {
  .range__intro {
    font-size: 0.9em;
  }
}
.range__intro a {
  text-decoration: underline;
  font-weight: 500;
  color: #1d4384;
}
.range__intro a:hover {
  text-decoration: none;
  color: #ff8d00;
}
.range__intro.location {
  display: flex;
  align-items: center;
  gap: 50px;
  max-width: unset;
}
@media (max-width: 1230px) {
  .range__intro.location {
    flex-direction: column;
  }
}

.range__around {
  position: relative !important;
}

.range__link.addcursor {
  cursor: pointer;
}
.range__link.addcursor .btn {
  cursor: pointer;
}

.range__link {
  display: block;
  text-decoration: none;
}
@media (max-width: 766px) {
  .range__link .range--reserve {
    width: 50%;
  }
  .range__link .range--finance {
    width: 50%;
  }
}
.range__link:hover .range__block {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.range__link:hover .btn--primary {
  background-color: #ff8d00;
  border: 1px solid #ff8d00;
}

.range__top {
  padding: 8px 15px 15px 15px;
}
.range__top img {
  margin: auto;
}

.range__block {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .range__block {
    margin-bottom: 30px;
  }
}
.range__block .btn--primary {
  margin-bottom: 0px;
  margin-top: 0px;
}
.range__block.orange--border {
  border: 1px solid #ff8d00;
}

.block--accessories select {
  margin-bottom: 5px;
}
.block--accessories .range__top-info {
  margin-top: 25px;
  padding: 0 5px;
}
.block--accessories .range__top-info .range--title {
  text-align: left;
  font-weight: 500;
}
.block--accessories .range__top-info .range--price {
  text-align: center;
  width: initial;
  display: block;
}
@media (min-width: 768px) {
  .block--accessories .range__top-info .range--price.mar23 {
    margin-top: -23px;
  }
}
.block--accessories .range__top-info .range--info {
  display: none;
}
.block--accessories .range__bottom {
  display: none;
}

.range__top-info {
  padding: 0 10px;
  text-align: center;
  position: relative;
}
.range__top-info .squares {
  position: absolute;
  left: 5%;
  bottom: 10px;
  width: 90%;
}
@media (max-width: 550px) {
  .range__top-info .squares {
    position: static;
    width: 100%;
    margin-bottom: 10px;
  }
}

.range--title {
  font-size: 1.2em;
  color: #1d4384;
  margin: 5px auto;
  min-height: 60px;
}

.range--description {
  text-align: left;
  color: #666666;
  font-size: 0.8em;
  margin-bottom: 15px;
  min-height: 100px;
}

.range--price {
  color: #ff8d00;
  font-weight: 700;
  font-size: 1.2em;
  min-height: 53px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}
.range--price .was--price {
  font-size: 0.8em;
  color: #adadad;
  font-weight: 500;
  text-decoration: line-through;
  display: block;
  line-height: normal;
}
.range--price .price span {
  color: #666666;
  font-size: 0.8em;
}
.range--price .price span.sale {
  display: block;
}
.range--price .price span.save--price {
  color: #fe4c0c;
  display: block;
  line-height: normal;
}

.range--reserve {
  background-color: #1d4384;
  color: #fff;
  width: 50%;
  text-align: center;
  font-size: 0.8em;
  padding: 5px;
  margin: 0 auto;
  float: right;
}
@media (max-width: 1229px) and (min-width: 768px) {
  .range--reserve {
    float: none !important;
    margin-bottom: 10px;
    width: 100%;
  }
}
.range--reserve span {
  font-weight: 700;
  display: block;
}

.range--finance {
  background-color: #7eccf0;
  text-align: center;
  font-size: 0.8em;
  padding: 5px;
  margin: 0 auto !important;
  color: #2e6ad0 !important;
  float: none;
  width: 100%;
}
.range--finance .range--bold {
  color: #2e6ad0 !important;
}

.range--info {
  color: #666666;
  font-size: 0.8em;
  display: block;
  margin-top: 10px;
  position: relative;
}
.range--info.hov {
  cursor: pointer;
}
.range--info.hov:hover .fa-info {
  background-color: #ff6a00;
}
.range--info .fa-info {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: block;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  background-color: #2e6ad0;
  color: #fff;
  border-radius: 100%;
}

.range--bold {
  color: #72c5ec;
  font-weight: 700;
}

.range__bottom {
  text-align: center;
  background-color: #eaeaea;
}
@media (max-width: 550px) {
  .range__bottom {
    margin-top: 10px;
    clear: both;
  }
}
.range__bottom .section {
  margin: 0;
  padding: 15px;
}
.range__bottom .btn {
  margin-top: 15px;
  margin-bottom: 0;
  cursor: default;
}
.range__bottom img {
  min-height: 50px;
  margin: auto;
}

.range--amount {
  color: #1d4384;
  font-weight: 700;
  font-size: 1.4em;
}

.range--text {
  font-weight: 600;
  color: #666666;
  font-size: 0.9em;
}
@media (max-width: 415px) {
  .range--text {
    font-size: 0.65em;
  }
}

.useful__links {
  margin-bottom: 25px;
}
.useful__links .btn--blue {
  max-width: 210px;
}
@media screen and (max-width: 420px) {
  .useful__links .btn--blue {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .useful__links {
    margin-bottom: 15px;
  }
}

.use--gal {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .use--gal .dw-md-7 {
    width: 57.7%;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .use--gal .dw-md-5 {
    width: 42.3%;
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .use--gal img {
    width: 100%;
  }
}

.use--links .dw-sm-4 {
  padding: 0 12.5px;
}

.useful__links--image {
  position: relative;
  width: 100%;
  min-height: 310px;
  max-width: 390px;
  margin: auto;
}

.useful__links--bg {
  background-color: #1d4384;
  min-height: 300px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .useful__links--bg {
    min-height: 235px;
  }
}
.useful__links--bg .useful__links--text {
  padding: 0 70px 0 40px;
}
@media screen and (max-width: 420px) {
  .useful__links--bg .useful__links--text {
    padding: 0 40px 0 40px;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .useful__links--bg .useful__links--title {
    font-size: 1.4em;
  }
}

.useful__links--image--one {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/accessories.jpg") no-repeat center;
}

.useful__links--image--two {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/buyers-guide.jpg") no-repeat center;
}
@media (max-width: 767px) {
  .useful__links--image--two {
    margin: 30px auto;
  }
}

.useful__links--image--three {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/delivery.jpg") no-repeat center;
}

.useful__links--link .btn {
  border: none;
}
.useful__links--link:hover .useful__links--bg btn {
  background-color: #2e6ad0;
}
.useful__links--link:hover .useful__links--image--one .btn {
  background-color: #2e6ad0;
}
.useful__links--link:hover .useful__links--image--two .btn {
  background-color: #2e6ad0;
}
.useful__links--link:hover .useful__links--image--three .btn {
  background-color: #2e6ad0;
}

.useful__links--text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 100%;
  padding: 0 40px;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .useful__links--text {
    padding: 0 20px;
  }
}

.useful__links--top {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 0.85em;
}

.useful__links--title {
  font-size: 1.8em;
  font-weight: 600;
  max-width: 310px;
}
@media (max-width: 991px) {
  .useful__links--title {
    font-size: 1.4em;
  }
}

.offers h2 {
  text-align: center;
  font-weight: 500;
  color: #1d4384;
}
@media (max-width: 767px) {
  .offers h2 {
    font-size: 1.4em;
    padding: 0 20px;
  }
}

.offers__block {
  position: relative;
  min-height: 285px;
  background-repeat: no-repeat;
  background-size: cover;
}
.offers__block .section {
  margin: 0;
}

@media (min-width: 768px) {
  .offers__block--left {
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .offers__block--middle {
    padding: 0;
  }
}
@media (max-width: 766px) {
  .offers__block--middle {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .offers__block--right {
    padding-left: 0;
  }
}

.offers__block--image {
  min-height: 285px;
  background-size: cover;
  width: 100%;
}

.offers__block--ben {
  min-height: 400px;
  background-size: cover;
  width: 100%;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .offers__block--ben {
    background-size: cover !important;
    min-height: 508px;
    background-position: 5% !important;
  }
}

.offers__block--image-one {
  background: url("../img/tile--one.jpg") no-repeat center;
}

.offers__block--image-two {
  background: url("../img/hot-tub-smart-tv.jpg") no-repeat center;
}

.offers__block--image-three {
  background: url("../img/tile--three.jpg") no-repeat center;
}

.offers__block--image-four {
  background-image: url("../img/tile--four.jpg");
  background-position: 50%;
}

.offers__block--image-five {
  background-image: url("../img/tile--five.jpg");
  background-position: 50%;
}

.offers__block--image-six {
  background-image: url("../img/tile--six.jpg");
  background-position: 50%;
}

.offers__text {
  width: 100%;
  text-align: center;
  max-width: 400px;
  background-color: #eaeaea;
  min-height: 285px;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .offers__text {
    min-height: unset;
    margin: auto;
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    margin-bottom: 0px;
    max-width: 100%;
    padding-bottom: 25px;
  }
}

.benefits--box {
  min-height: 400px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .benefits--box {
    min-height: 508px;
  }
}
@media (max-width: 767px) {
  .benefits--box {
    min-height: 10px;
  }
}

.offers__title {
  font-size: 1.5em;
  font-weight: 400;
  padding-top: 45px;
  color: #1d4384;
}
@media (max-width: 991px) {
  .offers__title {
    padding-top: 30px;
    font-size: 1.3em;
  }
}

.offers-title--benefits {
  padding-top: 15px;
}

.offers__detail {
  font-weight: 300;
  font-style: normal;
  font-size: 0.9em;
  max-width: 325px;
  margin: 25px auto 30px;
}
@media (max-width: 991px) {
  .offers__detail {
    font-size: 0.8em;
  }
}

.offers--benefits {
  margin: 8px auto;
  max-width: 365px;
  font-size: 0.85em;
}
@media (max-width: 767px) {
  .offers--benefits {
    max-width: 100%;
  }
}

.offers--btn {
  max-width: 255px;
  margin: 0 auto;
}
.offers--btn.loc-txt {
  margin: 0;
}
.offers--btn.loc-txt.location--page {
  margin: auto;
}

.superstore-finance {
  display: flex;
  justify-content: center;
  gap: 25px;
  padding-bottom: 50px;
}
.superstore-finance a.offers--btn {
  margin: unset !important;
}

.offer--badge {
  position: absolute;
  top: 9px;
  right: 10px;
  z-index: 10;
  background-repeat: no-repeat;
}

@media (min-width: 991px) {
  .listing--product .dw-md-3 {
    width: 25%;
  }
}
@media (min-width: 991px) {
  .listing--product .dw-md-3:nth-child(4n+1) {
    clear: both;
  }
}

.offers__bottom {
  margin-bottom: 50px;
}
.offers__bottom .section {
  margin-right: 0px;
}
.offers__bottom h2 {
  max-width: 260px;
  text-align: center;
  margin: auto auto 15px;
}
.offers__bottom .left {
  background-color: #f6eef3;
  padding-right: 0;
}
@media (min-width: 992px) {
  .offers__bottom .left {
    width: 42.4%;
  }
}
.offers__bottom .right {
  padding-left: 0;
}
@media (min-width: 992px) {
  .offers__bottom .right {
    width: 57.6%;
  }
}
@media (min-width: 991px) {
  .offers__bottom .right {
    min-height: 245px;
  }
}
@media (min-width: 991px) {
  .offers__bottom .right img {
    min-height: 245px;
  }
}
.offers__bottom .btn {
  max-width: 270px;
  margin: 25px auto 0;
}

.offers--surround {
  padding: 20px 40px;
  min-height: 245px;
  text-align: center;
  margin: auto;
  display: block;
}
.offers--surround a:hover .btn--black {
  background-color: #000000;
  border: 1px solid #000000;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .offers--surround {
    padding: 10px 30px;
  }
}
@media (max-width: 767px) {
  .offers--surround {
    padding: 20px;
  }
}

.featured {
  margin-bottom: 50px;
}
.featured h2 {
  text-align: center;
  margin-bottom: 20px;
}
.featured h3 {
  font-weight: 300;
  font-size: 1em;
  text-align: center;
  margin: 15px auto;
  padding: 0 8px;
}
@media (max-width: 767px) {
  .featured h3 {
    font-size: 0.95em !important;
    min-height: 40px;
  }
}
.featured span {
  text-align: center;
  color: #666666;
  display: block;
  margin: 0 auto 20px;
  padding: 0 8px;
}
.featured .section {
  margin: 0 -5px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .featured .dw-md-3 {
    width: 315px !important;
  }
}
@media (min-width: 992px) {
  .featured .dw-md-3 {
    width: 200px;
    padding: 0 7px;
  }
}

.feat__prod {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  border: 1px solid #ccc;
  margin: 0 auto 10px;
}
.feat__prod span {
  color: #a4a4a4;
  font-size: 0.85em;
  min-height: 50px;
}
@media (max-width: 767px) {
  .feat__prod span {
    display: none;
  }
}
.feat__prod .feat__prod--image {
  background-color: #f6eef3;
}
.feat__prod .feat__prod--image img {
  margin: auto;
  display: block;
}

.internal ul {
  padding: 10px 20px;
  margin-bottom: 20px;
}
.internal ul strong, .internal ul li {
  color: #2e6ad0;
}
.internal ul span {
  color: #666666;
}
.internal .pages {
  padding-bottom: 0px !important;
}
.internal .pages .bottom {
  display: block;
  text-align: center;
}
.internal .pages .bottom strong {
  color: #1d4384;
}

.internal.privacy h3:before, .internal.privacy h3:after {
  border-top: none;
}

.internal__banner {
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1d4384;
  height: 320px;
  position: relative;
  margin-bottom: 35px;
  background-size: cover !important;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .internal__banner {
    background-position: 20% !important;
  }
}
@media (max-width: 500px) {
  .internal__banner {
    min-height: 320px;
    height: auto;
    padding: 30px 0;
    background-position: 100% !important;
  }
}
.internal__banner .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  .internal__banner .container {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .internal__banner .container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.internal__banner h1 {
  text-align: center;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  text-shadow: 0 1px 2px #000;
}
.internal__banner h1:before, .internal__banner h1:after {
  border-top: 1px solid #fff;
}
.internal__banner .internal__sub {
  color: #fff;
  text-align: center;
  width: 100%;
  text-shadow: 1px 1px 2px #000;
}

@media (max-width: 767px) {
  .review--banner {
    height: auto !important;
    background-size: cover !important;
    padding: 5px;
  }
}
@media (max-width: 767px) {
  .review--banner .internal__sub, .review--banner h1 {
    position: relative;
    top: auto;
    left: auto;
    margin-top: 0;
    transform: none;
  }
}
@media (min-width: 767px) {
  .review--banner h1 {
    top: 40%;
  }
}

.banner--contact {
  background-image: url("../img/banners/prem-hot-tubs-contact.jpg");
  background-position: 50%;
}
@media (max-width: 500px) {
  .banner--contact {
    background-image: url("../img/banners/mobile/contact-us.jpg");
  }
}

.banner--about {
  background-image: url("../img/banners/prem-hot-tubs-about.jpg");
}
@media (max-width: 500px) {
  .banner--about {
    background-image: url("../img/banners/mobile/about-us.jpg");
  }
}

.banner--review {
  background-image: url("../img/banners/happy-hot-tub-reviews.jpg");
}
@media (max-width: 500px) {
  .banner--review {
    background-image: url("../img/banners/mobile/happy-hot-tub-reviews-mobile.jpg");
  }
}

.banner--gallery {
  background-image: url("../img/banners/prem-hot-tubs-gallery.jpg");
}
@media (max-width: 500px) {
  .banner--gallery {
    background-image: url("../img/banners/mobile/gallery.jpg");
  }
}

.banner--warranty {
  background-image: url("../img/banners/banner-warranty.jpg");
  background-position: 70%;
}
@media (max-width: 500px) {
  .banner--warranty {
    background-image: url("../img/banners/mobile/warranty.jpg");
  }
}

.banner--guide {
  background-image: url("../img/banners/buyers-guide.jpg");
}

.banner--generic {
  background-image: url("../img/banners/prem-hot-tubs.jpg");
}

.banner--delivery {
  background-image: url("../img/banners/prem-hot-tubs--delivery.jpg");
}
@media (max-width: 500px) {
  .banner--delivery {
    background-image: url("../img/banners/mobile/delivery.jpg");
    background-position: left !important;
  }
}

.banner--benefits {
  background-image: url("../img/banners/prem-hot-tubs--benefits.jpg");
}
@media (max-width: 500px) {
  .banner--benefits {
    background-image: url("../img/banners/mobile/benefits.jpg");
  }
}

.banner--music {
  background-image: url("../img/banners/tv-bann.jpg");
  background-position: top;
}
@media (max-width: 500px) {
  .banner--music {
    background-image: url("../img/banners/mobile/smart-tv.jpg");
  }
}

.banner--jets {
  background-image: url("../img/banners/jets-banner.jpg");
  background-position: top;
}
@media (max-width: 500px) {
  .banner--jets {
    background-image: url("../img/banners/mobile/jets.jpg");
  }
}

.banner--manuals {
  background-image: url("../img/banners/prem-hot-tubs-manual.jpg");
}
@media (max-width: 500px) {
  .banner--manuals {
    background-image: url("../img/banners/mobile/manuals-and-videos.jpg");
  }
}

.banner--service {
  background-image: url("../img/banners/prem-hot-tubs-servicing.jpg");
}
@media (max-width: 500px) {
  .banner--service {
    background-image: url("../img/banners/mobile/service.jpg");
  }
}

.banner--payment {
  background-image: url("../img/banners/prem-hot-tubs--payment.jpg");
}
@media (max-width: 500px) {
  .banner--payment {
    background-image: url("../img/banners/mobile/payment-options.jpg");
  }
}

.banner--energy {
  background-image: url("../img/banners/prem-hot-tubs-energy.jpg");
}
@media (max-width: 500px) {
  .banner--energy {
    background-image: url("../img/banners/mobile/energy-efficient.jpg");
  }
}

.banner--maintenance {
  background-image: url("../img/banners/prem-hot-tubs-maintenance.jpg");
  background-position: 70%;
}
@media (max-width: 500px) {
  .banner--maintenance {
    background-image: url("../img/banners/mobile/maintenance.jpg");
  }
}

.banner--lighting {
  background-image: url("../img/banners/prem-hot-tubs-lighting.jpg");
}
@media (max-width: 500px) {
  .banner--lighting {
    background-image: url("../img/banners/mobile/lighting.jpg");
  }
}

.banner--water {
  background-image: url("../img/banners/prem-hot-tubs-water.jpg");
}
@media (max-width: 500px) {
  .banner--water {
    background-image: url("../img/banners/mobile/water-features.jpg");
  }
}

.banner--faqs {
  background-image: url("../img/banners/banner-faq.jpg");
}
@media (max-width: 500px) {
  .banner--faqs {
    background-image: url("../img/banners/mobile/faq-mobile-banner.jpg");
  }
}

.banner--finance {
  background-image: url("../img/banners/prem-hot-tubs-finance.jpg");
  background-position: 70%;
}
@media (max-width: 500px) {
  .banner--finance {
    background-image: url("../img/banners/mobile/finance.jpg");
    background-position: 10% !important;
  }
}

.banner--support {
  background-image: url("../img/banners/prem-hot-tubs-support.jpg");
  background-position: 50%;
}
@media (max-width: 500px) {
  .banner--support {
    background-image: url("../img/banners/mobile/support.jpg");
  }
}

.internal--sub {
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
  margin: 50px auto 20px;
}

.internal__chapters--items.active {
  color: #2e6ad0;
  text-decoration: underline;
}

.internal__chapters {
  margin: 170px auto;
  color: #666666;
}
.internal__chapters p {
  margin: 15px 0;
}
.internal__chapters h3 {
  font-size: 1.6em;
  font-weight: 400;
}

.internal__chapters--side {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
@media (max-width: 767px) {
  .internal__chapters--side {
    position: static;
  }
}

.chapter--selected {
  color: #2e6ad0;
  font-weight: 600;
  text-decoration: underline;
}

.internal__chapters--list {
  background-color: #1d4384;
  padding: 20px;
}
@media (max-width: 991px) {
  .internal__chapters--list {
    margin-top: 30px;
  }
}
.internal__chapters--list li {
  list-style: none;
  margin: 15px auto;
  cursor: pointer;
}

.internal__chapter--post {
  display: none;
}

.internal--line {
  border: 1px solid #dfdfdf;
  margin: 30px auto;
}

.internal__content {
  padding: 0 80px;
}
@media (max-width: 767px) {
  .internal__content {
    padding: 0 20px;
  }
}
.internal__content h1 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;
}
.internal__content h2 {
  text-align: left;
}
.internal__content h2:before, .internal__content h2:after {
  border-top: none;
}
.internal__content h3 {
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #1d4384;
}
.internal__content h3:before, .internal__content h3:after {
  border-top: none;
}
.internal__content .btn {
  display: block;
  margin: 30px auto;
}
.internal__content .btn span {
  font-weight: 500;
  margin-left: 5px;
}

.internal--body {
  display: block;
  margin: 20px 0;
  font-weight: 300;
  color: #666666;
}
.internal--body p {
  margin: 10px 0;
}
.internal--body ul {
  padding: 10px 20px;
}
.internal--body ul strong {
  color: #2e6ad0;
}
.internal--body a {
  color: #2e6ad0;
  text-decoration: underline;
  font-weight: 700;
}
.internal--body a:hover {
  color: #ff8d00;
  text-decoration: none;
}

.internal--center {
  text-align: center !important;
  color: #2e6ad0 !important;
}

.internal--primary {
  color: #2e6ad0;
  font-weight: 700;
  text-decoration: underline;
}

.internal--ordered-list {
  margin-top: 20px;
  padding: 0 25px;
  font-size: 0.9em;
}
.internal--ordered-list ol li, .internal--ordered-list ul li {
  color: #2e6ad0;
  font-weight: 700;
}
.internal--ordered-list ol li span, .internal--ordered-list ul li span {
  color: #666666;
  font-weight: 300;
}

.testimonials {
  background-color: #1d4384;
  border: 1px solid #ccc;
  padding: 35px;
  margin-bottom: 25px;
}
.testimonials img {
  margin-bottom: 20px;
}

.testimonials--quote {
  font-style: italic;
  display: block;
  font-weight: 400;
}

.testimonials--name {
  color: #2e6ad0;
  font-weight: 700;
  margin: 10px 0;
  display: block;
}

.conpage {
  border: 0.5px solid #707070;
  background-color: #EAEAEA;
  border-radius: 5px;
  margin-top: 10px;
  padding: 25px 25px 15px 25px;
}
.conpage strong {
  color: #1d4384;
  font-size: 1.3em;
  display: block;
  font-weight: 500;
}
.conpage span.toptext {
  display: block;
  margin: 10px 0 15px 0;
  font-size: 0.9em;
}
.conpage .hide {
  display: none;
}
.conpage .btn {
  margin-top: 0;
}

.all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #eaeaea;
  color: #666666;
  font-size: 1em;
}
.footer h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
}
@media (max-width: 500px) {
  .footer .sml-full {
    width: 100% !important;
    text-align: left;
    padding-bottom: 15px;
  }
}

.footer--logo img {
  margin: auto;
  display: block;
}

.footer--info {
  display: block;
  margin: 10px 0 0;
  font-size: 0.85em;
}
.footer--info i {
  margin-right: 15px;
}
.footer--info .foot--left {
  margin-left: 30px;
}
.footer--info .custom--highlight {
  font-weight: 600;
}

.footer--info.bottom {
  margin-top: 2px;
}

.footer--info.bottomer {
  margin-top: 10px;
}

.footer--locations {
  display: flex;
  gap: 40px;
}

.footer--tel a {
  font-weight: 600;
  color: #ff8d00;
  display: block;
  font-size: 1.6em;
  text-decoration: none;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer--tel a {
    font-size: 1.2em;
  }
}
@media (max-width: 991px) {
  .footer--tel a {
    font-size: 1em;
  }
}
.footer--tel a:hover {
  color: #2e6ad0;
}

.footer--email a {
  font-weight: 600;
  color: #7eccf0;
  display: block;
  font-size: 1.1em;
  text-decoration: none;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer--email a {
    font-size: 1em;
  }
}
@media (max-width: 991px) {
  .footer--email a {
    font-size: 1em;
  }
}
.footer--email a:hover {
  color: #2e6ad0;
}

.footer--legal {
  text-align: center;
}
.footer--legal a {
  font-size: 0.6em;
  color: #666666;
  text-decoration: none;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer--legal a {
    font-size: 0.55em;
  }
}
@media (max-width: 991px) {
  .footer--legal a {
    font-size: 0.55em;
  }
}
.footer--legal a:hover {
  color: #2e6ad0;
}
.footer--legal .dash--break {
  color: #666666;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer--legal .dash--break {
    margin: 0 3px;
  }
}

.social__media {
  margin: auto;
  text-align: center;
}
.social__media.confirm {
  text-align: left;
  margin-top: -20px;
}
.social__media.confirm .footer--icons {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 1.5em;
}
.social__media .footer--icons {
  background-color: #7eccf0;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
  margin: 15px auto;
  text-decoration: none;
}
.social__media .footer--icons:hover {
  background-color: #2e6ad0;
}

.footer--title.finance {
  margin: 10px 0;
}

.footer--title {
  font-size: 1.1em;
  color: #1d4384;
  font-weight: 500;
}
@media (max-width: 991px) {
  .footer--title {
    font-size: 1em;
  }
}
.footer--title i {
  margin-right: 15px;
  vertical-align: middle;
  font-size: 1.6em;
}

.foot--links {
  color: #666666;
  display: block;
  margin: 5px 0;
  font-size: 0.9em;
  text-decoration: none;
}
.foot--links i {
  color: #ff8d00;
  margin-right: 10px;
}
.foot--links:hover {
  color: #2e6ad0;
}

@media (max-width: 767px) {
  .footer--col-last {
    margin-bottom: 15px;
  }
}

.footer--link-smaller {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .footer--link-smaller {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 420px) {
  .footer--link-smaller {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer--link-smaller .foot--links {
    margin: 20px 0;
  }
}

@media (min-width: 767px) {
  .footer--title.drop i {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer--title.drop i {
    display: block;
    float: right;
    color: #ff8d00;
  }
}

@media (max-width: 767px) {
  .foot--links.closed {
    display: none;
  }
}

@media (max-width: 767px) {
  .footer--logo {
    margin-top: 30px;
  }
}
@media (max-width: 767px) and (max-width: 1230px) {
  .footer--logo {
    margin-top: 0px;
  }
}
@media screen and (max-width: 767px) and (max-width: 420px) {
  .footer__link {
    padding: 50px 5px;
  }
}
.footer__link {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
}
@media (max-width: 1230px) {
  .footer__link {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .footer__link {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .footer__link {
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .footer__link {
    padding: 50px 15px 20px 15px;
  }
}
@media (max-width: 767px) {
  .footer__link .footer--below {
    clear: both;
  }
}
.footer__link .footer--link-smaller {
  /*@include media('<=phone') {
  	display: none;
  }*/
}
@media (max-width: 991px) {
  .footer__link .dw-sm-4:nth-child(3n+1) {
    clear: both;
  }
}
.footer__link .links {
  margin-top: 10px;
}
@media (max-width: 991px) {
  .footer__link .links {
    margin-top: 10px;
  }
}

.btm--links {
  margin: 15px 0;
  font-size: 0.65em;
  color: #999999;
}
@media (max-width: 767px) {
  .btm--links {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .btm--links .right {
    text-align: right;
  }
}

.dw--link {
  color: #999999;
}
.dw--link:hover {
  color: #2e6ad0;
}

.footer--line {
  border: 1px solid #1d4384;
  margin-top: 50px;
}

.footer__newsletter {
  background-color: #1d4384;
  margin: auto;
}
@media (max-width: 767px) {
  .footer__newsletter {
    padding-bottom: 25px;
  }
}
.footer__newsletter .footer__news {
  font-weight: 400;
  color: #fff;
  display: inline-block;
  padding: 20px 15px 15px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer__newsletter .footer__news {
    padding: 20px 6px 15px;
  }
}
@media (max-width: 767px) {
  .footer__newsletter .footer__news {
    text-align: center;
  }
}
.footer__newsletter .footer__news i {
  color: #ff8d00;
  margin-right: 15px;
  font-size: 2.2em;
  vertical-align: middle;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer__newsletter .footer__news i {
    font-size: 1.6em;
    margin-right: 8px;
  }
}
.footer__newsletter .footer__news span {
  font-size: 0.9em;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .footer__newsletter .footer__news span {
    font-size: 0.75em;
  }
}
@media (max-width: 991px) {
  .footer__newsletter .footer__news span {
    font-size: 1.15em;
  }
}
@media (max-width: 767px) {
  .footer__newsletter .footer__news span {
    display: block;
    margin-top: 10px;
    font-size: 1.2em;
  }
}
.footer__newsletter .grecaptcha-badge {
  /*display: none;*/
}
.footer__newsletter input[type=text] {
  height: 40px;
  line-height: 40px;
  max-width: 380px;
  color: #ff8d00;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 991px) {
  .footer__newsletter input[type=text] {
    max-width: 480px;
  }
}
.footer__newsletter .btn {
  height: 40px;
  line-height: 40px;
  max-width: 200px;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767px) {
  .footer__newsletter .btn {
    margin: auto;
    max-width: none;
  }
}
.footer__newsletter .loading-help .loading-special {
  background-color: #ff8d00;
  margin: 15px auto;
}

.account {
  margin-bottom: 40px;
}
.account .account__content {
  background-color: #eaeaea;
  border-radius: 3px;
  padding: 25px;
  min-height: 580px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.account .account__content.nopadd {
  padding: 0px;
}
.account .account__content .editor-label {
  margin: 10px 0;
  color: #666666;
}
.account .account__content .regicon {
  position: relative;
  padding: 10px 0 0 70px;
  text-align: left;
  min-height: 60px;
  font-size: 0.85em;
}
.account .account__content .regicon i {
  position: absolute;
  background-color: #7eccf0;
  color: #fff;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  left: 0;
  border-radius: 100%;
  top: 0;
  font-size: 1.4em;
}
.account h2 {
  margin: 15px 0;
}
.account h2:before, .account h2:after {
  border-top: none;
}

.account.empty--basket {
  margin-bottom: 0px;
}
.account.empty--basket h2 {
  text-align: center;
}
.account.empty--basket .account__content {
  min-height: initial;
}
.account.empty--basket .section {
  max-width: 450px;
  margin: auto;
}

.account__address {
  border-radius: 2px;
  background-color: #eaeaea;
  border: 1px solid #ccc;
}
@media (min-width: 768px) {
  .account__address {
    padding: 30px;
    margin: 30px 0;
  }
}

.account__console {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
  text-align: center;
}
.account__console i {
  display: block;
  font-size: 2em;
  margin-bottom: 10px;
}
.account__console strong {
  text-transform: uppercase;
  color: #666666;
}

@media (max-width: 990px) and (min-width: 768px) {
  .account-menu .section .dw-lg-4:nth-child(3n+1) {
    clear: both;
  }
}
@media (max-width: 767px) {
  .account-menu .section .dw-lg-4:nth-child(2n+1) {
    clear: both;
  }
}

.register--account .express-checkout-block {
  background-color: #eaeaea;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 25px;
  display: grid;
  align-items: center;
  grid-template: "icon text button"/0.5fr 3fr 3fr;
}
@media (max-width: 767px) {
  .register--account .express-checkout-block {
    display: block;
  }
  .register--account .express-checkout-block div h2 {
    text-align: center !important;
    margin: 15px 0 !important;
  }
  .register--account .express-checkout-block div span {
    text-align: center !important;
  }
}
.register--account .express-checkout-block i {
  grid-area: icon;
}
.register--account .express-checkout-block div {
  grid-area: text;
}
.register--account .express-checkout-block div h2 {
  margin: 0 0 0 15px;
  text-align: left;
}
.register--account .express-checkout-block div span {
  text-align: start;
  margin: 0 0 0 15px;
}
.register--account .express-checkout-block input {
  grid-area: button;
}
.register--account i.fa-pen-alt, .register--account i.fa-user-circle, .register--account i.fa-shopping-cart {
  font-size: 3em;
  color: #2e6ad0;
  display: block;
  margin-bottom: 15px;
  text-align: center;
}
.register--account i.fa-shopping-cart {
  margin-top: 18px;
}
.register--account h1 {
  text-transform: capitalize;
}
.register--account .findAddress, .register--account .findAddressSingle {
  margin: 0 !important;
}
.register--account ul {
  padding: 0 30px;
  list-style-position: inside;
}
.register--account ul li {
  color: #2e6ad0;
}
.register--account ul li span {
  color: #666666;
}
.register--account .reg--acc {
  text-align: center;
}
.register--account .btn, .register--account .loading-help .loading-special {
  height: 55px;
  line-height: 55px;
  margin: 15px 0px;
  border-radius: 3px;
}

.o-em {
  background-color: #fff;
  margin: auto;
  max-width: 800px;
  padding: 15px;
  color: #666666;
}

.account--why {
  margin: 40px auto;
  text-align: center;
}
@media (max-width: 767px) {
  .account--why {
    margin: 20px auto 0 auto;
  }
}
.account--why .section {
  margin: 0 -10px;
}
.account--why .section .dw-lg-4 {
  padding: 0 10px;
}
@media screen and (max-width: 500px) {
  .account--why .section .dw-lg-4 {
    width: 100%;
  }
}

.forgotten {
  text-decoration: underline;
}
.forgotten:hover {
  text-decoration: none;
}

.account--texts {
  color: #666666;
  font-family: "Poppins", sans-serif;
  font-size: 0.85em;
  text-align: center;
  display: block;
}

.account__background {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.account__background .btn {
  max-width: 240px;
}

.account__background.default {
  border: 2px solid #2e6ad0;
  padding: 13px;
}

@media (min-width: 768px) {
  .btn--back {
    width: 25%;
    float: left;
  }
}

.btn--right {
  float: right;
}
@media (min-width: 991px) {
  .btn--right {
    width: 25%;
  }
}

.account__order-history {
  margin-top: 30px;
}

.account__orders {
  margin-top: 30px;
  line-height: 20px;
}

.account__orders--row.orders .row:first-child {
  font-weight: 700;
  border-top: 0px;
}

.account__orders--row {
  border-top: 1px solid #d7d7d7;
  font-weight: 700;
}

.account__orders--main {
  color: #2e6ad0;
  font-size: 1.3em;
  text-align: center;
}

.account__orders--row > div {
  float: left;
  padding: 5px 10px;
}

@media (min-width: 992px) {
  .account__orders--details {
    width: 32%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .account__orders--details {
    width: 40%;
  }
}
.account__orders--details strong {
  color: #3c0c2a;
}
.account__orders--details span {
  color: #2e6ad0;
}

.account__orders--products {
  position: relative;
  padding-right: 50px;
}
@media (min-width: 992px) {
  .account__orders--products {
    width: 41%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .account__orders--products {
    width: 26%;
  }
}
.account__orders--products .dw-xs-9 {
  margin-bottom: 10px;
}
.account__orders--products .dw-xs-3 i {
  text-align: center;
  color: #3c0c2a;
  display: block;
}
.account__orders--products strong {
  color: #3c0c2a;
}
.account__orders--products a:hover strong {
  color: #2e6ad0;
}

@media (min-width: 992px) {
  .account__orders--cost {
    width: 27%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .account__orders--cost {
    width: 34%;
  }
}
.account__orders--cost strong {
  color: #3c0c2a;
}
.account__orders--cost span {
  color: #2e6ad0;
}

.eye--icon {
  position: relative;
}
.eye--icon .forgot {
  color: #2e6ad0;
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eye--icon .forgot:hover {
  color: #1d4384;
}

#loginform {
  width: 100%;
  margin: 0 auto;
  padding: 0 0px 15px;
  text-align: center;
}

#loginform .editor-label {
  text-align: left;
}

#loginform .editor-label label {
  color: #1d4384;
  font-size: 1.15em;
}

#loginform .editor-field {
  margin-bottom: 10px;
  position: relative;
}

#loginform .editor-field input[type=text], #loginform .editor-field input[type=password] {
  border: 1px solid #1d4384;
}

#loginform .editor-field.submit {
  margin-top: 20px;
}

#loginform a.sign-amb {
  display: inline-block;
  margin: 10px auto 0;
  text-align: center;
  font-size: 0.85em;
  font-style: italic;
}

#loginform a.sign-amb:hover {
  color: #59341a;
}

.account.other h2 {
  font-size: 1.3em;
  text-align: left;
}

.non-login .form-surround {
  max-width: 445px;
  margin: 0 auto;
}

.non-login .form-surround .btn {
  margin-top: 15px;
  margin-bottom: 25px;
}

.account .btn.back:hover {
  background-color: #fff;
  color: #777;
}

.account .login .specialhead .center-head {
  background-color: transparent;
}

.admin-link {
  display: flex;
  min-height: 130px;
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  border: 1px solid #d7d7d7;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  text-align: center;
  margin-bottom: 30px;
}
.admin-link i {
  font-size: 2em;
}
.admin-link strong {
  display: block;
  width: 100%;
  margin-top: 5px;
  text-decoration: none;
  text-align: center;
}

.showroom-li {
  padding: 15px;
  background-color: #f9f9f9;
  display: flex;
  margin-bottom: 15px;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1215686275);
}
.showroom-li .name {
  font-size: 1.3em;
  font-weight: 600;
  color: #1d4384;
}
.showroom-li .delete {
  color: #db2727;
  cursor: pointer;
}

.rel .forgot.eye {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 55px;
  display: flex;
  align-items: center;
  color: #1d4384;
  width: 55px;
  justify-content: center;
}

.rel .forgot.eye:hover {
  color: #fd8c00;
}

.product__steps {
  margin-top: 40px;
}

.step--one {
  max-width: 575px;
  margin: 0 auto 45px;
}

@media (max-width: 991px) {
  .step--two .dw-sm-6:nth-child(2n+1) {
    clear: both;
  }
}

.steps__title {
  color: #1d4384;
  font-weight: 500;
  text-align: center;
}

.step--head {
  font-size: 2em;
}

.step--sub {
  font-size: 1.5em;
  margin-bottom: 30px;
}
@media (max-width: 1230px) {
  .step--sub {
    font-size: 1.2em;
  }
}

.step--info {
  max-width: 580px;
  text-align: center;
  margin: auto;
}

.step--summary {
  margin: 50px 0;
  border-top: 1px solid #ccc;
  padding: 35px 0;
  border-bottom: 1px solid #ccc;
}

.step_combo {
  border: 1px solid #f1f1f1;
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
}
.step_combo:hover {
  border: 1px solid #7eccf0;
}
.step_combo.selected {
  border: 1px solid #2e6ad0;
}
.step_combo.selected .step--button .dummy-check i {
  opacity: 1 !important;
}
.step_combo .step--button input {
  display: none;
}
.step_combo .step--button .dummy-check {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border: 1px solid #ccc;
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 5px;
}
.step_combo .step--button .dummy-check i {
  color: #2e6ad0;
  opacity: 0;
}

@media (max-width: 1230px) {
  .option--list .option--boot:nth-child(3n+1) {
    clear: both;
  }
}
@media (min-width: 1231px) {
  .option--list .option--boot:nth-child(4n+1) {
    clear: both;
  }
}
.build--tabs .option--list.section {
  margin: 0px -15px;
}

.option--list.section {
  margin: 0px -5px;
}
.option--list.section .option--boot {
  padding: 0px 5px;
}
@media screen and (max-width: 420px) {
  .option--list.section .option--boot {
    width: 50%;
  }
  .option--list.section .option--boot:nth-child(3n+1) {
    clear: none;
  }
  .option--list.section .option--boot:nth-child(2n+1) {
    clear: both;
  }
}
.option--list.section .dw-md-4:nth-child(3) .step__payment img {
  max-width: 155px;
  margin: auto;
}

.build__pad {
  margin-top: 20px;
}
.build__pad h3 {
  font-weight: 500;
  color: #1d4384;
  text-align: left;
  margin: 0 auto 15px;
}
.build__pad h3:before, .build__pad h3:after {
  border-top: none;
}

.step--image {
  display: block;
  position: relative;
}
@media (max-width: 767px) {
  .step--image {
    max-width: 230px;
    margin: 0 auto;
  }
}
.step--image i {
  position: absolute;
  bottom: 12px;
  color: #72c5ec;
  left: 5px;
  display: block;
}
.step--image:hover i {
  color: #ff8d00;
}
.step--image img {
  margin: auto;
  display: inline-block;
}
.step--image a {
  text-decoration: none;
}

.step--detail {
  margin: auto;
  max-width: 165px;
  display: block;
  font-size: 0.9em;
  position: relative;
  min-height: 55px;
}
.step--detail span {
  display: block;
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step--button input {
  width: 25px;
  height: 25px;
  margin-top: 5px;
}

.step__note {
  border: 2px solid #ff8d00;
  background-color: #fddfb9;
  color: #ff8d00;
  padding: 12px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 15px;
}
.step__note i {
  margin-right: 15px;
  vertical-align: middle;
  font-size: 1.5em;
}

.step__different {
  background-color: #eaeaea;
  padding: 20px;
}
.step__different span {
  font-weight: 500;
  font-size: 0.9em;
}
.step__different .btn {
  margin: 5px auto;
}
.step__different .btn i {
  margin-right: 15px;
  vertical-align: middle;
}

.coming--soon {
  display: block;
  position: absolute;
  left: -15px;
  top: -15px;
  background-color: #ff8d00;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.coming--soon span {
  margin: 21px 0;
  display: block;
  font-size: 0.95em;
  line-height: 1.3;
}

.step__payment {
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 30px 15px 30px;
  min-height: 430px;
  position: relative;
  height: 100%;
  max-width: 370px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .step__payment {
    padding: 0.75rem 0.5rem;
    min-height: initial;
  }
}
.step__payment.shrt {
  min-height: 500px;
  margin-top: 0px;
  margin-bottom: 15px;
  clear: both;
}
@media (max-width: 1230px) and (min-width: 992px) {
  .step__payment.shrt {
    min-height: 500px;
  }
}
@media (max-width: 991px) {
  .step__payment.shrt {
    min-height: 0;
  }
}
.step__payment.finance img {
  margin: 30px auto 0 auto !important;
}
@media (max-width: 990px) {
  .step__payment.finance {
    margin: auto !important;
  }
}
.step__payment.selected--payment {
  border: 1px solid #ff8d00;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .step__payment {
    min-height: 475px;
  }
}
.step__payment .step__top {
  min-height: 130px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .step__payment .step__top {
    min-height: 120px;
  }
}
@media (max-width: 766px) {
  .step__payment .step__top {
    min-height: 166px;
  }
}
@media screen and (max-width: 500px) {
  .step__payment .step__top {
    min-height: 10px !important;
  }
  .step__payment .step__top .steps__title {
    min-height: 10px !important;
  }
}
@media screen and (max-width: 500px) {
  .step__payment {
    min-height: 10px !important;
  }
  .step__payment .step__text {
    min-height: 10px !important;
  }
}
.step__payment .step--sub {
  margin-bottom: 15px;
  font-size: 1rem;
}
.step__payment .step__price {
  color: #ff8d00;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.1em;
}
.step__payment img {
  margin: 0 auto 25px auto;
}
@media (min-width: 992px) {
  .step__payment img {
    width: auto;
  }
}
.step__payment .step__text {
  margin: 15px auto 0;
  font-size: 0.7rem;
  padding: 0 15px;
}
@media (max-width: 1230px) and (min-width: 992px) {
  .step__payment .step__text {
    padding: 0px;
  }
}
@media (max-width: 991px) {
  .step__payment .step__text {
    min-height: 65px;
  }
}
.step__payment .step__text span {
  display: block;
  font-size: 0.9em;
}
.step__payment .btn {
  margin-bottom: 0;
}
.step__payment strong {
  display: block;
  margin-top: 20px;
}

.step__payment.selected {
  border: 1px solid #2e6ad0;
}

.step__package {
  border: 1px solid #ccc;
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
}

.no--packs {
  background-color: #999999;
}

.step__next {
  max-width: 575px;
  display: block;
  margin: 15px auto;
}

.step--three {
  margin-bottom: 50px;
}

.package {
  padding: 30px 5px;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  display: block;
  color: #fff;
}

.package__price {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  font-weight: 700;
  display: block;
}
.package__price .price {
  color: #ff8d00;
  font-size: 2.5em;
}
.package__price .percentage {
  color: #72c5ec;
}

.package__involved {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  display: block;
  height: auto !important;
}
@media (min-width: 1230px) {
  .package__involved {
    min-height: 395px;
  }
}
@media (min-width: 991px) and (max-width: 1229px) {
  .package__involved {
    min-height: 395px;
  }
}
@media (max-width: 990px) and (min-width: 767px) {
  .package__involved {
    min-height: 395px;
  }
}
@media (max-width: 766px) {
  .package__involved {
    min-height: 450px;
  }
}
@media screen and (max-width: 500px) {
  .package__involved {
    min-height: 10px;
  }
}
.package__involved .section {
  margin: 0;
}
.package__involved i {
  vertical-align: middle;
  font-size: 1.5em;
  color: #72c5ed;
  position: absolute;
}

.package__around {
  display: block;
  margin: 5px 0;
}

.pack__list {
  display: block;
  position: relative;
}

.pack__text {
  font-weight: 500;
  display: inline-block;
  padding-left: 35px;
}

.package--standard .package {
  color: #666666;
}

.package__print {
  padding: 20px;
  font-size: 0.8em;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  display: block;
}
@media (min-width: 991px) {
  .package__print {
    min-height: 150px;
  }
}
@media (max-width: 990px) and (min-width: 767px) {
  .package__print {
    min-height: 112px;
  }
}
@media (max-width: 766px) {
  .package__print {
    min-height: 150px;
  }
}
@media screen and (max-width: 500px) {
  .package__print {
    min-height: 10px;
  }
}

.package__select {
  padding: 15px;
  text-align: center;
  margin: auto;
  display: block;
}
.package__select input {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}
.package__select span {
  font-weight: 500;
  font-size: 1em;
}

.other__packages {
  margin-top: 35px;
}
.other__packages .package__image {
  display: block;
}
.other__packages .package__image img {
  margin: auto;
}
.other__packages .package__name {
  color: #1d4384;
  font-size: 1.5em;
  font-weight: 500;
  margin: 10px 0;
  display: block;
  padding: 0 20px;
}
@media (min-width: 991px) {
  .other__packages .package__name {
    min-height: 70px;
    font-size: 1.3em;
  }
}
@media (max-width: 990px) {
  .other__packages .package__name {
    min-height: 70px;
    font-size: 1.3em;
  }
}
@media screen and (max-width: 500px) {
  .other__packages .package__name {
    min-height: 10px !important;
  }
}
.other__packages .package__price {
  color: #ff8d00;
  text-align: left;
  padding-left: 20px;
  border-bottom: none;
}

.quick--option {
  display: none;
}

.financeinfo .row {
  margin: 0px auto;
  padding: 5px 10px;
  max-width: 340px;
  position: relative;
}
.financeinfo .row:nth-child(odd) {
  background-color: #eee;
}
.financeinfo .row .title {
  width: calc(100% - 90px);
}
.financeinfo .row .price {
  position: absolute;
  right: 15px;
  padding: 0px;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  width: 90px;
  font-weight: 700;
}
.financeinfo.wide .row {
  max-width: 100%;
}

.basket {
  position: relative;
  padding: 0 15px;
}
.basket input[type=text], .basket .basket--label {
  border: 1px solid #dfdfdf;
  max-width: 40px;
  margin: 0 auto 0;
  display: block;
  color: #666666;
  font-size: 1.2em;
  border-radius: 2px;
  text-align: center;
  padding: 0;
  margin-top: 25px;
  height: 40px;
}
@media (max-width: 990px) and (min-width: 768px) {
  .basket input[type=text], .basket .basket--label {
    margin-top: auto;
  }
}
.basket input[type=submit] {
  border: none;
  max-width: 80px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: #2e6ad0;
  font-weight: 700;
  font-size: 0.85em;
  text-decoration: underline;
  background: transparent;
}
.basket input[type=submit]:hover {
  text-decoration: none;
  color: #ff8d00;
}
.basket .offers__three {
  margin: 0 15px;
}

@media (max-width: 767px) {
  .basket--label {
    margin: 0 !important;
    display: inline-block !important;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.basket__buttons {
  margin-top: 20px;
}
.basket__buttons .checkout-opt .readywait {
  display: none;
}
.basket__buttons .checkout-opt .KO {
  display: block;
}
.basket__buttons .checkout-opt.ready .readywait {
  display: flex;
}
.basket__buttons .checkout-opt.ready .KO {
  display: none;
}
.basket__buttons .btn--primary, .basket__buttons .btn--grey {
  width: 100%;
}
@media (max-width: 767px) {
  .basket__buttons .btn--primary, .basket__buttons .btn--grey {
    margin: 10px auto;
    max-width: 285px;
  }
}
.basket__buttons .btn--orange {
  max-width: 285px;
  float: right;
}
@media (max-width: 767px) {
  .basket__buttons .btn--orange {
    float: none;
    margin: 10px auto;
  }
}

.basket__form {
  padding: 35px 0 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 767px) {
  .basket__form {
    border-bottom: unset;
  }
}

.co .basket__form {
  border-bottom: 0;
}

.basket__heading {
  font-weight: 500;
  margin-bottom: 50px;
  color: #1d4384;
  font-size: 1.6em;
  text-align: center;
}
@media (max-width: 767px) {
  .basket__heading {
    margin-bottom: 20px;
  }
}

.basket__row {
  margin-bottom: 15px;
  text-align: center;
}
@media (max-width: 767px) {
  .basket__row {
    border-bottom: none;
    padding: 0 15px;
  }
}

.basket__row--main {
  color: #1d4384;
  border-bottom: 1px solid #1d4384;
  padding: 15px;
  font-size: 1.1em;
  font-weight: 500;
}
@media (max-width: 767px) {
  .basket__row--main {
    display: none;
  }
}

.basket__column {
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .basket__column {
    float: left;
  }
}

.basket__column--one {
  text-align: left;
  width: 60%;
}
.basket__column--one img {
  border: 1px solid #ccc;
  border-radius: 2px;
}
@media (min-width: 992px) {
  .basket__column--one {
    position: relative;
  }
}
@media (max-width: 767px) {
  .basket__column--one {
    padding: 0;
    width: 100%;
    min-height: 100px;
    float: none;
    margin-bottom: 15px;
  }
}

.basket__column--two {
  width: 30%;
}
@media (max-width: 767px) {
  .basket__column--two {
    width: 100%;
    float: left;
    text-align: left;
  }
  .basket__column--two input {
    margin: 0 0 0 5px;
    height: 40px;
    line-height: 40px;
    float: left;
    margin-top: 0 !important;
    padding: 0 15px;
  }
  .basket__column--two .basket__column--xs {
    float: left;
    line-height: 40px;
  }
}

.basket__column--three {
  width: 10%;
}
@media (max-width: 767px) {
  .basket__column--three {
    display: none;
  }
}

.basket--bold {
  font-size: 1.2em;
  padding: 15px;
  display: block;
  font-weight: 700;
  margin: auto;
  text-align: center;
  color: #ff8d00;
}
@media (max-width: 990px) and (min-width: 768px) {
  .basket--bold {
    margin-top: auto;
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .basket--bold {
    margin-top: auto;
    font-size: 1em;
  }
}

.basket--title {
  color: #1d4384;
  font-weight: 500;
  display: block;
}
.basket--title a {
  color: #1d4384;
  font-weight: 500;
  text-decoration: none;
}
.basket--title a:hover {
  text-decoration: underline;
}
@media (min-width: 992px) {
  .basket--title {
    margin: 20px auto;
  }
}

.basket__quantity {
  display: block;
}
@media (max-width: 767px) {
  .basket__quantity {
    display: inline-block;
  }
}

.basket--remove {
  margin: 20px 0;
  display: block;
}
@media (max-width: 767px) {
  .basket--remove {
    display: inline-block;
    float: right;
  }
}
.basket--remove .basket--remove-text {
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.85em;
  color: #ff4c0d;
  font-weight: 500;
  text-decoration: none;
  margin-left: 10px;
}
@media (max-width: 990px) and (min-width: 768px) {
  .basket--remove .basket--remove-text {
    margin-top: auto;
  }
}
@media (max-width: 767px) {
  .basket--remove .basket--remove-text {
    margin-top: auto;
  }
}
.basket--remove .basket--remove-text:hover {
  text-decoration: underline;
}
.basket--remove .fas {
  color: #ff4c0d;
  vertical-align: middle;
  text-decoration: none;
}

.basket__total {
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
@media (max-width: 767px) {
  .basket__total {
    padding: 0;
    border-top: 3px solid #2e6ad0;
    margin-top: 15px;
  }
}

.basket--sum {
  padding: 15px 0;
  font-size: 1em;
  font-weight: 500;
  color: #1d4384;
}
@media (max-width: 767px) {
  .basket--sum {
    text-align: center;
    padding: 15px 0;
  }
}

.basket__admin input {
  max-width: 75px !important;
}

.basket__row--regular {
  min-height: 95px;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 767px) {
  .basket__row--regular {
    min-height: 0;
  }
}

@media (max-width: 767px) {
  .basket__column--xs {
    display: inline-block !important;
  }
}

.basket--size {
  display: block;
}

.basket__discount {
  background-color: #fff;
  border: 1px solid #2e6ad0;
  cursor: pointer;
  min-height: 50px;
  line-height: 50px;
  padding: 0 15px;
  color: #2e6ad0;
  font-weight: 700;
}
.basket__discount .fa {
  float: right;
  color: #ff8d00;
  font-size: 1.4em;
  margin: 14px;
}

.basket__discount--title {
  float: left;
  width: auto;
}

.basket-discount--slide {
  padding: 0;
}
.basket-discount--slide input[type=text] {
  border: 1px solid #d4d4d4;
  max-width: 100%;
  margin: 15px 15px auto auto;
}

.delivery__form input {
  max-width: 100% !important;
  font-size: 0.9em !important;
  margin: 0 !important;
}
.delivery__form .basket--bold, .delivery__form .basket--sum {
  padding: 0;
  line-height: 40px;
}

.user--discount {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.user--discount input {
  max-width: 100% !important;
  font-size: 1em !important;
}

.basket__discount--info {
  border: 1px solid #2e6ad0;
  background-color: #fff;
  border-top: none;
}
.basket__discount--info .discount__text {
  display: block;
}

.basket__edit {
  padding: 15px;
}

.admin--apply {
  margin: 40px auto 0;
}

.admin--discount label {
  color: #666666;
  font-weight: 900;
}
.admin--discount input {
  margin-top: 15px !important;
}
.admin--discount .btn {
  margin-top: 15px;
}
.admin--discount .radio--admin {
  font-weight: normal;
  margin-top: 15px;
  display: block;
}
.admin--discount .radio--admin span {
  display: inline-block;
  margin-left: 15px;
  font-size: 0.85em;
}
.admin--discount .radio--admin span i {
  margin-right: 5px;
  color: #2e6ad0;
}

#collect h2 {
  padding-left: 0;
  font-size: 1.4em;
}
#collect .fa-map-marker-alt {
  color: #2e6ad0;
  font-size: 1.6em;
  margin: 30px 0 10px;
}
#collect .address {
  color: #666666;
}

.collection .fa-calendar-alt {
  color: #2e6ad0;
  font-size: 1.8em;
  margin-right: 0;
  text-align: center;
}
.collection strong {
  color: #2e6ad0;
  text-transform: uppercase;
}
.collection span {
  font-weight: 600;
  color: #666666;
}
.collection input {
  visibility: hidden;
  position: absolute;
}

.collection__inner.selected {
  border: 1px solid #2e6ad0;
}

.collection__inner {
  background-color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 17px 2px;
}
.collection__inner:hover {
  border: 1px solid #2e6ad0;
}
.collection__inner input[type=radio]:checked {
  border: 1px solid #2e6ad0;
}

.collection-slide .fa-chevron-left {
  left: -25px !important;
}
.collection-slide .fa-chevron-right {
  right: -25px !important;
}
.collection-slide .bx-viewport {
  min-height: 115px;
}

.combo--pack {
  margin: 0 !important;
}
.combo--pack .combo__img {
  width: 50px !important;
  height: 50px;
  margin-bottom: 5px;
  margin-right: 8px;
  float: left;
}
.combo--pack span {
  padding-top: 0 !important;
  color: #1d4384;
  float: left;
  width: 70%;
}

.basket__combination {
  margin: 15px;
}
@media (max-width: 767px) {
  .basket__combination {
    margin: 15px 0;
  }
}
.basket__combination h3 {
  color: #1d4384;
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 15px;
}
.basket__combination h3:before, .basket__combination h3:after {
  border-top: none;
}
.basket__combination .combo__img {
  width: 14%;
  display: inline-block;
  vertical-align: middle;
  float: left;
}
@media (max-width: 767px) {
  .basket__combination .combo__img {
    width: 23%;
  }
}
.basket__combination .combo__img img {
  display: inline-block;
}
.basket__combination span {
  font-weight: 500;
  font-size: 1em;
}
.basket__combination .step__package {
  cursor: inherit;
  border: none;
}
.basket__combination .step__package .package {
  font-size: 0.9em;
  padding: 10px;
  max-width: 180px;
  float: left;
  margin-right: 15px;
}
.basket__combination .step__package span {
  padding-top: 13px;
  float: left;
}

.product__steps {
  margin-top: 40px;
}

.step--one {
  max-width: 575px;
  margin: 0 auto 45px;
}

@media (max-width: 991px) {
  .step--two .dw-sm-6:nth-child(2n+1) {
    clear: both;
  }
}

.steps__title {
  color: #1d4384;
  font-weight: 500;
  text-align: center;
}

.step--head {
  font-size: 2em;
}

.step--sub {
  font-size: 1.5em;
  margin-bottom: 30px;
}
@media (max-width: 1230px) {
  .step--sub {
    font-size: 1.2em;
  }
}

.step--info {
  max-width: 580px;
  text-align: center;
  margin: auto;
}

.step--summary {
  margin: 50px 0;
  border-top: 1px solid #ccc;
  padding: 35px 0;
  border-bottom: 1px solid #ccc;
}

.step_combo {
  border: 1px solid #f1f1f1;
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
}
.step_combo:hover {
  border: 1px solid #7eccf0;
}
.step_combo.selected {
  border: 1px solid #2e6ad0;
}
.step_combo.selected .step--button .dummy-check i {
  opacity: 1 !important;
}
.step_combo .step--button input {
  display: none;
}
.step_combo .step--button .dummy-check {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border: 1px solid #ccc;
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 5px;
}
.step_combo .step--button .dummy-check i {
  color: #2e6ad0;
  opacity: 0;
}

@media (max-width: 1230px) {
  .option--list .option--boot:nth-child(3n+1) {
    clear: both;
  }
}
@media (min-width: 1231px) {
  .option--list .option--boot:nth-child(4n+1) {
    clear: both;
  }
}
.build--tabs .option--list.section {
  margin: 0px -15px;
}

.option--list.section {
  margin: 0px -5px;
}
.option--list.section .option--boot {
  padding: 0px 5px;
}
@media screen and (max-width: 420px) {
  .option--list.section .option--boot {
    width: 50%;
  }
  .option--list.section .option--boot:nth-child(3n+1) {
    clear: none;
  }
  .option--list.section .option--boot:nth-child(2n+1) {
    clear: both;
  }
}
.option--list.section .dw-md-4:nth-child(3) .step__payment img {
  max-width: 155px;
  margin: auto;
}

.build__pad {
  margin-top: 20px;
}
.build__pad h3 {
  font-weight: 500;
  color: #1d4384;
  text-align: left;
  margin: 0 auto 15px;
}
.build__pad h3:before, .build__pad h3:after {
  border-top: none;
}

.step--image {
  display: block;
  position: relative;
}
@media (max-width: 767px) {
  .step--image {
    max-width: 230px;
    margin: 0 auto;
  }
}
.step--image i {
  position: absolute;
  bottom: 12px;
  color: #72c5ec;
  left: 5px;
  display: block;
}
.step--image:hover i {
  color: #ff8d00;
}
.step--image img {
  margin: auto;
  display: inline-block;
}
.step--image a {
  text-decoration: none;
}

.step--detail {
  margin: auto;
  max-width: 165px;
  display: block;
  font-size: 0.9em;
  position: relative;
  min-height: 55px;
}
.step--detail span {
  display: block;
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step--button input {
  width: 25px;
  height: 25px;
  margin-top: 5px;
}

.step__note {
  border: 2px solid #ff8d00;
  background-color: #fddfb9;
  color: #ff8d00;
  padding: 12px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 15px;
}
.step__note i {
  margin-right: 15px;
  vertical-align: middle;
  font-size: 1.5em;
}

.step__different {
  background-color: #eaeaea;
  padding: 20px;
}
.step__different span {
  font-weight: 500;
  font-size: 0.9em;
}
.step__different .btn {
  margin: 5px auto;
}
.step__different .btn i {
  margin-right: 15px;
  vertical-align: middle;
}

.coming--soon {
  display: block;
  position: absolute;
  left: -15px;
  top: -15px;
  background-color: #ff8d00;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.coming--soon span {
  margin: 21px 0;
  display: block;
  font-size: 0.95em;
  line-height: 1.3;
}

.step__payment {
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 30px 15px 30px;
  min-height: 430px;
  position: relative;
  height: 100%;
  max-width: 370px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .step__payment {
    padding: 0.75rem 0.5rem;
    min-height: initial;
  }
}
.step__payment.shrt {
  min-height: 500px;
  margin-top: 0px;
  margin-bottom: 15px;
  clear: both;
}
@media (max-width: 1230px) and (min-width: 992px) {
  .step__payment.shrt {
    min-height: 500px;
  }
}
@media (max-width: 991px) {
  .step__payment.shrt {
    min-height: 0;
  }
}
.step__payment.finance img {
  margin: 30px auto 0 auto !important;
}
@media (max-width: 990px) {
  .step__payment.finance {
    margin: auto !important;
  }
}
.step__payment.selected--payment {
  border: 1px solid #ff8d00;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .step__payment {
    min-height: 475px;
  }
}
.step__payment .step__top {
  min-height: 130px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .step__payment .step__top {
    min-height: 120px;
  }
}
@media (max-width: 766px) {
  .step__payment .step__top {
    min-height: 166px;
  }
}
@media screen and (max-width: 500px) {
  .step__payment .step__top {
    min-height: 10px !important;
  }
  .step__payment .step__top .steps__title {
    min-height: 10px !important;
  }
}
@media screen and (max-width: 500px) {
  .step__payment {
    min-height: 10px !important;
  }
  .step__payment .step__text {
    min-height: 10px !important;
  }
}
.step__payment .step--sub {
  margin-bottom: 15px;
  font-size: 1rem;
}
.step__payment .step__price {
  color: #ff8d00;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.1em;
}
.step__payment img {
  margin: 0 auto 25px auto;
}
@media (min-width: 992px) {
  .step__payment img {
    width: auto;
  }
}
.step__payment .step__text {
  margin: 15px auto 0;
  font-size: 0.7rem;
  padding: 0 15px;
}
@media (max-width: 1230px) and (min-width: 992px) {
  .step__payment .step__text {
    padding: 0px;
  }
}
@media (max-width: 991px) {
  .step__payment .step__text {
    min-height: 65px;
  }
}
.step__payment .step__text span {
  display: block;
  font-size: 0.9em;
}
.step__payment .btn {
  margin-bottom: 0;
}
.step__payment strong {
  display: block;
  margin-top: 20px;
}

.step__payment.selected {
  border: 1px solid #2e6ad0;
}

.step__package {
  border: 1px solid #ccc;
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
}

.no--packs {
  background-color: #999999;
}

.step__next {
  max-width: 575px;
  display: block;
  margin: 15px auto;
}

.step--three {
  margin-bottom: 50px;
}

.package {
  padding: 30px 5px;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  display: block;
  color: #fff;
}

.package__price {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  font-weight: 700;
  display: block;
}
.package__price .price {
  color: #ff8d00;
  font-size: 2.5em;
}
.package__price .percentage {
  color: #72c5ec;
}

.package__involved {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  display: block;
  height: auto !important;
}
@media (min-width: 1230px) {
  .package__involved {
    min-height: 395px;
  }
}
@media (min-width: 991px) and (max-width: 1229px) {
  .package__involved {
    min-height: 395px;
  }
}
@media (max-width: 990px) and (min-width: 767px) {
  .package__involved {
    min-height: 395px;
  }
}
@media (max-width: 766px) {
  .package__involved {
    min-height: 450px;
  }
}
@media screen and (max-width: 500px) {
  .package__involved {
    min-height: 10px;
  }
}
.package__involved .section {
  margin: 0;
}
.package__involved i {
  vertical-align: middle;
  font-size: 1.5em;
  color: #72c5ed;
  position: absolute;
}

.package__around {
  display: block;
  margin: 5px 0;
}

.pack__list {
  display: block;
  position: relative;
}

.pack__text {
  font-weight: 500;
  display: inline-block;
  padding-left: 35px;
}

.package--standard .package {
  color: #666666;
}

.package__print {
  padding: 20px;
  font-size: 0.8em;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  display: block;
}
@media (min-width: 991px) {
  .package__print {
    min-height: 150px;
  }
}
@media (max-width: 990px) and (min-width: 767px) {
  .package__print {
    min-height: 112px;
  }
}
@media (max-width: 766px) {
  .package__print {
    min-height: 150px;
  }
}
@media screen and (max-width: 500px) {
  .package__print {
    min-height: 10px;
  }
}

.package__select {
  padding: 15px;
  text-align: center;
  margin: auto;
  display: block;
}
.package__select input {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}
.package__select span {
  font-weight: 500;
  font-size: 1em;
}

.other__packages {
  margin-top: 35px;
}
.other__packages .package__image {
  display: block;
}
.other__packages .package__image img {
  margin: auto;
}
.other__packages .package__name {
  color: #1d4384;
  font-size: 1.5em;
  font-weight: 500;
  margin: 10px 0;
  display: block;
  padding: 0 20px;
}
@media (min-width: 991px) {
  .other__packages .package__name {
    min-height: 70px;
    font-size: 1.3em;
  }
}
@media (max-width: 990px) {
  .other__packages .package__name {
    min-height: 70px;
    font-size: 1.3em;
  }
}
@media screen and (max-width: 500px) {
  .other__packages .package__name {
    min-height: 10px !important;
  }
}
.other__packages .package__price {
  color: #ff8d00;
  text-align: left;
  padding-left: 20px;
  border-bottom: none;
}

.quick--option {
  display: none;
}

.financeinfo .row {
  margin: 0px auto;
  padding: 5px 10px;
  max-width: 340px;
  position: relative;
}
.financeinfo .row:nth-child(odd) {
  background-color: #eee;
}
.financeinfo .row .title {
  width: calc(100% - 90px);
}
.financeinfo .row .price {
  position: absolute;
  right: 15px;
  padding: 0px;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  width: 90px;
  font-weight: 700;
}
.financeinfo.wide .row {
  max-width: 100%;
}

.checkout {
  border: 1px solid #ccc;
  border-radius: 2px;
  max-width: 1300px;
  margin-bottom: 30px;
}
.checkout h2 {
  padding: 15px 25px;
  display: inline-block;
}
@media (max-width: 767px) {
  .checkout h2 {
    font-size: 1.4em;
  }
}
.checkout h2:before, .checkout h2:after {
  border-top: none;
}
.checkout i {
  color: #666666;
  margin-right: 15px;
}
.checkout .editor-field {
  margin: 10px 0;
}
.checkout .validation-summary-errors {
  display: block;
  padding: 0 30px;
  column-count: 2;
}
@media (min-width: 991px) {
  .checkout .step__payment {
    min-height: 460px;
  }
}
.checkout #discountform.checkout-discount {
  padding: 0 20px;
}
.checkout #discountform.checkout-discount .section {
  display: flex;
  align-items: baseline;
}
@media (max-width: 767px) {
  .checkout #checkoutform #contactform > br:not(:last-of-type) {
    display: none;
  }
  .checkout #checkoutform #contactform #deliveryAddress {
    margin-top: 5px;
  }
  .checkout #checkoutform #contactform .checkout__area .section.pay-types {
    margin-bottom: 10px;
  }
  .checkout #checkoutform #contactform .checkout__area .section.pay-types > br {
    display: none;
  }
}

.checkout--form {
  display: flex;
  justify-content: center;
}

.checkout__line {
  border-top: 1px solid #ccc;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .checkout__line {
    margin-top: unset;
  }
}

.checkout__area .KO.orange {
  background-color: red;
}
.checkout__area .KO.orange i {
  color: #fff;
  font-size: 1.4em;
}
.checkout__area input.check-box {
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
}
.checkout__area select.clearborder, .checkout__area select.valid {
  border: 1px solid #dfdfdf;
}
.checkout__area select {
  color: #666;
  /*border: 1px solid red;*/
}
.checkout__area .agree {
  color: #666666;
  margin-bottom: 15px;
}
.checkout__area .agree a {
  font-weight: 600;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .checkout__area .agree {
    font-size: 0.75em;
  }
}
.checkout__area #map {
  width: 100%;
  height: 210px;
  float: right;
  margin-top: 15px;
}
.checkout__area .btn {
  margin-top: 0;
}
.checkout__area .btn.selected {
  background-color: #2e6ad0;
}
@media (max-width: 767px) {
  .checkout__area > br {
    display: none;
  }
}

.btn.paypal {
  background-color: #e89f3b;
  border: 1px solid #e89f3b;
  margin-bottom: 10px;
}
.btn.paypal img {
  margin: auto;
}
.btn.paypal:hover {
  background-color: #e1e1e1;
  border: 1px solid #e1e1e1;
}

.pay-types .btn {
  margin: 0;
}
@media (max-width: 767px) {
  .pay-types .btn {
    margin-bottom: 10px;
  }
}

.feat__popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.feat__popup.open {
  opacity: 1;
  visibility: visible;
}
.feat__popup .overlaypop {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.feat__popup h2 {
  text-align: center;
  margin: 0 auto 20px auto;
  color: #1d4384;
  padding: 0;
  display: block;
}
.feat__popup .view {
  position: relative;
  width: 95%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  max-height: 90%;
  background-color: #f0f0f0;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.8);
}
@media (max-width: 767px) {
  .feat__popup .view {
    height: auto;
    width: 90%;
  }
}
.feat__popup .surround {
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  margin: auto;
}
.feat__popup .features-text {
  padding: 35px;
}
@media screen and (max-width: 500px) {
  .feat__popup .features-text {
    padding: 15px 10px;
  }
}
.feat__popup #popupcontent {
  padding-bottom: 60px;
  position: relative;
  height: 100%;
  overflow-y: auto;
}
.feat__popup .surround.open {
  opacity: 1;
  visibility: visible;
}
.feat__popup .close {
  font-size: 1em;
  display: block;
  position: absolute;
  top: -20px;
  right: -10px;
  cursor: pointer;
  z-index: 999;
}
.feat__popup .close .fa, .feat__popup .close .fas {
  font-size: 1.3em;
  text-align: center;
  background-color: #2e6ad0;
  color: #fff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  line-height: 35px;
  float: left;
}
.feat__popup .close .fa:hover, .feat__popup .close .fas:hover {
  background-color: #ff8d00;
}
.feat__popup .close .text {
  line-height: 30px;
  float: right;
  margin-left: 10px;
  float: left;
  display: none;
}
.feat__popup .close.back {
  display: none;
  right: auto;
  left: 0px;
}

.checkout__addresses {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
}
.checkout__addresses strong {
  display: block;
  margin-bottom: 15px;
  color: #2e6ad0;
}
.checkout__addresses label {
  color: #666666;
}

#adddelivery {
  color: #2e6ad0;
  font-weight: 700;
  font-size: 1.2em;
  padding: 0 35px;
  text-decoration: underline;
  cursor: pointer;
}

.checkout__same-as--delivery {
  margin-bottom: 20px;
  float: right;
  margin-top: 15px;
  font-size: 0.9em;
  color: #666666;
  line-height: 25px;
}
@media (max-width: 767px) {
  .checkout__same-as--delivery {
    margin-bottom: 0px;
    float: left;
  }
}
.checkout__same-as--delivery span {
  margin-top: 2px;
  color: #333;
  line-height: 40px;
  float: left;
  display: block;
}
@media (max-width: 767px) {
  .checkout__same-as--delivery span {
    padding: 0 10px;
    float: none;
    line-height: initial;
  }
}
.checkout__same-as--delivery input {
  width: 21px;
  height: 21px;
  border: 1px solid #d6d6d6;
  margin: 11px 10px;
  float: left;
}
@media (max-width: 767px) {
  .checkout__same-as--delivery div.select-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .checkout__same-as--delivery div.select-wrapper > input[type=checkbox] {
    margin-right: 20px;
  }
}

#billingAddress.shut {
  height: 0;
  overflow: hidden;
}

#deliveryaddress.shut, #newaddress.shut {
  height: 0;
  overflow: hidden;
}

#delivery {
  margin-top: 35px;
}
@media (max-width: 767px) {
  #delivery {
    margin-top: 10px;
  }
}

#delivery-options .delivery-opt {
  padding: 15px 115px 15px 44px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: relative;
  display: block;
  margin-top: 15px;
  position: relative;
}
#delivery-options .delivery-opt i {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: block;
  top: 12px;
  text-align: center;
  left: -35px;
  transform: translate(50%, -50%);
  background-color: #2e6ad0;
  color: #fff;
  border-radius: 100%;
  font-size: 0.8em;
}
@media (max-width: 767px) {
  #delivery-options .delivery-opt i {
    left: -50px;
  }
}
#delivery-options .delivery-opt i:hover {
  background-color: #ff8d00;
}
#delivery-options .delivery-opt.selected {
  border: 1px solid #2e6ad0;
}
#delivery-options .rb {
  position: absolute;
  left: 15px;
  top: 50%;
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 14px;
  height: 40px;
}
#delivery-options input {
  margin: 13px 0;
  width: 14px;
  height: 14px;
}
#delivery-options strong {
  color: #666666;
}
#delivery-options span {
  color: #666666;
}
#delivery-options span.price {
  color: #2e6ad0;
  font-weight: 700;
  position: absolute;
  right: 15px;
  top: 50%;
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  line-height: 40px;
  width: 85px;
  font-size: 1.2em;
}

.btn--paypal {
  background-color: #ffc439;
  height: 40px;
  line-height: 20px;
  border: 1px solid #ffc439;
  font-weight: 700;
  position: relative;
  margin-top: 14px;
}
.btn--paypal img {
  margin: 4px auto;
}
.btn--paypal:hover {
  opacity: 0.8;
}

.required {
  color: #2e6ad0;
}

@media print {
  .basket__heading, #contactform, .breadcrumb, .hero__top, footer, .basket__restriction, .btn, .col-left, .col-right {
    display: none !important;
  }
  .total--checkout-print {
    display: block !important;
  }
  .basket__column img {
    height: 80px;
    width: 80px;
  }
  .basket__column--one {
    width: 55%;
    float: left;
  }
  .basket__column--three.check {
    width: 20%;
    float: right;
  }
  .basket__column--two.check {
    width: 25%;
    float: right;
  }
  .basket__row {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .checkkout--nav .accounts {
    display: flex;
    justify-content: flex-end;
  }
}

﻿﻿﻿﻿.navigation--middle {
  margin-top: 42px;
  padding-left: 11px;
  padding-right: 0;
}
@media (min-width: 992px) {
  ﻿﻿﻿﻿.navigation--middle {
    width: calc(100% - 560px);
    position: relative;
  }
}
@media (max-width: 991px) {
  ﻿﻿﻿﻿.navigation--middle {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 15px;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.0509803922);
    border-bottom: 1px solid #ccc;
    -webkit-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.0509803922);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.0509803922);
  }
}

.nav--links {
  text-decoration: none;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .nav--links {
    font-size: 0.75em !important;
  }
}
@media (min-width: 992px) {
  .nav--links {
    color: #666666;
    display: block;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 50px;
  }
  .nav--links:hover i.arrow--item {
    color: #2e6ad0;
  }
}
@media (max-width: 991px) {
  .nav--links {
    border-bottom: 1px solid #ccc;
    width: 86%;
    float: left;
    padding: 15px 30px;
    font-weight: 700;
    color: #1d4384;
  }
}
@media (max-width: 767px) {
  .nav--links {
    width: 86%;
  }
}

@media (min-width: 991px) {
  .navigation {
    background-color: #eaeaea;
    height: 50px;
  }
}

@media (max-width: 991px) {
  ﻿﻿﻿.navigation.nav--shut {
    height: 0;
    overflow: hidden;
  }
}

.nav__sub.nav--opened {
  position: relative;
}

@media (max-width: 991px) {
  .nav--opened {
    height: auto;
    width: 100%;
    z-index: 9999;
    color: #2e6ad0;
  }
}

i.arrow--desk {
  margin-left: 6px;
  font-size: 1.1em;
  height: 50px;
  line-height: 50px;
  padding-right: 10px;
  position: absolute;
  color: #7eccf0;
}
@media (max-width: 991px) {
  i.arrow--desk {
    display: none;
  }
}

@media (max-width: 991px) {
  .nav--shut {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 991px) {
  .nav__sub.nav--shut {
    height: 0;
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .nav__item.offers .arrow--item {
    display: none;
  }
}

@media (max-width: 1229px) and (min-width: 992px) {
  .nav__item:nth-child(2) .nav--ad {
    display: none;
  }
  .nav__item:nth-child(2) ul.hot--tubs {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .nav__item {
    float: left;
    width: 14.8%;
    padding: 0 10px;
  }
  .nav__item:hover .nav__sub {
    visibility: visible;
    overflow: visible;
    height: auto;
  }
  .nav__item:hover .greyblock {
    height: 5px;
    visibility: visible;
  }
  .nav__item:hover .nav--fix a, .nav__item:hover .nav--fix i {
    color: #2e6ad0;
  }
}

@media (min-width: 992px) {
  .nav--links-home {
    width: 6.3%;
  }
}
@media (min-width: 992px) {
  .nav--links-home span {
    display: none;
  }
}
@media (max-width: 991px) {
  .nav--links-home span {
    display: block;
  }
}
.nav--links-home .nav--links--on i {
  color: #2e6ad0;
}
@media (min-width: 992px) {
  .nav--links-home i {
    display: block;
    color: #7eccf0;
    font-size: 1.6em;
    margin-top: -5px;
  }
}
@media (max-width: 991px) {
  .nav--links-home i {
    display: none;
  }
}
.nav--links-home i:hover {
  color: #2e6ad0;
}
@media (max-width: 991px) {
  .nav--links-home .nav--links {
    width: 100%;
  }
}

@media (max-width: 1229px) and (min-width: 992px) {
  .nav__item--last {
    width: 31% !important;
  }
}
@media (min-width: 992px) {
  .nav__item--last {
    float: left;
    width: 29.991%;
    text-align: center;
    margin-top: -20px;
    font-weight: 500;
  }
}
@media (max-width: 991px) {
  .nav__item--last {
    display: none;
  }
}
.nav__item--last span {
  font-size: 0.75em;
}
.nav__item--last a {
  color: #ff8d00;
  font-size: 1.5em;
  font-weight: 600;
  text-decoration: none;
}

@media (min-width: 992px) {
  .nav {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .nav--links.on {
    color: #2e6ad0;
    background-color: #fff;
  }
}

@media (max-width: 991px) {
  .arrow--item {
    width: 14%;
    float: right;
    height: 44px;
    line-height: 40px !important;
    margin: auto;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 1.4em;
    margin: 12px 0 auto auto;
  }
}

.nav-sub--sub {
  color: #666666;
  font-size: 0.9em;
  display: inline;
  padding-left: 18px;
  position: relative;
  text-decoration: none;
  font-weight: normal;
}
.nav-sub--sub:hover {
  color: #2e6ad0;
}
@media (max-width: 991px) {
  .nav-sub--sub {
    font-size: 1em;
    clear: both;
    padding: 0 25px;
    margin: 15px 0;
    display: block;
  }
}

@media (min-width: 992px) {
  .nav--arrow i.arrow--item {
    display: none;
  }
}

@media (min-width: 992px) {
  .nav__sub--small {
    padding: 20px 15px !important;
    min-width: 175px;
    min-height: auto !important;
  }
}

.nav__sub {
  font-family: "Poppins", sans-serif;
}
@media (min-width: 992px) {
  .nav__sub {
    visibility: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    height: 0;
    left: 0%;
    background-color: #eaeaea;
    padding: 40px 0 20px;
    z-index: 598;
    min-height: 278px;
  }
}
.nav__sub .nav__advert {
  position: relative;
  display: block;
  width: 20%;
  float: left;
  margin-right: 15px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .nav__sub .nav__advert {
    margin-right: 10px;
  }
}
.nav__sub .nav__advert.float--right {
  float: right !important;
}
.nav__sub .nav--ad {
  margin-right: 0;
}
@media (max-width: 991px) {
  .nav__sub .nav__advert {
    float: none;
    display: inline-block;
    width: 45%;
    margin: 10px 15px;
  }
  .nav__sub .nav__advert img {
    margin-right: 10px;
    width: 100%;
    display: inline;
  }
}
@media (max-width: 767px) {
  .nav__sub .nav__advert {
    width: 86%;
    margin: 10px 15px;
  }
  .nav__sub .nav__advert img {
    margin-right: 10px;
    width: 100%;
    display: inline;
  }
}

@media (max-width: 1229px) and (min-width: 992px) {
  .nav__access {
    width: 530px !important;
  }
}
@media (min-width: 992px) {
  .nav__access {
    float: left;
    width: 666px;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .nav__access .nav__sub-sub--items {
    width: 27% !important;
  }
}
@media (min-width: 992px) {
  .nav__access .nav__sub-sub--items {
    width: 29%;
  }
}

@media (max-width: 1229px) and (min-width: 992px) {
  .nav--links--on {
    font-size: 0.85em !important;
  }
}
@media (min-width: 992px) {
  .nav--links--on {
    color: #2e6ad0;
    font-size: 0.95em;
  }
}
.nav--links--on a {
  color: #2e6ad0;
}

@media (min-width: 992px) {
  .nav--fix {
    position: relative;
    display: block;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .nav__item--new {
    width: 16%;
    position: relative;
  }
}

@media (min-width: 992px) {
  .nav__item--why {
    width: 18.5%;
    position: relative;
  }
}

@media (min-width: 992px) {
  .nav__item--subs:nth-child(3) {
    width: 17%;
  }
}

@media (min-width: 992px) {
  .nav--about {
    width: 12.5%;
  }
}
@media (max-width: 991px) {
  .nav--about .nav--links {
    width: 100%;
  }
}

@media (max-width: 1229px) and (min-width: 992px) {
  .nav--contact {
    width: 11% !important;
  }
}
@media (min-width: 992px) {
  .nav--contact {
    width: 11%;
  }
}
@media (max-width: 991px) {
  .nav--contact .nav--links {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .nav__sub--list {
    border: 0;
    text-align: left;
    list-style: none;
    margin: 5px 0;
  }
}
@media (max-width: 990px) {
  .nav__sub--list {
    padding: 0 25px;
    list-style: none;
  }
}
.nav__sub--list i {
  margin-right: 10px;
  color: #ff8d00;
  position: absolute;
  left: 0;
  top: 4px;
}
.nav__sub--list .see--all i {
  position: static;
}

@media (min-width: 991px) {
  .sub--list {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .nav__sub--items {
    height: auto;
    display: block;
  }
}
@media (max-width: 991px) {
  .nav__sub--items {
    background-color: #fff;
    padding: 0;
  }
}

.nav__sub--title {
  list-style: none;
  color: #1d4384;
  font-weight: 500;
  margin-bottom: 15px;
}
.nav__sub--title a {
  color: #1d4384;
  text-decoration: none;
  font-weight: 500;
}
.nav__sub--title a:hover {
  color: #2e6ad0;
}
@media (max-width: 991px) {
  .nav__sub--title {
    padding: 0 25px;
    margin-top: 15px;
    display: block;
  }
}

.nav__sub-prods a {
  color: #666666;
  font-weight: normal;
}

@media (min-width: 992px) {
  .nav__sub-sub--items {
    width: 16%;
    float: left;
    margin-right: 30px;
    border-right: 1px solid #e1e1e1;
    padding-right: 15px;
  }
}
.nav__sub-sub--items .sub-sub--list {
  float: none;
  padding: 0;
}

@media (min-width: 992px) {
  .hot--sub-title {
    float: left;
    display: block;
    width: 55%;
  }
}

@media (min-width: 992px) {
  .hot--tubs {
    float: left;
    width: 50%;
    column-count: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    margin-right: 0;
    border-right: none;
    position: relative;
    display: block;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, auto);
    min-width: fit-content;
    grid-column-gap: 10px;
  }
  .hot--tubs::before {
    height: 500px;
    width: 1px;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-15px);
    background-color: #e1e1e1;
  }
}
@media (min-width: 992px) {
  .hot--tubs li {
    display: block;
    min-height: 25px;
    margin: 0;
    position: relative;
  }
  .hot--tubs li a {
    min-height: 20px;
  }
}
@media (min-width: 992px) {
  .hot--tubs li {
    height: 2px;
  }
}
@media (min-width: 992px) {
  .hot--tubs li:nth-child(7), .hot--tubs li:nth-child(8), .hot--tubs li:nth-child(6), .hot--tubs li:nth-child(5) {
    border-right: 0;
  }
}

@media (min-width: 992px) {
  .nav--hottubs {
    margin-top: -40px;
  }
}

.nav__sub-sub--items:nth-child(3) {
  border-right: none;
}

.nav__sub-new {
  /*
  	li:nth-child(-n+3) {
  		@include media('>tablet') {
  			border-right: 1px solid $color-nav-border;
  			height: 2px;
  		}
  	}*/
}
@media (min-width: 992px) {
  .nav__sub-new {
    float: left;
    margin-right: 0;
    border-right: none;
    position: relative;
  }
}
@media (min-width: 992px) {
  .nav__sub-new .nav__sub--title {
    float: left;
    display: block;
    width: 55%;
  }
}
@media (min-width: 992px) {
  .nav__sub-new li {
    display: block;
    margin: 0;
    height: 100% !important;
    margin-bottom: 5px;
  }
}

@media (min-width: 992px) {
  .greyblock {
    position: absolute;
    bottom: -5px;
    width: 100%;
    left: 0;
    visibility: hidden;
    height: 0px;
    z-index: 9999;
  }
}

.all--accessories {
  display: none !important;
}

.nav-sub__all a {
  color: #fff;
  display: block;
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin: 80px auto 0;
}

.see--all {
  font-size: 0.82em;
  color: #666666;
  display: block;
  text-decoration: none;
  font-weight: normal;
}
@media (min-width: 1026px) {
  .see--all {
    position: absolute;
    bottom: 15px;
  }
}
.see--all i {
  margin-left: 10px;
  position: initial;
}
.see--all:hover {
  color: #2e6ad0;
}

.review {
  margin-bottom: 65px;
}
.review h2 {
  text-align: center;
  font-weight: 500;
  color: #1d4384;
}
@media (max-width: 767px) {
  .review h2 {
    font-size: 1.4em;
  }
}

.review__page {
  margin-bottom: 35px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 25px 15px;
}

.review__quote {
  font-style: italic;
  font-weight: 400;
  text-align: center;
  max-width: 980px;
  margin: auto;
  padding: 0 35px;
}
@media (max-width: 767px) {
  .review__quote {
    font-size: 0.9em;
  }
}

.review__stars {
  margin: 35px auto 0;
  text-align: center;
  color: #e89f3b;
}
.review__stars i {
  margin-right: 2px;
}

.review__name {
  text-align: center;
  color: #2e6ad0;
  font-weight: 600;
}

.review--name {
  color: #1d4384;
  font-weight: 600;
  margin-bottom: 10px;
}

.review--stars {
  margin: 0 auto 15px;
  min-height: 30px;
  font-size: 1em;
}
.review--stars i {
  color: #fe8d03;
}

.review--quote {
  font-style: italic;
  font-weight: 400;
  max-width: 980px;
  margin: auto;
}
@media (max-width: 767px) {
  .review--quote {
    font-size: 0.9em;
  }
}

.review-stars {
  position: relative;
  width: 125px;
  height: 25px;
  line-height: 25px;
  font-size: 1em;
  display: block;
  float: left;
}

.review-stars .stars {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.review-stars .stars.blank {
  width: 100%;
  color: #999;
}

.review-stars .stars.full {
  color: #e89f3b;
}

.review-stars .star-connect {
  width: 125px;
  display: block;
}

.review-stars .stars i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  float: left;
}

#review h1 {
  font-weight: 400;
}
#review span {
  color: #666666;
  margin: 15px 0;
  display: block;
}

.reviews__new {
  background-color: #1d4384;
  border: 1px solid #ccc;
  padding: 15px 15px 0;
  border-radius: 2px;
  display: block !important;
}
.reviews__new .editor-label.no--comment {
  line-height: 40px;
}
.reviews__new .editor-label {
  color: #666666;
}
.reviews__new .editor-label.comment {
  margin-bottom: 10px;
  max-height: 60px;
  overflow: hidden;
}
.reviews__new .btn {
  margin: 30px auto;
  max-width: 300px;
}
.reviews__new h2 {
  font-size: 1.4em;
  margin-bottom: 15px;
}
.reviews__new a {
  display: none;
}

.reviews__new.hiding a {
  display: block;
  margin-bottom: 10px;
  font-size: 0.8em;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
}

.rev-view {
  width: 100%;
  max-width: 600px;
}
.rev-view .fancybox-title-float-wrap .child {
  display: none;
}
.rev-view .editor-label.comment {
  max-height: none;
}

.rev-view .reviews__new {
  width: 100%;
  max-width: 560px;
}

.rev-view a {
  display: none !important;
}

.reviews--g-cap {
  position: relative;
}

.review--split {
  padding: 0;
}

.review--left {
  position: static;
}

.review--right {
  float: right;
}

.review--help {
  padding: 15px;
  color: #fff;
  background-color: #2e6ad0;
  font-weight: 700;
  margin: 15px 10px;
  border-radius: 2px;
}

.review--rate {
  vertical-align: middle;
  line-height: 35px;
}

.review--full, .review--selected {
  color: #e89f3b;
}

@media (min-width: 1231px) {
  .review--capt {
    width: 32%;
  }
}
@media (min-width: 768px) {
  .review--capt {
    float: left;
    float: left;
  }
}

@media (min-width: 1231px) {
  .review--complete {
    width: 16%;
    margin: 12px 0;
  }
}
@media (min-width: 768px) {
  .review--complete {
    float: right;
  }
}

.review__rating {
  padding: 15px 30px;
}
.review__rating .section {
  background-color: #ccc;
  border-radius: 2px;
}
.review__rating .title {
  text-align: center;
  padding-top: 23px;
}

.review__rating--key {
  padding: 15px 0;
}

.rev-pnt {
  padding-left: 25px;
  margin: 15px 0;
}
.rev-pnt li {
  color: #2e6ad0;
}
.rev-pnt li span {
  color: #666666;
}

.review--capt .g-recaptcha {
  float: right;
  margin-top: 35px;
}
@media (max-width: 767px) {
  .review--capt .g-recaptcha {
    float: none;
    margin: 35px auto;
  }
}

.review--box {
  margin-bottom: 20px;
  /*	@include media('>phone') {
  		padding: 0 10px !important;
  	}*/
}

.review__customers h2 {
  margin-bottom: 25px;
}

.cust-revs {
  font-size: 0.85em;
}
.cust-revs .sun {
  margin: 10px 0;
}
.cust-revs i {
  width: 20px !important;
}
.cust-revs .review-stars {
  font-size: 1.2em;
}

.review--box:nth-child(4n+1) {
  clear: both;
}

.sitemap {
  font-size: 0.95em;
  padding: 0 !important;
}
.sitemap li {
  list-style: none;
}
.sitemap li a {
  color: #1d4384;
  text-decoration: none;
  font-weight: 500;
}
.sitemap li a:hover {
  color: #2e6ad0;
}
.sitemap .hot--sub-title {
  float: none;
  margin-bottom: 20px;
}
.sitemap .hot--tubs {
  column-count: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
  padding: 0 !important;
  width: 30%;
  margin-bottom: 0;
  float: left;
}
.sitemap .hot--tubs::before {
  display: none !important;
}
@media (max-width: 767px) {
  .sitemap .hot--tubs {
    width: 100%;
  }
}
.sitemap .hot--tubs li {
  border-right: none;
}
.sitemap .hot--tubs li, .sitemap .hot--tubs a {
  min-height: 20px;
}
.sitemap .nav__sub-sub--items {
  padding: 0 !important;
  margin-right: 0;
  width: 30% !important;
  border-right: none;
  float: left;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .sitemap .nav__sub-sub--items {
    width: 100% !important;
  }
}
.sitemap .nav__sub-sub--items span {
  color: #1d4384;
}
.sitemap .nav__access {
  width: 100% !important;
}
.sitemap .nav__access .section {
  margin: 0;
}
.sitemap .sub--list a {
  font-size: 0.9em;
}

@media (max-width: 991px) {
  ul.sitemap {
    padding-left: 25px !important;
  }
}

.site--two {
  column-count: 3;
  -moz-columns: 3;
  -webkit-columns: 3;
}

.faqs {
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .faqs {
    padding-bottom: 0px;
  }
}

.faq__top {
  cursor: pointer;
  padding: 17px 10px;
  background-color: #f7f7f7;
  color: #1d4384;
  position: relative;
  margin: 15px 0;
  border-radius: 2px;
}
.faq__top i {
  position: absolute;
  transform: translateY(-50%);
  right: 15px;
  top: 50%;
  text-align: center;
  font-size: 1.8em;
  color: #1d4384;
  line-height: 60px;
  vertical-align: middle;
}

.faq--title {
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 50px 0 0;
}

.faq__block.closed {
  height: auto;
}

.faq__content.closed {
  height: 0;
  overflow: hidden;
}

.faq__content {
  padding: 0 15px;
}
.faq__content ul li span {
  font-weight: 700;
}
.faq__content img {
  float: right;
}
@media (max-width: 767px) {
  .faq__content img {
    margin: auto;
    float: none;
  }
}
.faq__content a {
  color: #2e6ad0;
  font-weight: 600;
  text-decoration: underline;
}
.faq__content a:hover {
  color: #ff8d00;
  text-decoration: none;
}

.faq--info {
  color: #666666;
  display: block;
  font-size: 0.9em;
}
.faq--info.center-block strong a {
  font-size: 1.3em;
}
.faq--info ol {
  padding-left: 30px;
}

.pages {
  padding: 15px 0 50px;
}

.c-blue {
  color: #2f6ad0;
}

.c-orange {
  color: #f18c1a;
}

.borderline {
  border-bottom: 0.5px solid #1D4384;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.internal.privacy .alice-blue {
  display: block;
  font-size: 1.3em;
  color: #1d4384;
}

.exampleonly {
  font-weight: bold;
  font-size: 0.8em;
}

.greybg {
  margin-top: 40px;
  padding: 40px 0;
  background-color: #eaeaea;
}
.greybg .pages__content h2 {
  margin: 0 0 25px 0;
}
.greybg .topsec {
  border-left: 5px solid #1D4384;
  padding: 0 0 0 15px;
  margin: 20px 0;
}
.greybg .whitebx {
  background-color: #fff;
  text-align: center;
  padding: 20px 15px;
  margin-top: 30px;
}
.greybg .whitebx .info {
  font-size: 0.85em;
}

.pages__content .section.split .img-boot img {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.1607843137));
  max-width: 485px;
  width: 100%;
}
@media (min-width: 992px) {
  .pages__content .section.split {
    position: relative;
  }
  .pages__content .section.split h2 {
    margin-top: 0px;
  }
  .pages__content .section.split.txt-right .txt-boot {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
}
@media (min-width: 992px) and (min-width: 1231px) {
  .pages__content .section.split.txt-right .txt-boot {
    width: calc(100% - 550px);
  }
}
@media (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .pages__content .section.split.txt-right .txt-boot {
    width: calc(100% - 415px);
  }
}
@media (min-width: 992px) {
  .pages__content .section.split.txt-right .img-boot {
    width: 41.6666666667%;
  }
  .pages__content .section.split.txt-left .img-boot {
    float: right;
  }
  .pages__content .section.split.txt-left .txt-boot {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 550px);
  }
  .pages__content .section.split.img-left .txt-boot {
    float: right;
  }
}
@media (min-width: 992px) and (min-width: 1231px) {
  .pages__content .section.split.img-left .txt-boot {
    width: calc(100% - 550px);
  }
}
@media (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .pages__content .section.split.img-left .txt-boot {
    width: calc(100% - 415px);
  }
}
@media (min-width: 992px) {
  .pages__content .section.split.img-left .img-boot {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .pages__content .section.split.img-left .img-boot.large {
    left: -35px;
    top: 53%;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .pages__content .section.split.img-left .img-boot.large {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .pages__content .section.split.img-left .img-boot.large img {
    max-width: initial;
  }
  .pages__content .section.split.img-right .txt-boot {
    float: left;
  }
}
@media (min-width: 992px) and (min-width: 1231px) {
  .pages__content .section.split.img-right .txt-boot {
    width: calc(100% - 550px);
  }
}
@media (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .pages__content .section.split.img-right .txt-boot {
    width: calc(100% - 415px);
  }
}
@media (min-width: 992px) {
  .pages__content .section.split.img-right .img-boot {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 991px) {
  .pages__content .section.split {
    padding: 0px 15px;
  }
  .pages__content .section.split .txt-boot {
    float: none;
    max-width: 480px;
    margin: 0 auto;
  }
}
.pages__content .greybox {
  padding: 30px;
  background-color: #eaeaea;
  text-align: center;
}
.pages__content .greybox.delpage br {
  display: none;
}
.pages__content .greybox.delpage .basket--sum, .pages__content .greybox.delpage .basket--bold {
  font-size: 1.2em;
}
.pages__content .greybox.delpage ul {
  padding: 0;
  margin: 0;
}
@media (max-width: 767px) {
  .pages__content .greybox.delpage {
    padding: 20px;
  }
  .pages__content .greybox.delpage .basket--bold {
    font-size: 1.6em;
  }
  .pages__content .greybox.delpage .dw-xs-6 {
    max-width: 230px;
    margin: 0 auto 10px auto;
    float: none;
  }
}
.pages__content .greybox span {
  font-size: 1em;
}
.pages__content .greybox span strong {
  color: #1d4384;
}
.pages__content .greybox .btn {
  margin: 15px auto 0 auto;
  color: #fff;
  text-decoration: none;
  font-weight: normal;
}
.pages__content h2 {
  font-weight: 500;
  font-size: 1.6em;
  margin: 15px 0;
}
.pages__content h2:before, .pages__content h2:after {
  border-top: none;
}
.pages__content a {
  font-weight: 700;
  color: #2e6ad0;
  text-decoration: underline;
}
.pages__content a:hover {
  text-decoration: none;
  color: #ff8d00;
}
@media (max-width: 991px) {
  .pages__content .dw-md-4:nth-child(2n+1) {
    clear: both;
  }
}
.pages__content img {
  margin: auto;
}
@media (max-width: 991px) {
  .pages__content img {
    margin: 0 auto 15px;
  }
}
.pages__content table {
  margin-top: 25px;
  width: 100%;
}
.pages__content table th {
  text-align: left;
  color: #2e6ad0;
  font-weight: 500;
  background-color: #eaeaea;
  padding: 5px 10px;
}
.pages__content table tbody tr {
  border-bottom: 1px solid #eaeaea;
  display: block;
  font-size: 0.85em;
  font-weight: 700;
}
.pages__content table tbody .col--left {
  border-right: 1px solid #eaeaea;
  width: 190px;
}
.pages__content table tbody .col--right {
  padding: 8px 15px;
}
.pages__content table tbody .col--right span {
  display: block;
  font-weight: normal !important;
}
.pages__content .section.split {
  margin: 30px -15px 0 -15px;
}

.pages__content.warranty h2 {
  margin-top: 20px;
}

.page--text {
  font-size: 1em;
  display: block;
}
.page--text a {
  display: inline-block;
}
.page--text .quote .page--text {
  font-size: 0.85em;
}

.email--text {
  font-size: 0.9em;
  display: block;
}
.email--text a {
  display: inline-block;
}

.cust---quotes {
  margin-bottom: 30px;
  margin-top: 20px;
}
.cust---quotes .quote {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0705882353);
  border: 0.5px solid #1D4384;
  position: relative;
  padding: 10px 10px 30px 10px;
  border-radius: 6px;
  min-height: 300px;
  margin-bottom: 35px;
}
.cust---quotes .quote .tri {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0 0px;
  border-color: #1D4384 transparent transparent transparent;
  position: absolute;
  z-index: 9999;
  bottom: -30px;
  left: 15%;
  display: block;
}
.cust---quotes .quote .tri .inner {
  position: relative;
  top: -3px;
  left: 1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0 0px;
  border-color: #fff transparent transparent transparent;
}
.cust---quotes .quote img {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
@media (max-width: 991px) {
  .cust---quotes .quote img {
    bottom: 0;
  }
}
.cust---quotes .quote i {
  color: #F8D446;
}
@media (max-width: 991px) {
  .cust---quotes .quote {
    min-height: 100px;
  }
}
.cust---quotes .quote .page--text {
  display: inline;
  font-size: 0.85em;
}
.cust---quotes .quote--name {
  padding: 10px 0 0 60px;
  color: #333;
  text-transform: uppercase;
  position: relative;
  font-weight: bold;
  min-height: 42px;
}
@media (max-width: 991px) {
  .cust---quotes .quote--name {
    margin-bottom: 30px;
  }
}
.cust---quotes .quote--name i {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../img/review-icon.png");
  width: 42px;
  height: 42px;
  display: block;
}

.page--top {
  margin-top: 40px;
}

.page--center {
  text-align: center;
}
.page--center img {
  margin: 0 auto;
}

.delivery-map {
  text-align: center;
  margin: 20px auto;
  display: block;
  color: #666666;
  text-decoration: underline;
  font-weight: 500;
}
.delivery-map i {
  margin-right: 15px;
  color: #2e6ad0;
  font-size: 1.1em;
}
.delivery-map:hover {
  text-decoration: none;
}

.page__background {
  margin: 25px 0 30px;
  background-color: #eaeaea;
  padding: 40px;
  text-align: center;
}
.page__background h2 {
  margin: 0;
}
.page__background span {
  text-align: center;
  max-width: 840px;
  margin: 25px auto 0;
}

.page--line {
  border: 1px solid #eaeaea;
  margin: 20px 0;
}

.page__splits {
  margin: 40px 0 0px;
}
.page__splits h2 {
  margin: 20px 0 15px;
}
.page__splits img {
  margin: auto;
}

.check__route {
  margin: 25px 0 50px;
}

.page--bottom {
  color: #1d4384;
  font-weight: 500;
  padding: 15px;
  text-align: center;
  margin: 60px auto;
}

.page--centered-text {
  text-align: center;
  font-size: 0.85em;
  max-width: 900px;
  margin: auto;
  display: block;
}

.servicepage .section {
  margin: 0 -8px;
}
.servicepage .section .dw-md-4 {
  padding: 0 8px;
}
@media (max-width: 990px) and (min-width: 768px) {
  .servicepage .section .dw-md-4:nth-child(3) {
    margin-top: 30px;
  }
}
@media (max-width: 766px) {
  .servicepage .section .dw-md-4 {
    margin-bottom: 20px;
  }
  .servicepage .section {
    margin-top: -20px;
  }
}
.servicepage .box {
  text-align: center;
  font-size: 0.85em;
  padding: 20px 0 0 0;
  border: 0.5px solid #707070;
}
.servicepage .box .title {
  color: #1d4384;
  font-size: 1.8em;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 5px;
}
.servicepage .box span.total {
  color: #ff8d00;
  font-size: 2.5em;
  font-weight: bold;
}
.servicepage .box .title, .servicepage .box .text {
  padding: 0 25px;
}
.servicepage .box .inner {
  padding: 0 15px;
}
.servicepage .box .text {
  margin: 15px 0;
  min-height: 290px;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .servicepage .box .text {
    min-height: 350px;
  }
}
@media (max-width: 990px) and (min-width: 768px) {
  .servicepage .box .text {
    min-height: 320px;
  }
}
@media (max-width: 766px) {
  .servicepage .box .text {
    min-height: 20px;
  }
}
.servicepage .box .grey-bg {
  min-height: 500px;
  background-color: #eaeaea;
  padding: 15px;
  text-align: left;
}
@media (max-width: 1229px) and (min-width: 992px) {
  .servicepage .box .grey-bg {
    min-height: 550px;
  }
}
@media (max-width: 766px) {
  .servicepage .box .grey-bg {
    min-height: 20px;
  }
}
.servicepage .box .grey-bg .bullet {
  position: relative;
  padding: 0 0 0 20px;
  margin-bottom: 10px;
}
.servicepage .box .grey-bg .bullet i {
  position: absolute;
  top: 4px;
  left: 0;
  color: #1d4384;
}

.page--centered-text.bottom {
  margin-top: 50px;
}

.page__list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.page--list {
  padding: 0 30px;
  list-style-position: inside;
  max-width: 840px;
  margin: auto;
}
.page--list li {
  text-align: center;
}
.page--list span {
  margin: 0 auto;
  display: inline-block;
}

.product__thumbs {
  display: block;
  margin: 0 auto;
  max-width: 840px;
  padding: 0 !important;
  cursor: pointer;
}
.product__thumbs li {
  float: left;
  list-style: none;
  margin: 15px 15px 0 0;
}
@media (max-width: 991px) {
  .product__thumbs li {
    width: 31.9%;
  }
}
@media (max-width: 991px) {
  .product__thumbs li {
    width: 47.8%;
  }
}
@media (max-width: 370px) {
  .product__thumbs li {
    width: 47%;
  }
}
.product__thumbs li:hover {
  opacity: 0.9;
}
@media (min-width: 991px) {
  .product__thumbs li:nth-child(3n+3) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .product__thumbs li:nth-child(2n+2) {
    margin-right: 0;
  }
}

.energy-saving-list .mob-energy-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 25px;
}
@media (max-width: 767px) {
  .energy-saving-list .mob-energy-list {
    gap: 5px;
  }
}
@media (min-width: 992px) {
  .energy-saving-list .mob-energy-list {
    display: none;
  }
}
@media (max-width: 991px) {
  .energy-saving-list .desktop-energy-list {
    display: none;
  }
}
.energy-saving-list h2 {
  font-size: 1.55em;
}
@media (max-width: 767px) {
  .energy-saving-list h2 {
    margin: 10px 0;
    font-size: 1.25em;
  }
}
.energy-saving-list img {
  margin: unset;
}
@media (max-width: 991px) {
  .energy-saving-list img {
    margin: auto;
  }
}
.energy-saving-list .flx-left {
  display: flex;
  justify-content: left;
}
.energy-saving-list .flx-right {
  display: flex;
  justify-content: right;
}
@media (max-width: 767px) {
  .energy-saving-list {
    padding-top: 0;
  }
}

.energy.internal .warranty__sub {
  font-size: 1.55em;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .energy.internal .warranty__sub {
    font-size: 1.25em;
    text-align: left;
  }
}
.energy.internal .warranty__sub span {
  font-size: 0.73em;
  font-weight: 500;
  margin: 10px 0;
}
.energy.internal .faq--info {
  text-align: center;
}
.energy.internal .page--text, .energy.internal .faq--info {
  font-size: 1.12em;
}
@media (max-width: 766px) {
  .energy.internal .page--text, .energy.internal .faq--info {
    font-size: 0.88em;
    text-align: left;
  }
}
.energy.internal .page--text strong, .energy.internal .faq--info strong {
  color: #1D4384;
}
.energy.internal .page--text strong a, .energy.internal .faq--info strong a {
  color: #1D4384;
}
.energy.internal .page--text .bottom a, .energy.internal .faq--info .bottom a {
  color: #1D4384;
}
.energy.internal .blue-box {
  background-color: #def2fb;
  padding: 27px 0;
}
.energy.internal .blue-box span {
  font-size: 1.25em;
}
@media (max-width: 767px) {
  .energy.internal .blue-box span {
    font-size: 0.88em;
  }
}
.energy.internal .pages__content .sub {
  font-size: 1.13em;
}
@media (max-width: 767px) {
  .energy.internal .pages__content .sub {
    font-size: 0.88em;
    text-align: left;
  }
}
.energy.internal .pages__content h2.products--heading {
  font-weight: 600;
  font-size: 1.25em;
}
@media (max-width: 767px) {
  .energy.internal .pages__content h2.products--heading {
    text-align: left;
    margin-bottom: 10px;
  }
}
.energy.internal .bottom {
  font-size: 1.25em;
}
@media (max-width: 767px) {
  .energy.internal .bottom {
    font-size: 0.88em;
  }
}
.energy.internal .bottom strong {
  color: #666666;
}
.energy.internal .bottom strong a {
  color: #1D4384;
}
.energy.internal .carousel-container {
  background-color: #F5F5F5;
  margin: 30px 0;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .energy.internal .carousel-container {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
  }
}

.banner--energy .internal__sub {
  font-size: 1.12em;
}
@media (max-width: 767px) {
  .banner--energy .internal__sub {
    font-size: 0.88em;
  }
}
.banner--energy h1 {
  font-size: 1.88em;
}

.gallery h2 {
  font-size: 1.5em;
  font-weight: 500;
  color: #1d4384;
}
.gallery h2:before, .gallery h2:after {
  border-top: none;
}

.gallery-slider--inner .slide {
  height: 205px;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.gallery-slider--inner .slide .overlay {
  background-color: #1D4384;
  opacity: 0.6;
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.gallery-slider--inner .slide strong {
  font-weight: 500;
  color: #fff;
  font-size: 1.2em;
  text-shadow: 0 1px 2px #000;
}
.gallery-slider--inner .slide .gallery__content {
  position: absolute;
  bottom: 0%;
  width: 100%;
  z-index: 999;
}
.gallery-slider--inner .slide .gallery__content .btn {
  border-radius: 0;
  margin: 0;
}

#videos .section {
  margin: 0 -5px;
}
#videos .section .dw-sm-4 {
  padding: 0 5px;
}
#videos .section .dw-sm-4:nth-child(3n+1) {
  clear: both;
}
#videos .bx-wrapper .bx-controls-direction a.fa-chevron-left {
  left: -50px;
}
@media (max-width: 991px) {
  #videos .bx-wrapper .bx-controls-direction a.fa-chevron-left {
    left: -5px;
  }
}
#videos .bx-wrapper .bx-controls-direction a.fa-chevron-right {
  right: -50px;
}
@media (max-width: 991px) {
  #videos .bx-wrapper .bx-controls-direction a.fa-chevron-right {
    right: -5px;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery__video {
  position: relative;
  height: 218px;
  max-width: 390px;
  margin: 15px 0;
}
.gallery__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.gallery__video:hover i {
  color: #ff8d00;
}

.gallery__background {
  background-size: cover;
  background: url("../img/pht-video.jpg") no-repeat center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gallery__background i {
  color: #fff;
  font-size: 2em;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}
.gallery__background .fa-play:before {
  margin-left: 5px;
}

.warranty--form {
  margin: 20px 0 0 0;
}

#reg-form {
  cursor: pointer;
}

.products--heading.sidelines {
  padding: 20px 0;
  text-align: center;
  color: #1d4384;
  font-size: 1.5em;
}

.products--heading.sidelines:before, .products--heading.sidelines:after {
  border-top: 1px solid #1d4384 !important;
}

.bg-grey {
  background-color: #F5F5F5;
}

.warranty h2 {
  font-size: 1.4em;
}
.warranty a.editor-label {
  text-align: left;
  margin: 0;
  color: #ff8d00;
  font-size: 0.8em;
}
@media (min-width: 991px) {
  .warranty a.editor-label {
    margin-left: 15px;
  }
}
.warranty a.editor-label.serial {
  cursor: pointer;
}
.warranty a.editor-label.notorange {
  color: #2e6ad0;
  text-decoration: none;
}
.warranty .editor-label {
  font-weight: 600;
}
@media (max-width: 767px) {
  .warranty .editor-label {
    min-height: initial;
  }
}
.warranty .warranty__sub {
  color: #1d4384;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}
.warranty .warranty__sub strong {
  color: #2e6ad0;
  text-decoration: underline;
}
.warranty .warranty__sub strong:hover {
  color: #ff8d00;
  text-decoration: none;
}
.warranty .form__top {
  margin-top: 30px;
  margin-bottom: 20px;
}
.warranty .form__top h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.8em;
}
.warranty .form__top span {
  max-width: 980px;
  display: block;
  margin: auto;
  font-size: 0.9em;
}
.warranty input#DatePurchased {
  border-radius: 2px;
  border: 1px solid #dfdfdf;
  width: 100%;
  height: 40px;
  padding: 0 0 0 10px;
}
.warranty .browse {
  background-color: #fff;
  padding: 15px;
  font-family: inherit;
  position: relative;
}
.warranty .browse .star {
  color: #2e6ad0;
  left: 250px;
  top: 13px;
  display: block;
  position: absolute;
}
.warranty .browse input {
  color: #1d4384;
  font-size: 1em;
  font-family: inherit;
}
.warranty .date-picker {
  position: relative;
}
.warranty .date-picker .calendar-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  line-height: 50px;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.warranty ul li {
  font-size: 0.85em;
}

.warranty-banner {
  background-color: #7eccf0;
  padding: 20px;
  margin: 30px auto;
  color: #fff;
}
.warranty-banner span {
  font-size: 1.2em;
}
.warranty-banner span span {
  font-weight: 700;
  font-size: 1.8em;
}

.question__popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.question__popup h2 {
  text-align: center;
  margin: 50px auto 0;
  padding: 30px;
  color: #1d4384;
}
@media (max-width: 767px) {
  .question__popup h2 {
    margin-top: 20px;
  }
}
.question__popup .overlaypop {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.question__popup img {
  margin: 20px auto;
}
.question__popup .view {
  position: relative;
  width: 95%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  max-height: 90%;
  background-color: #f0f0f0;
  height: 632px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.8);
}
.question__popup .view .popset {
  overflow-y: auto;
}
@media (max-width: 767px) {
  .question__popup .view {
    height: auto;
    padding: 0 15px;
  }
}
.question__popup .surround {
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  max-width: 720px;
  margin: auto;
}
.question__popup .surround.open {
  opacity: 1;
  visibility: visible;
}
.question__popup .close {
  font-size: 1em;
  display: block;
  position: absolute;
  top: -20px;
  right: -17px;
  cursor: pointer;
  z-index: 999;
}
@media (max-width: 767px) {
  .question__popup .close {
    right: 10px;
    top: 10px;
  }
}
.question__popup .close .fa, .question__popup .close .fas {
  font-size: 1.3em;
  text-align: center;
  background-color: #2e6ad0;
  color: #fff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  line-height: 35px;
  float: left;
}
.question__popup .close .fa:hover, .question__popup .close .fas:hover {
  background-color: #ff8d00;
}
.question__popup .close .text {
  line-height: 30px;
  float: right;
  margin-left: 10px;
  float: left;
  display: none;
}
.question__popup .close.back {
  right: auto;
  left: 20px;
  display: none;
}
.question__popup .editor-field {
  margin: 20px 0;
}
.question__popup .editor-field input[type=text] {
  height: 45px;
  border-radius: 2px;
}
.question__popup .editor-field textarea {
  height: 110px;
  border-radius: 2px;
}
.question__popup .btn {
  height: 50px;
  line-height: 50px;
}

.question__popup.open {
  opacity: 1;
  visibility: visible;
}

.popup * {
  transition: none !important;
}
.popup .overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 99999;
}
.popup .pop-content {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #fff;
  border-radius: 3px;
  z-index: 999999;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 800px;
  overflow-y: auto;
  max-height: 80%;
}
.popup .pop-content .inner-rel {
  position: relative;
  padding: 60px 15px 15px 15px;
}
.popup .pop-content .inner-rel img {
  max-width: 300px;
  width: 100%;
}
.popup .pop-content .inner-rel .step__text {
  margin: 0 auto;
}
.popup .pop-content .inner-rel .step__text .row {
  margin: 0px auto;
  padding: 5px 10px;
}
.popup .pop-content .inner-rel .step__text .row:nth-child(odd) {
  background-color: #eee;
}
.popup .pop-content .inner-rel .step__text .row .section {
  position: relative;
}
.popup .pop-content .inner-rel .step__text .row .section .titl {
  width: calc(100% - 90px);
}
.popup .pop-content .inner-rel .step__text .row .section .pri {
  position: absolute;
  right: 15px;
  padding: 0px;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  width: 90px;
}
.popup .pop-content .inner-rel .step__text .row .section .pri span {
  font-weight: 700;
}
.popup .pop-content .inner-rel .fa-times {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  background-color: #2e6ad0;
  color: #fff;
  cursor: pointer;
  font-size: 1.2em;
  display: block;
  z-index: 10;
}
.popup .pop-content .inner-rel .fa-times:hover {
  background-color: #ff8d00;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-item .related--prod {
  width: 100% !important;
  margin: 0 !important;
  float: none !important;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  z-index: 9999;
  font-size: 1.8em;
  color: #1d4384;
  background-color: #fff !important;
  border-radius: 100%;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
}
.owl-theme .owl-nav [class*=owl-] i {
  color: #1d4384;
  font-size: 1.3em;
}
.owl-theme .owl-nav [class*=owl-] i:hover {
  color: #ff8d00;
}

.owl-theme .owl-nav .owl-next {
  right: -10px;
}
@media screen and (max-width: 582px) {
  .owl-theme .owl-nav .owl-next {
    right: 0;
  }
}

.owl-theme .owl-nav .owl-prev {
  left: -10px;
}
@media screen and (max-width: 582px) {
  .owl-theme .owl-nav .owl-prev {
    left: 0;
  }
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}