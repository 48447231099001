﻿.warranty--form {
    margin: 20px 0 0 0;
}

#reg-form {
    cursor: pointer;
}

.products--heading.sidelines {
    padding: 20px 0;
    text-align: center;
    color: #1d4384;
    font-size: 1.5em;
}

.products--heading.sidelines:before, .products--heading.sidelines:after {
    border-top: 1px solid #1d4384 !important;
}

.bg-grey {
    background-color: #F5F5F5;
}

.warranty {
    h2 {
        font-size: 1.4em;
    }

    a.editor-label {
        text-align: left;
        margin: 0;
        color: $color-orange;
        font-size: 0.8em;

        @include media('>=tablet') {
            margin-left: 15px;
        }

        &.serial {
            cursor: pointer;
        }

        &.notorange {
            color: $color-primary;
            text-decoration: none;
        }
    }

    .editor-label {
        font-weight: 600;

        @include media('<=phone') {
            min-height: initial;
        }
    }

    .warranty__sub {
        color: $color-bg-hero;
        margin-bottom: 20px;
        font-weight: 600;
        text-align: center;

        strong {
            color: #2e6ad0;
            text-decoration: underline;

            &:hover {
                color: $color-orange;
                text-decoration: none;
            }
        }
    }

    .form__top {
        margin-top: 30px;

        h2 {
            text-align: center;
            margin-bottom: 15px;
            font-size: 1.8em;
        }

        span {
            max-width: 980px;
            display: block;
            margin: auto;
            font-size: 0.9em;
        }

        margin-bottom: 20px;
    }

    input#DatePurchased {
        border-radius: 2px;
        border: 1px solid $color-search;
        width: 100%;
        height: 40px;
        padding: 0 0 0 10px;
    }

    .browse {
        background-color: $color-white;
        padding: 15px;
        font-family: inherit;
        position: relative;

        .star {
            color: #2e6ad0;
            left: 250px;
            top: 13px;
            display: block;
            position: absolute;
        }

        input {
            color: $color-bg-hero;
            font-size: 1em;
            font-family: inherit;
        }
    }

    .date-picker {
        position: relative;

        .calendar-btn {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 0.8em;
            line-height: 50px;
            width: 40px;
            height: 40px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
        }
    }

    ul {
        li {
            font-size: 0.85em;
        }
    }
}


.warranty-banner {
    background-color: $color-ligh-blue;
    padding: 20px;
    margin: 30px auto;
    color: $color-white;

    span {
        font-size: 1.2em;

        span {
            font-weight: 700;
            font-size: 1.8em;
        }
    }
}

.question__popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;

    h2 {
        text-align: center;
        margin:50px auto 0;
        padding: 30px;
        color: $color-bg-hero;

        @include media('<=phone') {
            margin-top: 20px;
        }
    }

    .overlaypop {
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
    }

    img {
        margin: 20px auto;
    }

    .view {
        position: relative;
        width: 95%;
        max-width: 800px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%, -50%);
        max-height: 90%;
        background-color: #f0f0f0;
        height: 632px;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.8);

        .popset{
            overflow-y: auto;
        }

        @include media('<=phone') {
            height: auto;
            padding: 0 15px;
        }
    }

    .surround {
        transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -webkit-transition: opacity 0.2s ease-in-out;
        opacity: 0;
        visibility: hidden;
        max-width: 720px;
        margin: auto;
    }

    .surround.open {
        opacity: 1;
        visibility: visible;
    }


    .close {
        font-size: 1em;
        display: block;
        position: absolute;
        top: -20px;
        right: -17px;
        cursor: pointer;
        z-index: 999;

        @include media('<=phone') {
            right: 10px;
            top: 10px;
        }

        .fa, .fas {
            font-size: 1.3em;
            text-align:center;
            background-color: $color-primary;
            color:$color-white;
            border-radius:100%;
            height: 35px;
            width: 35px;
            line-height: 35px;
            float: left;
            &:hover{ background-color: $color-orange; }
        }

        .text {
            line-height: 30px;
            float: right;
            margin-left: 10px;
            float: left;
            display:none;
        }
    }

    .close.back {
        right: auto;
        left: 20px;
        display:none;
    }

    .editor-field {
        margin: 20px 0;
    }

    .editor-field input[type=text] {
        height: 45px;
        border-radius: 2px;
    }

    .editor-field textarea {
        height: 110px;
        border-radius: 2px;
    }

    .btn {
        height: 50px;
        line-height: 50px;
    }
}

.question__popup.open {
    opacity: 1;
    visibility: visible;
}