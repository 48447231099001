﻿.product__steps {
    margin-top: 40px;
}

.step--one {
    max-width: 575px;
    margin: 0 auto 45px;
}

.step--two {
    .dw-sm-6:nth-child(2n+1) {
        @include media('<=tablet') {
            clear: both;
        }
    }
}

.steps__title {
    color: $color-bg-hero;
    font-weight: 500;
    text-align: center;
}

.step--head {
    font-size: 2em;
}

.step--sub {
    font-size: 1.5em;
    margin-bottom: 30px;

    @include media("<=desktop") {
        font-size: 1.2em;
    }
}

.step--info {
    max-width: 580px;
    text-align: center;
    margin: auto;
}

.step--summary {
    margin: 50px 0;
    border-top: 1px solid $color-line;
    padding: 35px 0;
    border-bottom: 1px solid $color-line;
}

.step_combo {
    border: 1px solid #f1f1f1;
    display: block;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 3px;

    &:hover {
        border: 1px solid #7eccf0;
    }

    &.selected {
        border: 1px solid #2e6ad0;

        .step--button {
            .dummy-check {


                i {
                    opacity: 1 !important;
                }
            }
        }
    }

    .step--button {
        input {
            display: none;
        }

        .dummy-check {
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 35px;
            border: 1px solid #ccc;
            display: block;
            margin: 0 auto;
            border-radius: 3px;
            margin-bottom: 5px;


            i {
                color: #2e6ad0;
                opacity: 0;
            }
        }
    }
}

@include media('<=desktop') {
    .option--list .option--boot:nth-child(3n+1) {
        clear: both;
    }
}

@include media('>desktop') {
    .option--list .option--boot:nth-child(4n+1) {
        clear: both;
    }
}


.build--tabs {
    .option--list {
        &.section {
            margin: 0px -15px;
        }
    }
}

.option--list {
    &.section {
        margin: 0px -5px;

        .option--boot {
            padding: 0px 5px;
        }

        @media screen and (max-width:420px) {
            .option--boot {
                width: 50%;
            }

            .option--boot:nth-child(3n+1) {
                clear: none;
            }

            .option--boot:nth-child(2n+1) {
                clear: both;
            }
        }

        .dw-md-4:nth-child(3) .step__payment img {
            max-width: 155px;
            margin: auto;
        }
    }
}

.build__pad {
    margin-top: 20px;

    h3 {
        font-weight: 500;
        color: $color-bg-hero;
        text-align: left;
        margin: 0 auto 15px;

        &:before, &:after {
            border-top: none;
        }
    }
}

.step--image {
    display: block;
    position: relative;

    @include media('<=phone') {
        max-width: 230px;
        margin: 0 auto;
    }

    i {
        position: absolute;
        bottom: 12px;
        color: #72c5ec;
        left: 5px;
        display: block;
    }

    &:hover {
        i {
            color: #ff8d00;
        }
    }

    img {
        margin: auto;
        display: inline-block;
    }

    a {
        text-decoration: none;
    }
}

.step--detail {
    margin: auto;
    max-width: 165px;
    display: block;
    font-size: 0.9em;
    position: relative;
    min-height: 55px;

    span {
        display: block;
        width: 100%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.step--button {
    input {
        width: 25px;
        height: 25px;
        margin-top: 5px;
    }
}

.step__note {
    border: 2px solid $color-orange;
    background-color: $color-orange-light;
    color: $color-orange;
    padding: 12px 15px;
    text-align: center;
    font-weight: 500;
    font-size: .85em;
    margin-bottom: 15px;

    i {
        margin-right: 15px;
        vertical-align: middle;
        font-size: 1.5em;
    }
}

.step__different {
    background-color: $color-grey;
    padding: 20px;

    span {
        font-weight: 500;
        font-size: 0.9em;
    }

    .btn {
        margin: 5px auto;

        i {
            margin-right: 15px;
            vertical-align: middle;
        }
    }
}

.coming--soon {
    display: block;
    position: absolute;
    left: -15px;
    top: -15px;
    background-color: $color-orange;
    color: $color-white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border-radius: 50px;
    width: 80px;
    height: 80px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);

    span {
        margin: 21px 0;
        display: block;
        font-size: 0.95em;
        line-height: 1.3;
    }
}

.step__payment {
    margin-top: 30px;
    border: 1px solid #ccc;
    padding: 30px 15px 30px;
    min-height: 430px;
    position: relative;
    height: 100%;
    max-width: 370px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media("<=tablet") {
        padding: 0.75rem 0.5rem;
        min-height: initial;
    }

    &.shrt {
        min-height: 500px;
        margin-top: 0px;
        margin-bottom: 15px;
        clear: both;

        @include media("<=desktop", ">tablet") {
            min-height: 500px;
        }

        @include media("<=tablet") {
            min-height: 0;
        }
    }

    &.finance {
        img {
            margin: 30px auto 0 auto !important;
        }

        @include media('<tablet') {
            margin: auto !important;
        }
    }

    &.selected--payment {
        border: 1px solid #ff8d00;
    }

    @include media('<desktop','>tablet') {
        min-height: 475px;
    }

    .step__top {
        min-height: 130px;

        @include media('<desktop','>tablet') {
            min-height: 120px;
        }

        @include media('<phone') {
            min-height: 166px;
        }

        @media screen and (max-width:500px) {
            min-height: 10px !important;

            .steps__title {
                min-height: 10px !important;
            }
        }
    }

    @media screen and (max-width:500px) {
        min-height: 10px !important;

        .step__text {
            min-height: 10px !important;
        }
    }

    .step--sub {
        margin-bottom: 15px;
        font-size: 1rem;
    }

    .step__price {
        color: $color-orange;
        font-weight: 600;
        margin-bottom: 15px;
        text-align: center;
        font-size: 1.1em;
    }

    img {
        margin: 0 auto 25px auto;

        @include media(">tablet") {
            width: auto;
        }
    }

    .step__text {
        margin: 15px auto 0;
        font-size: 0.7rem;
        padding: 0 15px;

        @include media("<=desktop", ">tablet") {
            padding: 0px;
        }

        @include media("<=tablet") {
            min-height: 65px;
        }

        span {
            display: block;
            font-size: 0.9em;
        }
    }

    .btn {
        margin-bottom: 0;
    }

    strong {
        display: block;
        margin-top: 20px;
    }
}

.step__payment.selected {
    border: 1px solid $color-primary;
}

.step__package {
    border: 1px solid $color-line;
    display: block;
    cursor: pointer;
    margin-bottom: 15px;
}

.no--packs {
    background-color: $color-footer-grey;
}

.step__next {
    max-width: 575px;
    display: block;
    margin: 15px auto;
}

.step--three {
    margin-bottom: 50px;
}

.package {
    padding: 30px 5px;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
    display: block;
    color: $color-white;
}

.package__price {
    text-align: center;
    border-bottom: 1px solid $color-line;
    padding: 10px 0;
    font-weight: 700;
    display: block;

    .price {
        color: $color-orange;
        font-size: 2.5em;
    }

    .percentage {
        color: $color-baby-blue;
    }
}


.package__involved {
    border-bottom: 1px solid $color-line;
    padding: 20px 0;
    display: block;
    height: auto !important;

    @include media('>=desktop') {
        min-height: 395px;
    }

    @include media('>=tablet', '<desktop') {
        min-height: 395px;
    }

    @include media('<tablet', '>=phone') {
        min-height: 395px;
    }

    @include media('<phone') {
        min-height: 450px;
    }

    @media screen and (max-width:500px) {
        min-height: 10px;
    }

    .section {
        margin: 0;
    }

    i {
        vertical-align: middle;
        font-size: 1.5em;
        color: $color-plus;
        position: absolute;
    }
}

.package__around {
    display: block;
    margin: 5px 0;
}

.pack__list {
    display: block;
    position: relative;
}

.pack__text {
    font-weight: 500;
    display: inline-block;
    padding-left: 35px;
}

.package--standard {
    .package {
        color: $color-text;
    }
}

.package__print {
    padding: 20px;
    font-size: 0.8em;
    font-weight: 500;
    border-bottom: 1px solid $color-line;
    display: block;

    @include media('>=tablet') {
        min-height: 150px;
    }

    @include media('<tablet', '>=phone') {
        min-height: 112px;
    }

    @include media('<phone') {
        min-height: 150px;
    }

    @media screen and (max-width:500px) {
        min-height: 10px;
    }
}

.package__select {
    padding: 15px;
    text-align: center;
    margin: auto;
    display: block;

    input {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        vertical-align: middle;
    }

    span {
        font-weight: 500;
        font-size: 1.0em;
    }
}

.other__packages {
    margin-top: 35px;

    .package__image {
        display: block;

        img {
            margin: auto;
        }
    }



    .package__name {
        color: $color-bg-hero;
        font-size: 1.5em;
        font-weight: 500;

        @include media('>=tablet') {
            min-height: 70px;
            font-size: 1.3em;
        }

        @include media('<tablet') {
            min-height: 70px;
            font-size: 1.3em;
        }

        @media screen and (max-width:500px) {
            min-height: 10px !important;
        }

        margin: 10px 0;
        display: block;
        padding: 0 20px;
    }

    .package__price {
        color: $color-orange;
        text-align: left;
        padding-left: 20px;
        border-bottom: none;
    }
}

.quick--option {
    display: none;
}


.financeinfo {
    .row {
        margin: 0px auto;
        padding: 5px 10px;
        max-width: 340px;

        &:nth-child(odd) {
            background-color: #eee;
        }

        position: relative;

        .title {
            width: calc(100% - 90px);
        }

        .price {
            position: absolute;
            right: 15px;
            padding: 0px;
            top: 50%;
            transform: translateY(-50%);
            text-align: right;
            width: 90px;
            font-weight: 700;
        }
    }

    &.wide {
        .row {
            max-width: 100%;
        }
    }
}
