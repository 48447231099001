﻿.ranges {
    margin-top: 40px;

    h1 {
        margin: 0;
        text-align: center;
        font-weight: 500;
        color: $color-bg-hero;
        position: relative;

        @include media('<=phone') {
            font-size: 1.7em;
        }

        @include media('<=320px') {
            font-size: 1.5em;
        }


        &:before {
            margin-left: -265px;
            text-align: right;
        }

        &:after {
            margin-left: 15px;
        }

        &:before, &:after {
            position: absolute;
            top: 51%;
            overflow: hidden;
            width: 100%;
            max-width: 250px;
            height: 1px;
            content: '';
            border-top: 1px solid $color-bg-hero;
        }
    }

    .bx-wrapper {
        margin: auto;

        .bx-controls-direction {
            a {
                top: 50%;
                color: $color-bg-hero;
            }

            .fa-chevron-left {
                left: -40px;

                @include media('<tablet') {
                    left: -20px;
                }

                @include media('<=phone') {
                    left: -10px;
                }
            }

            .fa-chevron-right {
                right: -40px;

                @include media('<tablet') {
                    right: -20px;
                }

                @include media('<=phone') {
                    right: -10px;
                }
            }
        }
    }

    .bx-viewport {
        margin-bottom: 50px;
    }

    img {
        margin: auto;
    }

    &.location {
        @include media('<tablet') {
            padding-top: 25px;
        }
    }
}

.sale--items {
    margin-bottom: 15px;

    @include media('<tablet') {
        &.dw-sm-6:nth-child(2n+1) {
            clear: both;
        }
    }

    &.dw-xs-12 {
    }
}

.dw-xs-12.sale--items, .listing--boot {
    @media screen and (max-width:768px) and (min-width:550px) {
        width: 50% !important;
        float: left;
    }
}

.range__around:nth-child(4n+1) {
    @include media('>=tablet') {
        clear: both;
    }
}

.range__around.range--slider:nth-child(4n+1) {
    @include media('>=tablet') {
        clear: none;
    }
}

.dw-md-3.sale--items:nth-child(5) {
    @include media('>=tablet') {
        clear: both;
    }
}

.related--prod {
    @include media('<=phone') {
        margin: 0 24px;
    }

    .range--price {
        text-align: center;
        width: initial;
        display: block;
        min-height: 75px;
    }

    .range--reserve {
        float: right;
    }

    @include media('<=550px') {
        margin: 0 2.2%;
    }

    @include media('<=400px') {
        margin: 0 1.2%;
    }

    @include media('<=330px') {
        margin: 0%;
    }
}

.location-page-banner {
    height: 265px;
    background-position: center;
    background-size: cover;
}

.no--slider {
    .range__around {
        margin-right: 10px;

        @include media('<desktop','>tablet') {
            width: 23.999%;
        }

        @include media('<=tablet') {
            width: 31.9%;
        }

        @include media('<=phone') {
            width: 48.333% !important;
        }

        @include media('<=665px') {
            width: 100% !important;
        }
    }

    .range__around:nth-child(4) {
        margin-right: 0;
    }
}

.flx-section {
    display: flex;
    align-items: center;


    @include media('<=tablet') {
        &.reverse {
            flex-direction: row-reverse;
        }
    }

    @include media('<=phone') {
        flex-direction: column;
        grid-gap: 15px;

        &.reverse {
            flex-direction: column-reverse;
        }
    }
}

h3.loc:after {
    display: none !important;
}

h3.loc:before {
    display: none !important;
}

.range__intro {
    text-align: center;
    font-weight: 400;
    margin: 30px auto 40px;
    max-width: 980px;

    &.loc-txt {
        text-align: start;
    }

    @include media('<=phone') {
        font-size: 0.9em;
    }

    a {
        text-decoration: underline;
        font-weight: 500;
        color: $color-bg-hero;

        &:hover {
            text-decoration: none;
            color: $color-orange;
        }
    }

    &.location {
        display: flex;
        align-items: center;
        gap: 50px;
        max-width: unset;

        @include media('<=desktop') {
            flex-direction: column;
        }
    }
}

.range__around {
    position: relative !important;
}

.range__link.addcursor {
    cursor: pointer;

    .btn {
        cursor: pointer;
    }
}

.range__link {
    display: block;
    text-decoration: none;

    @include media('<phone') {
        .range--reserve {
            width: 50%;
        }

        .range--finance {
            width: 50%;
        }
    }

    &:hover {
        .range__block {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        }


        .btn--primary {
            background-color: $color-orange;
            border: 1px solid $color-orange;
        }
    }
}

.range__top {
    padding: 8px 15px 15px 15px;

    img {
        margin: auto;
    }
}

.range__block {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 15px;

    @include media("<=phone") {
        margin-bottom: 30px;
    }

    .btn--primary {
        margin-bottom: 0px;
        margin-top: 0px;
    }

    &.orange--border {
        border: 1px solid $color-orange;
    }
}

.block--accessories {

    select {
        margin-bottom: 5px;
    }

    .range__top-info {
        margin-top: 25px;
        padding: 0 5px;

        .range--title {
            text-align: left;
            font-weight: 500;
        }

        .range--price {
            text-align: center;
            width: initial;
            display: block;

            @include media('>phone') {
                &.mar23 {
                    margin-top: -23px;
                }
            }
        }

        .range--info {
            display: none;
        }
    }

    .range__bottom {
        display: none;
    }
}



.range__top-info {
    padding: 0 10px;
    text-align: center;
    position: relative;

    .squares {
        position: absolute;
        left: 5%;
        bottom: 10px;
        width: 90%;

        @include media('<=550px') {
            position: static;
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.range--title {
    font-size: 1.2em;
    color: $color-bg-hero;
    margin: 5px auto;
    min-height: 60px;
}

.range--description {
    text-align: left;
    color: $color-text;
    font-size: 0.8em;
    margin-bottom: 15px;
    min-height: 100px;
}

.range--price {
    color: $color-orange;
    font-weight: 700;
    font-size: 1.2em;
    min-height: 53px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;

    .was--price {
        font-size: 0.8em;
        color: #adadad;
        font-weight: 500;
        text-decoration: line-through;
        display: block;
        line-height: normal;
    }

    .price {
        span {
            color: $color-text;
            font-size: 0.8em;

            &.sale {
                display: block;
            }

            &.save--price {
                color: $color-save;
                display: block;
                line-height: normal;
            }
        }
    }
}

.range--reserve {
    background-color: $color-bg-hero;
    color: $color-white;
    width: 50%;
    text-align: center;
    font-size: 0.8em;
    padding: 5px;
    margin: 0 auto;
    float: right;

    @include media('<desktop','>phone') {
        float: none !important;
        margin-bottom: 10px;
        width: 100%;
    }

    span {
        font-weight: 700;
        display: block;
    }
}

.range--finance {
    background-color: $color-ligh-blue;
    text-align: center;
    font-size: 0.8em;  
    padding: 5px;
    margin: 0 auto !important;
    color: $color-primary !important;
    float: none;
    width: 100%;

    .range--bold {
        color: $color-primary !important;
    }
}

.range--info {
    color: $color-text;
    font-size: 0.8em;
    display: block;
    margin-top: 10px;
    position: relative;

    &.hov {
        cursor: pointer;

        &:hover {
            .fa-info {
                background-color: #ff6a00;
            }
        }
    }

    .fa-info {
        position: absolute;
        width: 25px;
        height: 25px;
        line-height: 25px;
        display: block;
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
        background-color: #2e6ad0;
        color: #fff;
        border-radius: 100%;
    }
}

.range--bold {
    color: $color-baby-blue;
    font-weight: 700;
}

.range__bottom {
    text-align: center;
    background-color: $color-grey;

    @include media('<=550px') {
        margin-top: 10px;
        clear: both;
    }

    .section {
        margin: 0;
        padding: 15px;
    }

    .btn {
        margin-top: 15px;
        margin-bottom: 0;
        cursor: default;
    }

    img {
        min-height: 50px;
        margin: auto;
    }
}

.range--amount {
    color: $color-bg-hero;
    font-weight: 700;
    font-size: 1.4em;
}

.range--text {
    font-weight: 600;
    color: $color-text;
    font-size: 0.9em;

    @include media('<=415px') {
        font-size: 0.65em;
    }
}
