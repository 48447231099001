﻿@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap');

.christmas-banner {
	position: relative;
	background-image: url('../img/banners/xmass-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	padding: 30px 0;

	.container.xmass {
		width: 1600px;

		.xmass-grid {
			display: grid;
			grid-template-columns: auto auto;
		}

		h1:after, h2:after {
			display: none;
		}

		h1, h2 {
			font-family: 'Playfair Display', serif;
			font-weight: 700;
		}

		h1 {
			font-size: 5.5rem;
			margin-left: -300px;
		}

		h2 {
			font-size: 3rem;
		}

		.closed {
			font-size: 2rem;
		}

		.sub-title {
			margin-top: 75px;
			font-family: Playfair Display,serif;
			font-size: 1.9rem;
			color: #1d4384;
			font-weight: 600;
		}

		.xmass-text {
			max-width:700px;
		}
	}
}




.carousel--badge {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 10;
	background-repeat: no-repeat;
}
.carousel__bg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 560px;

	@include media('<=tablet') {
		height: auto;
	}
}

.carousel {
	height: 560px;
	margin: 0 auto;

	@include media('<=tablet') {
		height: auto;
	}

}

.carousel-caption {
	@include media('<=phone') {
		position: relative;
		top: auto;
		left: auto;
		transform: initial;
	}

	.title {
		margin: 30px 0 0;
		color: $color-white;
		font-size: 1.5em;
		font-weight: 400;
		text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);

		@include media('<=tablet') {
			font-size: 1.2em;
		}
	}

	span {
		font-size: 3.8em;
		display: block;
		max-width: 600px;
		font-weight: 500;
		line-height: 1.4;
		color: $color-bg-hero;

		@include media('<=phone') {
			font-size: 2.8em;
		}

		@media screen and (max-width: 410px) {
			font-size: 2em;
		}
	}

	.btn--primary {
		max-width: 260px;
		display: block;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin: 55px 0;
		font-size: 1em;
	}

	.icons {
		margin-top: 60px;

		.icons__area {
			border: none;
			background-color: transparent;

			.fa-inverse {
				color: $color-primary;
			}
		}

		.icons--fa {
			color: $color-white;
			float: left;
		}

		.icons--text {
			color: $color-white;
			font-size: 0.8em !important;
		}
	}
}

.carousel .item {
	height: 560px;
	margin: auto;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;

	@include media('<=phone') {
		height: 400px;
	}
}
.carousel .item .container {
	position: relative;
	height: 560px;

	@include media('<=phone') {
		height: auto;
        min-height:400px;
	}
}

.carousel-indicators {
	display: none;
}

.carousel-control {
	position: absolute;
	color: $color-white;
	top: 50%;
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	-ms-transform: translate(-50% -50%);
	z-index: 5;
	font-size: 1.8em;
    z-index:99;
}

.carousel--left {

	@include media('>1801px') {
		left: 10%;
	}

	@include media('<1800px', '>1300px') {
		left: 2%;
	}

	@include media('<1299px','>desktop') {
		left: 12px;
	}



	@include media('<desktop','>tablet') {
		left: 2%;
	}

	@include media('<=tablet') {
		left: 15px;
	}

	@include media('<=phone') {
		display: none;
	}
}

.carousel--right {
	@include media('>1801px') {
		right: 10%;
	}

	@include media('<1800px', '>1300px') {
		right: 2%;
	}

	@include media('<1299px','>desktop') {
		right: 10px;
	}


	@include media('<desktop','>tablet') {
		right: 2%;
	}

	@include media('<=tablet') {
		right: 15px;
	}

	@include media('<=phone') {
		display: none;
	}
}
