﻿.sitemap {
    font-size: 0.95em;
    padding: 0 !important;

    li {
        list-style: none;

        a {
            color: $color-bg-hero;
            text-decoration: none;
            font-weight: 500;

            &:hover {
                color: $color-primary;
            }
        }
    }

    .hot--sub-title {
        float: none;
        margin-bottom: 20px;
    }

    .hot--tubs {
        column-count: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        padding: 0 !important;
        width: 30%;
        margin-bottom: 0;
        float: left;

        &::before{
            display: none !important;
        }

        @include media('<=phone') {
            width: 100%;
        }

        li {
            border-right: none;
        }

        li, a {
            min-height: 20px;
        }
    }

    .nav__sub-sub--items {
        padding: 0 !important;
        margin-right: 0;
        width: 30% !important;
        border-right: none;
        float: left;
        margin-bottom:5px;
        @include media('<=phone') {
            width: 100%!important;
        }

        span {
            color: $color-bg-hero;
        }
    }

    .nav__access {
        width: 100% !important;

        .section {
            margin: 0;
        }
    }

    .sub--list {
        a {
            font-size: 0.9em;
        }
    }
}

ul.sitemap {
    @include media('<=tablet') {
        padding-left: 25px !important;
    }
}

.site--two {
    column-count: 3;
    -moz-columns: 3;
    -webkit-columns: 3;
}
   
