﻿/* BREAKPOINTS ------------ */
$breakpoints: ( 'xs-phone': 400px, 'sm-phone': 500px, 'phone': 767px, 'tablet': 991px, 'desktop': 1230px, 'large': 1350px ) !default;
/*$breakpoints: ( 
    'phone': 320px, 
    'tablet': 768px, 
    'desktop': 1024px 
) !default;*/

/* COLOURS ------------ */
$color-primary: #2e6ad0;
$color-bg-hero: #1d4384;
$color-white: #fff;
$color-ligh-blue: #7eccf0;
$color-text: #666666;
$color-orange: #ff8d00;
$color-orange-light: #fddfb9;
$color-grey: #eaeaea;
$color-line: #ccc;
$color-baby-blue: #72c5ec;
$color-yellow: #e89f3b;
$color-footer-grey: #999999;
$color-nav-border: #e1e1e1;
$color-swatches: #d6d6d6;
$color-purple: #3c0c2a;
$color-top-dark: #333333;
$color-dark: #231f20;
$color-black: #000000;
$color-light-pink: #f6eef3;
$color-tabs: #2f68cf;
$color-plus: #72c5ed;
$color-faqs: #f7f7f7;
$color-was: #adadad;
$color-save: #fe4c0c;
$color-desc: #ebebeb;
$color-pink: #d57ab4;
$color-dark-pink: #b26695;
$color-dark-grey: #a4a4a4;
$color-search: #dfdfdf;
$color-checkout: #e1e1e1;
$color-stars-review: #fe8d03;
$color-carousel: #9fcf67 ;
$color-light: #eee;
$color-light-text: #999;
$color-light-grey: #ccc;
$color-darker-grey: #ccc;
$color-lighter-grey: #c4bcc1;
$color-grey-box: #eaeaea;

$color-svg: #d1cace;

$color-green: #2e8925;
$color-dark-green: #0c3c07;
$color-gold: #968937;
$color-dark-gold: #7a7029;
$color-teal: #0f5256;
$color-dark-teal: #00373a;
$color-turquoise: #2fccd5;
$color-dark-turquiose: #38a3ad;
$color-red: #ff4c0d;
$color-grey-border: #acacac;
$color-copy: #777;
$color-banners: #0f5256;

$color-paypal: #ffc439;
$color-indicators: #8c8c8c;