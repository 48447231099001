﻿.offers {

    h2 {
        text-align: center;
        font-weight: 500;
        color: $color-bg-hero;

        @include media('<=phone') {
           font-size: 1.4em;
           padding: 0 20px;
        }
    }
}

.offers__block {
    position: relative;
    min-height: 285px;
    background-repeat: no-repeat;
    background-size: cover;

    .section {
        margin: 0;
    }
}

.offers__block--left {
    @include media('>phone') {
        padding-right: 0;
    }
}

.offers__block--middle {
    @include media('>phone') {
        padding: 0;
    }

    @include media('<phone') {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    }
}

.offers__block--right {
    @include media('>phone') {
        padding-left: 0;
    }
}

.offers__block--image {
    min-height: 285px;
    background-size: cover;
    width: 100%;
}

.offers__block--ben {
    min-height: 400px;
    background-size: cover;
    width: 100%;

    @include media('<desktop','>tablet') {
        background-size: cover !important;
        min-height: 508px;
        background-position: 5% !important;
    }
}

.offers__block--image-one {
    background: url('../img/tile--one.jpg') no-repeat center;
}

.offers__block--image-two {
    background: url('../img/hot-tub-smart-tv.jpg') no-repeat center;
}

.offers__block--image-three {
    background: url('../img/tile--three.jpg') no-repeat center;
}

.offers__block--image-four {
    background-image: url('../img/tile--four.jpg'); background-position:50%; 
}

.offers__block--image-five {
    background-image: url('../img/tile--five.jpg'); background-position:50%; 
}

.offers__block--image-six {
    background-image: url('../img/tile--six.jpg'); background-position:50%; 
}

.offers__text {
    width: 100%;
    text-align: center;
    max-width: 400px;
    background-color: $color-grey;
    min-height: 285px;
    padding: 0 15px;

    @include media('<=phone') {
        min-height: unset;
        margin: auto;
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
        margin-bottom: 0px;
        max-width:100%;
        padding-bottom:25px;
    }
}

.benefits--box {
    min-height:400px;

    @include media('<desktop','>tablet') {
        min-height: 508px;
    }

    @include media('<=phone') {
         min-height:10px;

     }
}

.offers__title {
    font-size: 1.5em;
    font-weight: 400;
    padding-top: 45px;
    color: $color-bg-hero;

    @include media('<=tablet') {
        padding-top: 30px;
        font-size: 1.3em;
    }
}

.offers-title--benefits {
    padding-top: 15px;
}

.offers__detail {
    font-weight: 300;
    font-style: normal;
    font-size: 0.9em;
    max-width: 325px;
    margin: 25px auto 30px;

    @include media('<=tablet') {
        font-size: 0.8em;
    }
}

.offers--benefits {
    margin: 8px auto;
    max-width: 365px;
    font-size: 0.85em;
     @include media('<=phone') {
         max-width:100%;

     }
}

.offers--btn {
    max-width: 255px;
    margin: 0 auto;

    &.loc-txt {
        margin:0;
    }

    &.loc-txt.location--page {
        margin: auto;
    }
}

.superstore-finance {
    display: flex;
    justify-content: center;
    gap: 25px;
    padding-bottom: 50px;

    a.offers--btn {
        margin: unset !important;
    }
}

.offer--badge {
    position: absolute;
    top: 9px;
    right: 10px;
    z-index: 10;
    background-repeat: no-repeat;
}












.listing--product {
    .dw-md-3 {
        @include media('>=tablet') {
            width: 25%;
        }
    }

    .dw-md-3:nth-child(4n+1) {
        @include media('>=tablet') {
            clear: both;
        }
    }
}

.offers__bottom {
    margin-bottom: 50px;

    .section {
        margin-right: 0px;
    }

    h2 {
        max-width: 260px;
        text-align: center;
        margin: auto auto 15px;
    }

    .left {
        background-color: $color-light-pink;
        padding-right: 0;

        @include media('>tablet') {
            width: 42.4%;
        }
    }

    .right {
        padding-left: 0;

        @include media('>tablet') {
            width: 57.6%;
        }

        @include media('>=tablet') {
            min-height: 245px;
        }

        img {
            @include media('>=tablet') {
                min-height: 245px;
            }
        }
    }

    .btn {
        max-width: 270px;
        margin: 25px auto 0;
    }
}

.offers--surround {
    padding: 20px 40px;
    min-height: 245px;
    text-align: center;
    margin: auto;
    display: block;

    a:hover .btn--black {
        background-color: $color-black;
        border: 1px solid $color-black;
    }

    @include media('<desktop','>tablet') {
        padding: 10px 30px;
    }

    @include media('<=phone') {
        padding: 20px;
    }
}