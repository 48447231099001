﻿@import 'blocks/steps';
.basket {
	position: relative;
	padding: 0 15px; 
	input[type="text"], .basket--label {
		border: 1px solid $color-search;
		max-width: 40px;
		margin: 0 auto 0;
		display: block;
		color: $color-text;
		font-size: 1.2em;
		border-radius: 2px;
		text-align: center;
		padding: 0;
		margin-top: 25px;
		height: 40px;

		@include media('<tablet','>phone') {
			margin-top: auto;
		}
	}

	input[type="submit"] {
		border: none;
		max-width: 80px;
		margin: 0 auto;
		text-transform: uppercase;
		text-align: center;
		color: $color-primary;
		font-weight: 700;
		font-size: .85em;
		text-decoration: underline;
		background: transparent;

		&:hover {
			text-decoration: none;
			color: $color-orange;
		}
	}

	.offers__three {
		margin: 0 15px;
	}
}
.basket--label {
	@include media('<=phone') {
		margin: 0 !important;
		display: inline-block !important;
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
}
.basket__buttons {
	margin-top: 20px;

	.checkout-opt {
		.readywait {
			display: none;
		}

		.KO {
			display: block;
		}

		&.ready {
			.readywait {
				display: flex;
			}

			.KO {
				display: none;
			}
		}
	}

	.btn--primary, .btn--grey {
		width: 100%;

		@include media('<=phone') {
			margin: 10px auto;
			max-width: 285px;
		}
	}

	.btn--orange {
		max-width: 285px;
		float: right;

		@include media('<=phone') {
			float: none;
			margin: 10px auto;
		}
	}
}
.basket__form {
	padding: 35px 0 10px;
	border-top: 1px solid $color-line;
	border-bottom: 1px solid $color-line;

	@include media('<=phone') {
		border-bottom: unset;
	}
}

.co{
    .basket__form {
        border-bottom:0;
    }
}

.basket__heading {
	font-weight: 500;
	margin-bottom: 50px;
	color: $color-bg-hero;
	font-size: 1.6em;
	text-align: center;

	@media (max-width: 767px) {
		margin-bottom: 20px;
	}

}
.basket__row {
	margin-bottom: 15px;
	text-align: center;

	@include media('<=phone') {
		border-bottom: none;
		padding: 0 15px;
	}
}
.basket__row--main {
	color: $color-bg-hero;
	border-bottom: 1px solid $color-bg-hero;
	padding: 15px;
	font-size: 1.1em;
	font-weight: 500;

	@include media('<=phone') {
		display: none;
	}
}
.basket__column {
	padding-bottom: 15px;
	// min 768px
	@include media('>phone') {
		float: left;
	}
}

.basket__column--one {
	text-align: left;

	img {
		border: 1px solid $color-light-grey;
		border-radius: 2px;
	}

	width: 60%;
	// min 991px
	@include media('>tablet') {
		position: relative;
	}

	@include media('<=phone') {
		padding: 0;
		width: 100%;
		min-height: 100px;
		float: none;
		margin-bottom: 15px;
	}
}
.basket__column--two {
	width: 30%;

	@include media('<=phone') {
		width: 100%;
		float: left;
		text-align: left;

		input {
			margin: 0 0 0 5px;
			height: 40px;
			line-height: 40px;
			float: left;
			margin-top: 0 !important;
			padding: 0 15px;
		}

		.basket__column--xs {
			float: left;
			line-height: 40px;
		}
	}
}
.basket__column--three {
	width: 10%;

	@include media('<=phone') {
		display: none;
	}
}
.basket--bold {
	font-size: 1.2em;
	padding: 15px;
	display: block;
	font-weight: 700;
	margin: auto;
	text-align: center;
	color: $color-orange;

	@include media('<tablet','>phone') {
		margin-top: auto;
		font-size: .9em;
	}

	@include media('<=phone') {
		margin-top: auto;
        font-size:1em;
	}
}
.basket--title {
	color: $color-bg-hero;
	font-weight: 500;

	a {
		color: $color-bg-hero;
		font-weight: 500;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	display: block;
	// min 768px
	@include media('>tablet') {
		margin: 20px auto;
	}
}
.basket__quantity {
	display: block;

	@include media('<=phone') {
		display: inline-block;
	}
}
.basket--remove {
	margin: 20px 0;
	display: block;

	@include media('<=phone') {
		display: inline-block;
		float: right;
	}

	.basket--remove-text {
		text-transform: uppercase;
		display: inline-block;
		font-size: .85em;
		color: $color-red;
		font-weight: 500;
		text-decoration: none;
		margin-left: 10px;

		@include media('<tablet','>phone') {
			margin-top: auto;
		}

		@include media('<=phone') {
			margin-top: auto;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.fas {
		color: $color-red;
		vertical-align: middle;
		text-decoration: none;
	}
}
.basket__total {
	margin-top: 30px;
	padding-top: 15px;
	border-top: 1px solid $color-line;
	border-bottom: 1px solid $color-line;
	padding-bottom: 15px;
	@include media('<=phone') {
		padding: 0;
		border-top: 3px solid $color-primary;
		margin-top: 15px;
	}
}
.basket--sum {
	padding: 15px 0;
	font-size: 1em;
	font-weight: 500;
	color: $color-bg-hero;

	@include media('<=phone') {
		text-align: center;
		padding: 15px 0;
	}
}
.basket__admin {
	input {
		max-width: 75px !important;
	}
}
.basket__row--regular {
	min-height: 95px;

	border-bottom: 1px solid $color-line;
	// max 767px
	@include media('<=phone') {
		min-height: 0;
	}
}
.basket__column--xs {
	@include media('<=phone') {
		display: inline-block !important;
	}
}
.basket--size {
	display: block;
}
.basket__discount {
	background-color: $color-white;
	border: 1px solid $color-primary;
	cursor: pointer;
	min-height: 50px;
	line-height: 50px;
	padding: 0 15px;
	color: $color-primary;
	font-weight: 700;

	.fa {
		float: right;
		color: $color-orange;
		font-size: 1.4em;
		margin: 14px;
	}
}

.basket__discount--title {
	float: left;
	width: auto;
}

.basket-discount--slide {
	padding: 0;
	input[type="text"] {
		border: 1px solid #d4d4d4;
		max-width: 100%;
		margin: 15px 15px auto auto;
	}
}

.delivery__form {
	input {
		max-width: 100% !important;
		font-size: 0.9em !important;
		margin: 0 !important;
	}

	.basket--bold, .basket--sum {
		padding: 0;
		line-height: 40px;
	}
}

.user--discount {
	padding-left: 0!important;
	padding-right: 0 !important;

	input {
		max-width: 100% !important;
		font-size: 1em !important;
	}
}

.basket__discount--info {
	border: 1px solid $color-primary;
	background-color: $color-white;
	border-top: none;

	.discount__text {
		display: block;
	}
}

.basket__edit {
	padding: 15px;
}

.admin--apply {
	margin: 40px auto 0;
}

.admin--discount {
	label {
		color: $color-text;
		font-weight: 900;
	}

	input {
		margin-top: 15px !important;
	}

	.btn {
		margin-top: 15px;
	}

	.radio--admin {
		font-weight: normal;
		margin-top: 15px;
		display: block;


		span {
			display: inline-block;
			margin-left: 15px;
			font-size: 0.85em;

			i {
				margin-right: 5px;
				color: $color-primary;
			}
		}
	}
}


#collect {
	h2 {
		padding-left: 0;
		font-size: 1.4em;
	}

	.fa-map-marker-alt {
		color: $color-primary;
		font-size: 1.6em;
		margin: 30px 0 10px;
	}

	.address {
		color: $color-text;
	}
}

.collection {
	.fa-calendar-alt {
		color: $color-primary;
		font-size: 1.8em;
		margin-right: 0;
		text-align: center;
	}

	strong {
		color: $color-primary;
		text-transform: uppercase;
	}

	span {
		font-weight: 600;
		color: $color-text;
	}

	input {
		visibility: hidden;
		position: absolute;
	}
}

.collection__inner.selected {
	border: 1px solid $color-primary;
}

.collection__inner {
	background-color: $color-white;
	border: 1px solid $color-white;
	cursor: pointer;
	display: block;
	text-align: center;
	padding: 17px 2px;

	&:hover {
		border: 1px solid $color-primary;
	}

	input[type="radio"] {
		&:checked {
			border: 1px solid $color-primary;
		}
	}
}

.collection-slide {
	.fa-chevron-left {
		left: -25px !important;
	}

	.fa-chevron-right {
		right: -25px !important;
	}

	.bx-viewport {
		min-height: 115px;
	}
}

.combo--pack {
	margin: 0 !important;

	.combo__img {
		width: 50px !important;
		height: 50px;
		margin-bottom: 5px;
		margin-right: 8px;
        float:left;
	}

	span {
		padding-top: 0 !important;
		color: $color-bg-hero;
        float:left; 
        width:70%;
	}
}

.basket__combination {
	margin: 15px;

    @include media('<=phone') {
        margin: 15px 0;
    }
	h3 {
		color: $color-bg-hero;
		font-weight: 600;
		font-size: 1.1em;
		margin-bottom: 15px;

		&:before, &:after {
			border-top: none;
		}
	}

	.combo__img {
		width: 14%;
        @include media('<=phone') {
            width: 23%;
        }
		display: inline-block;
		vertical-align: middle;
        float:left;
		img {
			display: inline-block;
		}
	}

	span {
		font-weight: 500;
		font-size: 1em;
	}

	.step__package {
		cursor: inherit;
		border: none;

		.package {
			font-size: 0.9em;
			padding: 10px;
			max-width: 180px;
			float: left;
			margin-right: 15px;
		}

		span {
			padding-top: 13px;
            float:left;
		}
	}
}