﻿.pages {
    padding: 15px 0 50px;
}

.c-blue {
    color: #2f6ad0;
}

.c-orange {
    color: #f18c1a;
}

.borderline {
    border-bottom: 0.5px solid #1D4384;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.internal.privacy {
    .alice-blue {
        display: block;
        font-size: 1.3em;
        color: $color-bg-hero;
    }
}

.exampleonly {
    font-weight: bold;
    font-size: 0.8em;
}

.greybg {
    margin-top: 40px;
    padding: 40px 0;
    background-color: $color-grey-box;

    .pages__content h2 {
        margin: 0 0 25px 0;
    }

    .topsec {
        border-left: 5px solid #1D4384;
        padding: 0 0 0 15px;
        margin: 20px 0;
    }

    .whitebx {
        background-color: $color-white;
        text-align: center;
        padding: 20px 15px;
        margin-top: 30px;

        .info {
            font-size: 0.85em;
        }
    }
}

.pages__content {
    .section.split {
        .img-boot {
            img {
                filter: drop-shadow(0 0 1rem #00000029);
                max-width: 485px;
                width: 100%;
            }
        }

        @include media(">tablet") {
            position: relative;

            h2 {
                margin-top: 0px;
            }

            &.txt-right {
                .txt-boot {
                    position: absolute;
                    top: 50%;
                    right: 0px;
                    transform: translateY(-50%);

                    @include media(">desktop") {
                        width: calc(100% - 550px);
                    }

                    @include media("<=desktop", ">tablet") {
                        width: calc(100% - 415px);
                    }
                }

                .img-boot {
                    width: 41.66666666666667%;
                }
            }

            &.txt-left {
                .img-boot {
                    float: right;
                }

                .txt-boot {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: calc(100% - 550px);
                }
            }

            &.img-left {
                .txt-boot {
                    @include media(">desktop") {
                        width: calc(100% - 550px);
                    }

                    @include media("<=desktop", ">tablet") {
                        width: calc(100% - 415px);
                    }

                    float: right;
                }

                .img-boot {
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);

                    &.large {
                        left: -35px;
                        top: 53%;

                        @include media(">tablet") {
                            width: 50%;
                        }

                        img {
                            max-width: initial;
                        }
                    }
                }
            }

            &.img-right {
                .txt-boot {
                    @include media(">desktop") {
                        width: calc(100% - 550px);
                    }

                    @include media("<=desktop", ">tablet") {
                        width: calc(100% - 415px);
                    }

                    float: left;
                }

                .img-boot {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        @include media ("<=tablet") {
            .txt-boot {
                float: none;
                max-width: 480px;
                margin: 0 auto;
            }

            padding: 0px 15px;
        }
    }

    .greybox {
        padding: 30px;
        background-color: $color-grey-box;
        text-align: center;

        &.delpage {

            br {
                display: none;
            }

            .basket--sum, .basket--bold {
                font-size: 1.2em;
            }

            ul {
                padding: 0;
                margin: 0;
            }

            @include media('<=phone') {
                padding: 20px;

                .basket--bold {
                    font-size: 1.6em;
                }

                .dw-xs-6 {
                    max-width: 230px;
                    margin: 0 auto 10px auto;
                    float: none;
                }
            }
        }

        span {
            font-size: 1em;

            strong {
                color: $color-bg-hero;
            }
        }

        .btn {
            margin: 15px auto 0 auto;
            color: #fff;
            text-decoration: none;
            font-weight: normal;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 1.6em;
        margin: 15px 0;

        &:before, &:after {
            border-top: none;
        }
    }

    a {
        font-weight: 700;
        color: #2e6ad0;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            color: $color-orange;
        }
    }

    .dw-md-4:nth-child(2n+1) {
        @include media('<=tablet') {
            clear: both;
        }
    }

    img {
        margin: auto;

        @include media('<=tablet') {
            margin: 0 auto 15px;
        }
    }

    table {
        margin-top: 25px;
        width: 100%;

        th {
            text-align: left;
            color: $color-primary;
            font-weight: 500;
            background-color: $color-grey;
            padding: 5px 10px;
        }

        tbody {
            tr {
                border-bottom: 1px solid $color-grey;
                display: block;
                font-size: 0.85em;
                font-weight: 700;
            }

            .col--left {
                border-right: 1px solid $color-grey;
                width: 190px;
            }

            .col--right {
                padding: 8px 15px;

                span {
                    display: block;
                    font-weight: normal !important;
                }
            }
        }
    }

    .section.split {
        margin: 30px -15px 0 -15px;
    }
}

.pages__content.warranty {
    h2 {
        margin-top: 20px;
    }
}

.page--text {
    font-size: 1em;
    display: block;

    a {
        display: inline-block;
    }

    .quote .page--text {
        font-size: 0.85em;
    }
}

.email--text {
    font-size: 0.9em;
    display: block;

    a {
        display: inline-block;
    }
}

.cust---quotes {
    margin-bottom: 30px;
    margin-top: 20px;

    .quote {
        .tri {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 30px 0 0px;
            border-color: #1D4384 transparent transparent transparent;
            position: absolute;
            z-index: 9999;
            bottom: -30px;
            left: 15%;
            display: block;

            .inner {
                position: relative;
                top: -3px;
                left: 1px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 30px 30px 0 0px;
                border-color: #fff transparent transparent transparent;
            }
        }


        img {
            position: absolute;
            bottom: 10px;
            right: 10px;

            @include media('<=tablet') {
                bottom: 0;
            }
        }

        i {
            color: #F8D446;
        }

        box-shadow: 0px 3px 6px #00000012;
        border: 0.5px solid #1D4384;
        position: relative;
        padding: 10px 10px 30px 10px;
        border-radius: 6px;
        min-height: 300px;

        @include media('<=tablet') {
            min-height: 100px;
        }

        margin-bottom: 35px;

        .page--text {
            display: inline;
            font-size: 0.85em;
        }
    }

    .quote--name {
        @include media('<=tablet') {
            margin-bottom: 30px;
        }

        padding: 10px 0 0 60px;
        color: #333;
        text-transform: uppercase;
        position: relative;
        font-weight: bold;
        min-height: 42px;

        i {
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('../img/review-icon.png');
            width: 42px;
            height: 42px;
            display: block;
        }
    }
}


.page--top {
    margin-top: 40px;
}

.page--center {
    text-align: center;

    img {
        margin: 0 auto;
    }
}

.delivery-map {
    text-align: center;
    margin: 20px auto;
    display: block;
    color: $color-text;
    text-decoration: underline;
    font-weight: 500;

    i {
        margin-right: 15px;
        color: $color-primary;
        font-size: 1.1em;
    }

    &:hover {
        text-decoration: none;
    }
}

.page__background {
    margin: 25px 0 30px;
    background-color: $color-grey;
    padding: 40px;
    text-align: center;

    h2 {
        margin: 0;
    }

    span {
        text-align: center;
        max-width: 840px;
        margin: 25px auto 0;
    }
}

.page--line {
    border: 1px solid $color-grey;
    margin: 20px 0;
}

.page__splits {
    margin: 40px 0 0px;

    h2 {
        margin: 20px 0 15px;
    }

    img {
        margin: auto;
    }
}

.check__route {
    margin: 25px 0 50px;
}

.page--bottom {
    color: $color-bg-hero;
    font-weight: 500;
    padding: 15px;
    text-align: center;
    margin: 60px auto;
}

.page--centered-text {
    text-align: center;
    font-size: 0.85em;
    max-width: 900px;
    margin: auto;
    display: block;
}

.servicepage {
    .section {
        margin: 0 -8px;
    }

    .section .dw-md-4 {
        padding: 0 8px;
    }

    @include media('<tablet','>phone') {
        .section .dw-md-4:nth-child(3) {
            margin-top: 30px;
        }
    }

    @include media('<phone') {
        .section .dw-md-4 {
            margin-bottom: 20px;
        }

        .section {
            margin-top: -20px;
        }
    }

    .box {
        text-align: center;
        font-size: 0.85em;
        padding: 20px 0 0 0;
        border: 0.5px solid #707070;

        .title {
            color: #1d4384;
            font-size: 1.8em;
            font-style: normal;
            font-weight: 300;
            margin-bottom: 5px;
        }

        span.total {
            color: $color-orange;
            font-size: 2.5em;
            font-weight: bold;
        }

        .title, .text {
            padding: 0 25px;
        }

        .inner {
            padding: 0 15px;
        }

        .text {
            margin: 15px 0;
            min-height: 290px;

            @include media('<desktop','>tablet') {
                min-height: 350px;
            }

            @include media('<tablet','>phone') {
                min-height: 320px;
            }

            @include media('<phone') {
                min-height: 20px;
            }
        }

        .grey-bg {
            min-height: 500px;

            @include media('<desktop','>tablet') {
                min-height: 550px;
            }

            @include media('<phone') {
                min-height: 20px;
            }

            background-color: #eaeaea;
            padding: 15px;
            text-align: left;

            .bullet {
                position: relative;
                padding: 0 0 0 20px;
                margin-bottom: 10px;

                i {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    color: #1d4384;
                }
            }
        }
    }
}

.page--centered-text.bottom {
    margin-top: 50px;
}

.page__list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.page--list {
    padding: 0 30px;
    list-style-position: inside;
    max-width: 840px;
    margin: auto;

    li {
        text-align: center;
    }

    span {
        margin: 0 auto;
        display: inline-block;
    }
}

.product__thumbs {
    display: block;
    margin: 0 auto;
    max-width: 840px;
    padding: 0 !important;
    cursor: pointer;

    li {
        float: left;
        list-style: none;
        margin: 15px 15px 0 0;

        @include media('<=tablet') {
            width: 31.9%;
        }

        @include media('<=tablet') {
            width: 47.8%;
        }

        @include media('<=370px') {
            width: 47%;
        }

        &:hover {
            opacity: 0.9;
        }
    }

    li:nth-child(3n+3) {
        @include media('>=tablet') {
            margin-right: 0;
        }
    }

    li:nth-child(2n+2) {
        @include media('<=tablet') {
            margin-right: 0;
        }
    }
}



.energy-saving-list {

    & .mob-energy-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        margin-left: 0;
        margin-right: 0;
        margin-top: 25px;

        @include media('<=phone') {
            gap: 5px;
        }

        @include media('>tablet') {
            display: none;
        }
    }

    & .desktop-energy-list {
        @include media('<=tablet') {
            display: none;
        }
    }



    & h2 {
        font-size: 1.55em;

        @include media('<=phone') {
            margin: 10px 0;
            font-size: 1.25em;
        }
    }



    img {
        margin: unset;

        @include media('<=tablet') {
            margin: auto;
        }
    }

    & .flx-left {
        display: flex;
        justify-content: left;
    }

    & .flx-right {
        display: flex;
        justify-content: right;
    }

    @include media('<=phone') {
        padding-top: 0;
    }

}

.energy.internal {


    & .warranty__sub {
        font-size: 1.55em;
        margin-bottom: 0;

        @include media('<=phone') {
            font-size: 1.25em;
            text-align: left;
        }

        span {
            font-size: 0.73em;
            font-weight: 500;
            margin: 10px 0;
        }
    }

    & .faq--info {
        text-align: center;
    }

    & .page--text, .faq--info {
        font-size: 1.12em;


        @include media('<phone') {
            font-size: 0.88em;
            text-align: left;
        }

        strong {
            color: #1D4384;

            a {
                color: #1D4384;
            }
        }

        .bottom a {
            color: #1D4384;
        }
    }

    .blue-box {
        background-color: #def2fb;
        padding: 27px 0;

        span {
            font-size: 1.25em;

            @include media('<=phone') {
                font-size: 0.88em;
            }
        }
    }

    & .pages__content {
        .sub {
            font-size: 1.13em;

            @include media('<=phone') {
                font-size: 0.88em;
                text-align: left;
            }
        }

        h2.products--heading {
            font-weight: 600;
            font-size: 1.25em;

            @include media('<=phone') {
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }

    .bottom {
        font-size: 1.25em;

        @include media('<=phone') {
            font-size: 0.88em;
        }

        strong {
            color: #666666;

            a {
                color: #1D4384;
            }
        }
    }

    .carousel-container {
        background-color: #F5F5F5;
        margin: 30px 0;
        padding-bottom: 10px;

        @include media('<=phone') {
            margin-top: 20px;
            margin-bottom: 10px;
            padding-top: 10px;
        }
    }
}

.banner--energy {
    & .internal__sub {
        font-size: 1.12em;

        @include media('<=phone') {
            font-size: 0.88em;
        }
    }

    h1 { font-size: 1.88em; }
}