﻿.internal {
    ul {
        padding: 10px 20px;
        margin-bottom: 20px;

        strong, li {
            color: $color-primary;
        }

        span {
            color: $color-text;
        }
    }
    .pages{
        padding-bottom: 0px !important;

        .bottom{ 
            display:block; text-align:center; 
            strong{ color:$color-bg-hero; }
        }
    }
}

.internal.privacy {
    h3 {
        &:before, &:after {
            border-top: none;
        }
    }
}

.internal__banner {
    background-repeat: no-repeat;
    background-position: center;
    background-color: $color-bg-hero;
    height: 320px;
    position: relative;
    margin-bottom: 35px;
    background-size: cover !important;
    margin: 0 auto;

    @include media('<=phone') {
        background-position: 20% !important;
    }

    @include media("<=sm-phone") {
        min-height: 320px; 
        height:auto;
        padding: 30px 0;
        background-position: 100% !important;
    }

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media("<=tablet") {
            width: 100%;
        }

        @include media("<=sm-phone") {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    h1 {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        color: $color-white;
        margin-bottom: 10px;
        width: 100%;
        text-shadow: 0 1px 2px #000;
        &:before, &:after {
            border-top: 1px solid #fff;
        }
    }

    .internal__sub {
        color: $color-white;
        text-align: center;
        width: 100%;
        text-shadow: 1px 1px 2px #000;
    }
}

.review--banner {
    @include media('<=phone') {
        height: auto !important;
        background-size: cover !important;
        padding: 5px;
    }

    .internal__sub, h1 {
        @include media('<=phone') {
            position: relative;
            top: auto;
            left: auto;
            margin-top: 0;
            transform: none;
        }
    }

    h1 {
         @include media('>=phone') {
        top: 40%;
        }
    }
}

.banner--contact {
    background-image: url('../img/banners/prem-hot-tubs-contact.jpg');
    background-position:50%;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/contact-us.jpg');
    }
}
.banner--about {
    background-image: url('../img/banners/prem-hot-tubs-about.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/about-us.jpg');
    }
}

.banner--review {
    background-image: url('../img/banners/happy-hot-tub-reviews.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/happy-hot-tub-reviews-mobile.jpg');
    }
}

.banner--gallery {
    background-image: url('../img/banners/prem-hot-tubs-gallery.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/gallery.jpg');
    }
}

.banner--warranty {
    background-image: url('../img/banners/banner-warranty.jpg');
    background-position:70%;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/warranty.jpg');
    }
}

.banner--guide {
    background-image: url('../img/banners/buyers-guide.jpg');
}

.banner--generic {
    background-image: url('../img/banners/prem-hot-tubs.jpg');
}

.banner--delivery {
    background-image: url('../img/banners/prem-hot-tubs--delivery.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/delivery.jpg');
        background-position:left !important;
    }
}

.banner--benefits {
    background-image: url('../img/banners/prem-hot-tubs--benefits.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/benefits.jpg');
    }
}

.banner--music {
    background-image: url('../img/banners/tv-bann.jpg'); background-position: top;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/smart-tv.jpg');
    }
}
.banner--jets {
    background-image: url('../img/banners/jets-banner.jpg');  background-position: top;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/jets.jpg');
    }
}

.banner--manuals {
    background-image: url('../img/banners/prem-hot-tubs-manual.jpg');
     @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/manuals-and-videos.jpg');
    }
}

.banner--service {
    background-image: url('../img/banners/prem-hot-tubs-servicing.jpg');
     @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/service.jpg');
    }
}

.banner--payment {
    background-image: url('../img/banners/prem-hot-tubs--payment.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/payment-options.jpg');
    }
}

.banner--energy {
    background-image: url('../img/banners/prem-hot-tubs-energy.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/energy-efficient.jpg');
    }
}

.banner--maintenance {
    background-image: url('../img/banners/prem-hot-tubs-maintenance.jpg');
    background-position:70%;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/maintenance.jpg');
    }
}

.banner--lighting {
    background-image: url('../img/banners/prem-hot-tubs-lighting.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/lighting.jpg');
    }
}

.banner--water {
    background-image: url('../img/banners/prem-hot-tubs-water.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/water-features.jpg');
    }
}

.banner--faqs {
    background-image: url('../img/banners/banner-faq.jpg');
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/faq-mobile-banner.jpg');

    }
}

.banner--finance {
    background-image: url('../img/banners/prem-hot-tubs-finance.jpg');
    background-position:70%;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/finance.jpg');
        background-position: 10% !important;
    }
}

.banner--support {
    background-image: url('../img/banners/prem-hot-tubs-support.jpg');
    background-position:50%;
    @include media('<=sm-phone') {
        background-image: url('../img/banners/mobile/support.jpg');
    }
}


.internal--sub {
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    color: $color-text;
    margin: 50px auto 20px;
}

.internal__chapters--items.active {
    color: $color-primary;
    text-decoration: underline;
}

.internal__chapters {
    margin: 170px auto;
    color: $color-text;

    p {
        margin: 15px 0;
    }

    h3 {
        font-size: 1.6em;
        font-weight: 400;
    }
}

.internal__chapters--side {
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    @include media('<=phone') {
        position: static;
    }
}

.chapter--selected {
    color: $color-primary;
    font-weight: 600;
    text-decoration: underline;
}

.internal__chapters--list {
    background-color: $color-bg-hero;
    padding: 20px;

    @include media('<=tablet') {
        margin-top: 30px;
    }

    li {
        list-style: none;
        margin: 15px auto;
        cursor: pointer;
    }
}
.internal__chapter--post {
    display: none;
}

.internal--line {
    border: 1px solid $color-search;
    margin: 30px auto;
}

.internal__content {
    padding: 0 80px;

    @include media('<=phone') {
        padding: 0 20px;
    }

    h1 {
        text-align: center;
        font-weight: 300;
        margin-bottom: 20px;
    }

    h2 {
        text-align: left;

        &:before, &:after {
            border-top: none;
        }
    }

    h3 {
        font-size: 1.5em;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        color: $color-bg-hero;

        &:before, &:after {
            border-top: none;
        }
    }

    .btn {
        display: block;
        margin: 30px auto;

        span {
            font-weight: 500;
            margin-left: 5px;
        }
    }
}

.internal--body {
    display: block;
    margin: 20px 0;
    font-weight: 300;
    color: $color-text;

    p {
        margin: 10px 0;
    }

    ul {
        padding: 10px 20px;

        strong {
            color: $color-primary;
        }
    }

    a {
        color: $color-primary;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            color: $color-orange;
            text-decoration: none;
        }
    }

   
}

.internal--center {
    text-align: center !important;
    color: $color-primary !important;
}

.internal--primary {
    color: $color-primary;
    font-weight: 700;
    text-decoration: underline;
}

.internal--ordered-list {
    margin-top:20px;
    padding: 0 25px;
    font-size: 0.9em;

    ol li, ul li {
        color: $color-primary;
        font-weight: 700;

        span {
            color: $color-text;
            font-weight: 300;
        }
    }
}

.testimonials {
    background-color: $color-bg-hero;
    border: 1px solid $color-light-grey;
    padding: 35px;
    margin-bottom: 25px;

    img {
        margin-bottom: 20px;
    }
}

.testimonials--quote {
    font-style: italic;
    display: block;
    font-weight: 400;
}

.testimonials--name {
    color: $color-primary;
    font-weight: 700;
    margin:10px 0;
    display: block;
}

