/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */

.owl-item {
    .related--prod {
        width:100%!important;
        margin:0!important;
        float:none!important;
    }
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
.owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    outline: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    z-index: 9999;
    font-size: 1.8em;
    color: #1d4384;
    background-color: #fff !important;
    border-radius: 100%;
    box-shadow: 0 0 9px 0 rgba(0,0,0,.35);
    -webkit-box-shadow: 0 0 9px 0 rgba(0,0,0,.35);
    -moz-box-shadow: 0 0 9px 0 rgba(0,0,0,.35);
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        color: #1d4384;
        font-size: 1.3em;
    }

    i:hover {
        color: #ff8d00;
    }
}
.owl-theme .owl-nav .owl-next {
    right: -10px;

    @media screen and (max-width: 582px) {
        right: 0;
    }
}

.owl-theme .owl-nav .owl-prev {
    left: -10px;

    @media screen and (max-width: 582px) {
        left: 0;
    }
}
.owl-theme .owl-nav [class*='owl-']:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none;
}
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }
