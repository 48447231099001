﻿

.account {
	margin-bottom: 40px;

	.account__content {
		background-color: $color-grey;
		border-radius: 3px;
		padding: 25px;
		min-height: 580px;
		margin-bottom: 20px;
		box-shadow: 0px 3px 6px #00000029;

		&.nopadd{padding: 0px;}

		.editor-label {
			margin: 10px 0;
			color: $color-text;
		}

		.regicon {
			position: relative;
			padding: 10px 0 0 70px;
			text-align: left;
			min-height: 60px;
			font-size: 0.85em;

			i {
				position: absolute;
				background-color: $color-ligh-blue;
				color: $color-white;
				height: 50px;
				line-height: 50px;
				width: 50px;
				text-align: center;
				left: 0;
				border-radius: 100%;
				top: 0;
				font-size: 1.4em;
			}
		}
	}



	h2 {
		margin: 15px 0;

		&:before, &:after {
			border-top: none;
		}
	}
}

.account.empty--basket {
	margin-bottom: 0px;
	h2 {
		text-align: center;
	}
    .account__content{ min-height:initial; }
	.section {
		max-width: 450px;
		margin: auto;
	}
}

.account__address {
	border-radius: 2px;
	background-color: $color-grey;
	border: 1px solid $color-light-grey;

	@include media('>phone') {
		padding: 30px;
		margin: 30px 0;
	}
}

.account__console {
	background-color: $color-white;
	border: 1px solid $color-light-grey;
	border-radius: 2px;
	padding: 20px;
	margin: 10px 0;
	text-align: center;

	i {
		display: block;
		font-size: 2em;
		margin-bottom: 10px;
	}

	strong {
		text-transform: uppercase;
		color: $color-text;
	}
}

.account-menu {
	.section {
		@include media('<tablet','>phone') {
			.dw-lg-4:nth-child(3n+1) {
				clear: both;
			}
		}

		@include media('<=phone') {
			.dw-lg-4:nth-child(2n+1) {
				clear: both;
			}
		}
	}
}

.register--account {

	.express-checkout-block {
		background-color: $color-grey;
		border: 1px solid $color-light-grey;
		border-radius: 2px;
		padding: 25px;
		display: grid;
		align-items: center;
		grid-template: "icon text button" /.5fr 3fr 3fr;


		@include media('<=phone') {
			display: block;

			div {
				h2 {
					text-align: center !important;
					margin: 15px 0 !important;
				}

				span {
					text-align: center !important;
				}
			}
		}

		i {
			grid-area: icon;
		}

		div {
			grid-area: text;

			h2 {
				margin: 0 0 0 15px;
				text-align: left;
			}

			span {
				text-align: start;
				margin: 0 0 0 15px;
			}
		}

		input {
			grid-area: button;
		}
	}

	i.fa-pen-alt, i.fa-user-circle, i.fa-shopping-cart {
		font-size: 3em;
		color: $color-primary;
		display: block;
		margin-bottom: 15px;
		text-align: center;
	}

	i.fa-shopping-cart {
		margin-top: 18px;
	}

	h1 {
		text-transform: capitalize;
	}

	.findAddress, .findAddressSingle {
		margin: 0 !important;
	}

	ul {
		padding: 0 30px;
		list-style-position: inside;

		li {
			color: $color-primary;

			span {
				color: $color-text;
			}
		}
	}

	.reg--acc {
		text-align: center;
	}

	.btn, .loading-help .loading-special {
		height: 55px;
		line-height: 55px;
		margin: 15px 0px;
		border-radius: 3px;
	}
}

.reg--account {

}

.o-em {
	background-color: $color-white;
	margin: auto;
	max-width: 800px;
	padding: 15px;
	color: $color-text;
}

.account--why {
	margin: 40px auto;
	text-align: center;
    @include media('<=phone') {
        margin: 20px auto 0 auto;
    }
    .section{
        margin:0 -10px;
        .dw-lg-4{ padding:0 10px; 
            @media screen and (max-width:500px) {
                width:100%;
            }

        }

    }
}

.forgotten {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.account--texts {
	color: $color-text;
	@include primary-font-stack;
	font-size: 0.85em;
	text-align: center;
	display: block;
}

.account__background {
	background-color: $color-white;
	padding: 15px;
	margin-bottom: 15px;

	.btn {
		max-width: 240px;
	}
}

.account__background.default {
	border: 2px solid $color-primary;
	padding: 13px;
}

.btn--back {
	@include media('>phone') {
		width: 25%;
		float: left;
	}
}

.btn--right {
	float: right;

	@include media('>=tablet') {
		width: 25%;
	}
}



.account__order-history {
	margin-top: 30px;
}

.account__orders {
	margin-top: 30px;
	line-height: 20px;
}

.account__orders--row.orders .row:first-child {
	font-weight: 700;
	border-top: 0px;
} 

.account__orders--row {
	border-top: 1px solid #d7d7d7;
	font-weight: 700;
}

.account__orders--main {
	color: $color-primary;
	font-size: 1.3em;
	text-align: center;
}


.account__orders--row > div {
	float: left;
	padding: 5px 10px;
}

.account__orders--details {
	@include media('>tablet') {
		width: 32%;
	}

	@include media('<=tablet', '>phone' ) {
		width: 40%;
	}

	strong {
		color: $color-purple;
	}

	span {
		color: $color-primary;
	}
}

.account__orders--products {
	position: relative;
	padding-right: 50px;

	@include media('>tablet') {
		width: 41%;
	}

	@include media('<=tablet', '>phone' ) {
		width: 26%;
	}

	.dw-xs-9 {
		margin-bottom: 10px;
	}

	.dw-xs-3 {
		i {
			text-align: center;
			color: $color-purple;
			display: block;
		}
	}

	strong {
		color: $color-purple;
	}

	a {
		&:hover strong {
			color: $color-primary;
		}
	}
}

.account__orders--cost {
	@include media('>tablet') {
		width: 27%;
	}

	@include media('<=tablet', '>phone' ) {
		width: 34%;
	}

	strong {
		color: $color-purple;
	}

	span {
		color: $color-primary;
	}
}

.eye--icon {
	position: relative;

	.forgot {
		color: $color-primary;
		position: absolute;
		right: 10px;
		top: 0px;
		cursor: pointer;
		height: 55px;
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			color: $color-bg-hero;
		}
	}
}



#loginform {
	width: 100%;
	margin: 0 auto;
	padding: 0 0px 15px;
	text-align: center;

	
}

#loginform .editor-label {
	text-align: left;
}

#loginform .editor-label label {
	color: #1d4384;
	font-size: 1.15em;
}


#loginform .editor-field {
	margin-bottom: 10px;
	position: relative;
}

#loginform .editor-field input[type="text"], #loginform .editor-field input[type="password"] {
	border: 1px solid #1d4384;
}

#loginform .editor-field.submit {
	margin-top: 20px;
}

#loginform a.sign-amb {
	display: inline-block;
	margin: 10px auto 0;
	text-align: center;
	font-size: 0.85em;
	font-style: italic;
}

#loginform a.sign-amb:hover {
	color: #59341a;
}

.account.other h2 {
	font-size: 1.3em;
	text-align: left;
}

.non-login .form-surround {
	max-width: 445px;
	margin: 0 auto;
}

.non-login .form-surround .btn {
	margin-top: 15px;
	margin-bottom: 25px;
}

.account .btn.back:hover {
	background-color: #fff;
	color: #777;
}



.account .login .specialhead .center-head {
	background-color: transparent;
}


.admin-link {
	display: flex;
	min-height: 130px;
	background-color: #fff;
	border-radius: 3px;
	padding: 15px;
	border: 1px solid #d7d7d7;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	text-decoration: none !important;
	text-align: center;
	margin-bottom: 30px;
	i {font-size: 2em;}

	strong {display: block; width: 100%; margin-top: 5px; text-decoration: none; text-align: center;}
}

.showroom-li {
	padding: 15px;
	background-color: #f9f9f9;
	display: flex;
	margin-bottom: 15px;
	border-radius: 3px;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 0px 10px #0000001F;

	.name{
		font-size: 1.3em;
		font-weight: 600;
		color: #1d4384;
	}

	.delete {
		color: #db2727;
		cursor: pointer;
	}
}

.rel .forgot.eye {cursor: pointer; position: absolute; right: 0px; top: 0px; height: 55px; display: flex; align-items: center; color: #1d4384;width: 55px; justify-content: center;}
.rel .forgot.eye:hover {
	color: #fd8c00;

} 