﻿@import 'blocks/steps';

.checkout {
    border: 1px solid $color-line;
    border-radius: 2px;
    max-width: 1300px;
    margin-bottom: 30px;

    h2 {
        padding: 15px 25px;
        display: inline-block;

        @include media('<=phone') {
            font-size: 1.4em;
        }

        &:before, &:after {
            border-top: none;
        }
    }

    i {
        color: $color-text;
        margin-right: 15px;
    }

    .editor-field {
        margin: 10px 0;
    }

    .validation-summary-errors {
        display: block;
        padding: 0 30px;
        column-count: 2;
    }

    .step__payment {
        @include media('>=tablet') {
            min-height: 460px;
        }
    }

    #discountform.checkout-discount {
        padding: 0 20px;

        .section {
            display: flex;
            align-items: baseline;
        }
    }

    #checkoutform {

        #contactform {
            @media (max-width: 767px) {
                &>br:not(:last-of-type) { display: none; }

                #deliveryAddress {
                    margin-top: 5px;
                }

                .checkout__area {


                    .section.pay-types {
                        margin-bottom: 10px;
                        &>br { display: none; }
                    }

                }

            }
        }
    }
}

.checkout--form {
    display: flex;
    justify-content: center;
}

.checkout__line {
    border-top: 1px solid $color-light-grey;
    margin-top: 10px;

    @media (max-width: 767px) {
        margin-top: unset;
    }

}

.checkout__area {

    .KO.orange {
        background-color: red;

        i {
            color: #fff;
            font-size: 1.4em;
        }
    }

    input.check-box {
        width: 25px;
        height: 25px;
        float: left;
        margin-right: 10px;
        margin-bottom: 5px;
    }


    select.clearborder, select.valid {
        border: 1px solid #dfdfdf;
    }

    select {
        color: #666;
        /*border: 1px solid red;*/
    }

    .agree {
        color: $color-text;
        margin-bottom: 15px;

        a {
            font-weight: 600;
            text-decoration: underline;
        }

        @include media('<=phone') {
            font-size: 0.75em;
        }
    }

    #map {
        width: 100%;
        height: 210px;
        float: right;
        margin-top: 15px;
    }

    .btn {
        margin-top: 0;
    }

    .btn.selected {
        background-color: $color-primary;
    }

    @media (max-width: 767px) {
        &>br { display: none; }
    }
}

.btn.paypal {
    background-color: $color-yellow;
    border: 1px solid $color-yellow;
    margin-bottom: 10px;

    img {
        margin: auto;
    }

    &:hover {
        background-color: $color-checkout;
        border: 1px solid $color-checkout;
    }
}

.pay-types {
    .btn {
        margin: 0;

        @include media('<=phone') {
            margin-bottom: 10px;
        }
    }
}

.feat__popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &.open{
        opacity: 1;
        visibility:visible;
    }

    .overlaypop {
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
    }

    h2 {
        text-align: center;
        margin: 0 auto 20px auto;
        color: $color-bg-hero;
        padding:0;
        display:block;
    }

    .view {
        position: relative;
        width: 95%;
        max-width: 800px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%, -50%);
        max-height: 90%;
        background-color: #f0f0f0;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.8);

        @include media('<=phone') {
            height: auto;
            width: 90%;
        }
    }

    .surround {
        transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -webkit-transition: opacity 0.2s ease-in-out;
        opacity: 0;
        visibility: hidden;
        margin: auto;
    }

    .features-text {
        padding: 35px;
        @media screen and (max-width:500px) {
            padding:15px 10px;
        }
    }

    #popupcontent{
        padding-bottom:60px;
        position:relative;
        height: 100%;
        overflow-y: auto;
    }

    .surround.open {
        opacity: 1;
        visibility: visible;
    }


    .close {
        font-size: 1em;
        display: block;
        position: absolute;
        top: -20px;
        right: -10px;
        cursor: pointer;
        z-index: 999;

        .fa, .fas {
            font-size: 1.3em;
            text-align:center;
            background-color: $color-primary;
            color:$color-white;
            border-radius:100%;
            height: 35px;
            width: 35px;
            line-height: 35px;
            float: left;
            &:hover{
                background-color: $color-orange;
            }
        }

        .text {
            line-height: 30px;
            float: right;
            margin-left: 10px;
            float: left;
            display:none;
        }
    }

    .close.back {
        display:none;
        right: auto;
        left: 0px;
    }
}

.checkout__addresses {
    background-color: $color-white;
    border: 1px solid $color-light-grey;
    padding: 15px;

    strong {
        display: block;
        margin-bottom: 15px;
        color: $color-primary;
    }

    label {
        color: $color-text;
    }
}

#adddelivery {
    color: $color-primary;
    font-weight: 700;
    font-size: 1.2em;
    padding: 0 35px;
    text-decoration: underline;
    cursor: pointer;
}

.sab {
    
}

.checkout__same-as--delivery {
    margin-bottom: 20px;
    float: right;
    margin-top: 15px;
    font-size: 0.9em;
    color: $color-text;
    line-height: 25px;

    @include media('<=phone') {
		margin-bottom: 0px;
        float:left;
	}

    span {
        margin-top: 2px;
        color: #333;
        line-height: 40px;
        float: left;
        display: block;
        @include media('<=phone') {
           padding: 0 10px;
           float:none;
           line-height:initial;
        }
    }

    input {
        width: 21px;
        height: 21px;
        border: 1px solid #d6d6d6;
        margin: 11px 10px;
        float: left;
    }

    div.select-wrapper {
        @media (max-width: 767px) {
            display: flex;
            align-items: center;
            padding: 10px;

            &>input[type=checkbox] {
                margin-right: 20px;
            }

        }
    }

}

#billingAddress.shut {
    height: 0;
    overflow: hidden;
}

#deliveryaddress.shut, #newaddress.shut {
    height: 0;
    overflow: hidden;
}

#delivery {
    margin-top: 35px;

    @media (max-width: 767px) {
        margin-top: 10px;
    }

}



#delivery-options {
    .delivery-opt {
        padding: 15px 115px 15px 44px;
        background-color: $color-white;
        border: 1px solid $color-light-grey;
        position: relative;
        display: block;
        margin-top: 15px;
        position:relative;
        i{
            position: absolute;
            cursor:pointer;
            width: 25px;
            height: 25px;
            line-height: 25px;
            display: block;
            top: 12px;
            text-align:center;
            left: -35px;
            transform: translate(50%,-50%);
            background-color: #2e6ad0;
            color: #fff;
            border-radius: 100%;
            font-size:0.8em;
            @include media('<=phone') {
                left:-50px;
            }
            &:hover{ background-color:$color-orange; }
        }
    }

    .delivery-opt.selected {
        border: 1px solid $color-primary;
    }

    .rb {
        position: absolute;
        left: 15px;
        top: 50%;
        -ms-transform: translate(0,-50%);
        -o-transform: translate(0,-50%);
        -moz-transform: translate(0,-50%);
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        width: 14px;
        height: 40px;
    }

    input {
        margin: 13px 0;
        width: 14px;
        height: 14px;
    }

    strong {
        color: $color-text;
    }

    span {
        color: $color-text;
    }

    span.price {
        color: $color-primary;
        font-weight: 700;
        position: absolute;
        right: 15px;
        top: 50%;
        -ms-transform: translate(0,-50%);
        -o-transform: translate(0,-50%);
        -moz-transform: translate(0,-50%);
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        line-height: 40px;
        width: 85px;
        font-size: 1.2em;
    }
}

.btn--paypal {
    background-color: $color-paypal;
    height: 40px;
    line-height: 20px;
    border: 1px solid $color-paypal;
    font-weight: 700;
    position: relative;
    margin-top: 14px;

    img {
        margin: 4px auto;
    }

    &:hover {
        opacity: 0.8;
    }
}



.required {
    color: $color-primary;
}

@media print {
    .basket__heading, #contactform, .breadcrumb, .hero__top, footer, .basket__restriction, .btn, .col-left, .col-right {
        display: none !important;
    }

    .total--checkout-print {
        display: block!important;
    }

    .basket__column img {
        height: 80px;
        width: 80px;
    }
    .basket__column--one {
        width: 55%;
        float: left;
    }
    .basket__column--three.check {
        width: 20%;
        float: right;
    }
    .basket__column--two.check {
        width: 25%;
        float: right;
    }
    .basket__row {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.checkkout--nav {
    @include media('<=tablet') {
        .accounts {
            display: flex;
            justify-content: flex-end;
        }
    }
}