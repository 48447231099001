﻿.useful__links {
	margin-bottom: 25px;

	.btn--blue {
		max-width: 210px;
        @media screen and (max-width:420px) {
            max-width:100%;
            }
	}

	@include media('<=tablet') {
		margin-bottom:15px;
	}
}

.use--gal {
	margin-bottom: 25px;

	.dw-md-7 {
		@include media('>tablet') {
			width: 57.7%;
			padding-right: 0;
		}
	}

	.dw-md-5 {
		@include media('>tablet') {
			width: 42.3%;
			padding-left: 0;
		}
	}

	img {
		@include media('<=tablet') {
			width: 100%;
		}
	}
}

.use--links {
	.dw-sm-4 {
		padding: 0 12.5px;
	}
}

.useful__links--image {
	position: relative;
	width: 100%;
	min-height: 310px;
	max-width:390px;
	margin: auto;
}

.useful__links--bg {
	background-color: $color-bg-hero;
	min-height: 300px;

	@include media('<desktop','>tablet') {
		min-height: 235px;
	}

	.useful__links--text {
		padding: 0 70px 0 40px;
         @media screen and (max-width:420px) {
             padding: 0 40px 0 40px;
            }
	}

	@include media('<desktop','>tablet') {
		.useful__links--title {
			font-size: 1.4em;
		}
	}
}

.useful__links--image--one {
	background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/accessories.jpg') no-repeat center;
}

.useful__links--image--two {
	background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/buyers-guide.jpg') no-repeat center;
    @include media('<=phone') {
		 margin: 30px auto;
	}
   
}

.useful__links--image--three {
	background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/delivery.jpg') no-repeat center;
}

.useful__links--link {
	.btn{border: none;}
	&:hover {
		.useful__links--bg {
			btn {
				background-color: #2e6ad0;
			}
		}
		.useful__links--image--one {
			.btn {
				background-color: #2e6ad0;
			}
		}
		.useful__links--image--two {
			.btn {
				background-color: #2e6ad0;
			}
		}
		.useful__links--image--three {
			.btn {
				background-color: #2e6ad0;
			}
		}
	}
}

.useful__links--text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: $color-white;
	width: 100%;
	padding: 0 40px;
	margin-top: 10px;
    @include media('<=phone') {
        padding: 0 20px;
    }
}

.useful__links--top {
	display: block;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 20px;
	font-size: 0.85em;
}

.useful__links--title {
	font-size: 1.8em;
	font-weight: 600;
	max-width: 310px;

	@include media('<=tablet') {
		font-size: 1.4em;
	}
}