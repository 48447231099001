﻿﻿ /**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
	position: relative;
	padding: 0;
	*zoom: 1;
}


/** THEME
===================================*/

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	right: 45%;
	bottom: -50%;
	z-index: 999;
	margin: auto;
	display: block;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(../img/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}


.bx-wrapper .bx-pager.bx-default-pager a {
	background: #9fcee6;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	outline: 0;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	text-indent: -9999px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #3a468a;
}

.bx-wrapper .bx-next {
	right: 0px;
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}

.homeslide .bx-wrapper .bx-controls-direction a {
	display: none;
}

.bx-wrapper img {
	display: block;
	margin: auto;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	outline: 0;
	width: 35px;
	height: 35px;
	line-height: 35px;
	z-index: 9999;
	font-size: 1.4em;
	color: $color-bg-hero;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
    background-color:#fff;
    border-radius:100%;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35)
     
}

.rightslider .bx-wrapper .bx-controls-direction a {
	top: 50%;
	color: #fff;
	font-size: 4em;
}

.bx-wrapper .bx-controls-direction a {
    &:hover{
        color:$color-orange;
    }
}

.bx-wrapper .bx-controls-direction a.fa-chevron-left {
	left: 0;
    @include media('>tablet') {
        left: 10px;
     }
    &::before{
        margin-left:-2px;
    }
}

.bx-wrapper .bx-controls-direction a.fa-chevron-right {
	right: 5px;
    @include media('>tablet') {
        right: 10px;
     }
    &::before{
        margin-left:2px;
    }
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}
