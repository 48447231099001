﻿﻿﻿.hero {
	position: relative;
	z-index: 90;
	&:hover{
		z-index: 9999;
	}
}

.hero__logged-in {
	background-color: $color-light-pink;
	text-align: center;
	color: $color-primary;
	font-size: 0.9em;
	padding: 5px;
	font-weight: 600;
    display:none;
}

.hero__top {
	color: $color-white;
	background-color: $color-bg-hero;
	display: block;

	.flex {
		margin-top:5px;
		display: flex;
		align-items: center;
		gap: 15px;
		justify-content: space-between;

		.contactlink {
			color: $color-primary;
			text-decoration: none;
			background-color: $color-white;
			padding: 5px 10px;
			height: 41px;
			display: inline-block;
			width: auto;
			font-weight: 500;
			line-height: 32px;
			font-size: 0.85em;
			border-radius: 3px 3px 0px 0px;
		}
	}

	.dw-xs-2 {
		@include media('>tablet') {
			width: 20.6%;
		}

		@include media('<desktop','>tablet') {
			width: 21%;
		}

		@include media('<tablet') {
			width: 17%;
		}

		@include media('<phone') {
			width: 130px;
		}



		.fa-twitter {
			margin-left: 50%;
		}

		.contactlink:hover {
			text-decoration: underline;
		}
	}

	.dw-xs-3 {
		@include media('<desktop','>tablet') {
			width: 23% !important;
		}

		@include media('>tablet') {
			width: 20%;
		}

		@include media('<tablet') {
			width: 27%;
		}

		@include media('<phone') {
			display: none;
		}
	}

	.dw-xs-8 {
		@include media('<desktop','>tablet') {
			width: 55.4% !important;
		}

		@include media('>tablet') {
			width: 79.4%;
		}

		@include media('<tablet') {
			width: 83%;
			padding: 0;
		}

		@include media('<=phone') {
			width: 70%;
			float: right;
			padding-right: 10px;
		}

		@media screen and (max-width:420px) {
			width: 65%;
		}

		@media screen and (max-width:330px) {
			width: 60%;
		}
	}
}

.hero__social {
	display:flex;
	align-items:center;
	grid-gap:10px;
    position:relative;
    a.fab {
	    @include media('<=desktop') {
		    display: none;
	    }
    }
}

.hero--icons {
	color: $color-white;
	text-decoration: none;

	&:hover {
		color: $color-ligh-blue;
	}
}

.hero__text {
	text-align: right;
	font-size: 1.1em;

	a {
		color: $color-orange;
		font-weight: 700;
		text-decoration: none;
	}

	span {
		font-weight: 700;
	}

	@include media('>tablet') {
		.tabdown-txt {
			display: none;
		}
	}

	@include media('<=tablet') {
		.tabup-txt {
			display: none;
		}

		i {
			transform: scaleX(-1);
			color: #ff8d00;
		}

		font-size: 0.9em;
		text-align: right;
	}

	@include media('<=phone') {
		font-size: 1.1em;
	}
}

.hero__link {
	text-align: right;
    margin-top: 5px;
	a {
		color: $color-ligh-blue;
		font-weight: bold;
		margin-left: 5px;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	@include media('<tablet') {
		font-size: 0.9em;
	}

	@include media('<=phone') {
		display: none;
	}

}

.hero__logo {
	margin: 10px 0;
	padding-left: 0;
	/*	@include media('<desktop','>tablet') {
		width: 27.6% !important;
	}

	@include media('<=tablet','>phone') {
		width: 35%;
	}
*/
	@include media('>tablet') {
		width: 180px;
	}

	@include media('<=tablet') {
		text-align: center;
		padding-left: 15px;
		margin-bottom: 0px;
	}
}


a.fadeout {
	opacity: 0.3;
	cursor: initial;

	&:hover span {
		color: $color-text;
	}
}

.hero--link {
	color: #818285;

	&:hover {
		color: $color-primary;
	}
}

.hero__accounts {
	margin-top: 10px;
	float: right;
	font-size: 0.85em;

	@include media('>=desktop') {
		width: 1000px;
		padding-left: 0;
	}

	@include media('>tablet') {
		width: 360px;
		margin-top: 20px;
		float: right;
		.section{
			margin: 0 -10px;
			.bs{
				padding: 0px 10px; 
			}

		}
	}

	@include media('<=tablet') {
		position: static;
		margin-top: 35px;
	}

	@include media('<=phone') {
		margin-top: 30px;
	}
}

.account--widgets, .account--cart {
	a {
		text-decoration: none;
		font-weight: 500;
	}
	i{
		vertical-align: middle;
	}
}

.account--widgets, .account--cart {
	float: left;
	text-align: center;
	position: relative;
	display: block;
	width: 100%;

	@include media('<=phone') {
		font-size: 0.9em;
		margin-left: 0;
		display: block;
		.account--text {font-size: 0.8em;}
	}
}

.account--widgets.browse {
	@include media('<=tablet') {
		width: 33%;
		float: right;
	}

	@include media('<=phone') {
		width: 47%;
	}

	.account--text {
		margin: 8px auto 0;
	}
}

.accounts__widget {
	@include media('>=tablet') {
		background-color: $color-grey;
		padding: 15px 0 0;
		height: 90px;
		border-radius: 3px;
	}
}

.account--total {
	font-size: 1.1em;
	display: inline-block;
	color: $color-text;

	@include media('<=phone') {
		display: none;
	}

	span {
		color: $color-bg-hero;
		font-weight: 800;
	}
}

.account--text {
	font-size: 1.2em;
	display: inline-block;
	color: $color-text;
	margin-left: 15px;

	@include media('<desktop','>tablet') {
		font-size: 1em;
	}

	@include media('<=tablet') {
		font-weight: 700;
		margin-top: 0 !important;
	}

	@include media('<=phone') {
		
		margin-left: 0;
		display: block;
	}
}

.hero--user {

	&:hover {
		.hero__account {
			visibility: visible;
		}
	}
}


.account--small {
	color: $color-text;
	display: block;

	a {
		color: $color-text;

		&:hover {
			color: $color-primary;
		}

		span {
			color: $color-ligh-blue;
		}

		@include media('<=phone') {
			display: none;
		}

		@include media('<=320px') {
			font-size: .5em;
		}
	}
}
#noofitems {
	margin-right: 5px;
	display: block;
	position: absolute;
	top: -5px;
	left: 30%;
	background-color: $color-ligh-blue;
	color: $color-white;
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 50%;
	font-size: 0.8em;
	font-weight: 700;

	@include media('<=phone') {
		left: 33px;
	}
}
.account--icons {
	display: block;
	text-align: center;
	font-size: 2.4em;
	margin: 5px 0;
	color: $color-bg-hero;
	min-height: 30px;
}
.hero__account {
	visibility: hidden;
	border: 1px solid $color-search;
	background-color: $color-white;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
	padding: 15px;
	position: absolute;
	left: 18px;

	@include media('<desktop','>tablet') {
		left: 0px;
	}

	a {
		margin-bottom: 5px;
		display: block;
	}
}
.show--items {
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	top: -12px;
	right: 7px;
	background-color: $color-light-text;
	border-radius: 50%;
	color: $color-light;
	font-size: .9em;
	line-height: 20px;
}

.hero__company [type="text"] {
	border: 1px solid $color-search;
	height: 48px;
	outline: none;
	padding-right: 50px;
	font-size: 0.9em;

	
	@include primary-font-stack;
}

.hero--magnify {
	background-color: $color-white;
	color: $color-bg-hero;
	outline: none;
	border: 0;
	position: absolute;
	right: 0;
	height: 48px;
	line-height: 48px;
	width: 50px;
	font-size: 1.3em;
	cursor: pointer;
	border-radius: 0px 3px 3px 0px;
	border-top: 1px solid $color-search;
	border-bottom: 1px solid $color-search;
	border-right: 1px solid $color-search;
    top:0px;
	@include media('<=tablet'){
		right: 15px;
        top:15px;
	}
}

.account--cart {
	&:hover .basket__items, &:hover .triangle {
		visibility: visible;
		display: block;

		@include media('<=phone') {
			visibility: hidden;
			display: none;
		}
	}

	@include media('<=tablet') {
		float: left;
		width: 52%;
	}

	@include media('<=phone') {
		width: 25%;
	}
}

.basket-hover {position: relative;}

.basket__items {
	position: absolute;
	top: 100%;
    z-index: 9;
	right: 0;
	visibility: hidden;
	border: 1px solid $color-grey;
	background-color: $color-grey;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
	width: 405px;

	h3 {
		color: $color-primary;
		font-weight: normal;
		margin-bottom: 35px;
		text-align: left;
	}

	.btn {
		font-size: 1.1em;
		margin-bottom: 0;
	}
}

.triangle-around {
	position: relative;
	margin-top: 30px;
}

.triangle {
	border-style: solid;
	border-color: transparent $color-grey transparent transparent;
	width: 0;
	position: absolute;
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	border-width: 20px 20px 20px 0;
	right: 35%;
	top: -30px;
	z-index: 1;
	visibility: hidden;
}

.triangle:after {
	content: ' ';
	position: absolute;
	border-style: solid;
	border-color: transparent $color-grey transparent transparent;
	width: 0;
	border-width: 20px 20px 20px 0;
	top: -20px;
	left: 1.6px;
}

.basket__hover--total {
	padding: 25px;
}

.basket-hover--total {
	font-weight: 500;
	color: $color-text;
	font-size: 1.33em;
	float: left;
	text-transform: uppercase;
}

.basket-hover-total--amount {
	display: inline-block;
	float: right;
	color: $color-bg-hero;
	font-weight: 500;
	font-size: 1.83em;
}

.basket--amount {
	text-align: left;
	color: $color-text;
	font-weight: 500;
	font-size: 1.1em;
	margin: 10px 0 35px 0;

	span {
		color: $color-ligh-blue;
	}
}


