﻿.review {
	margin-bottom: 65px;

	h2 {
		text-align: center;
		font-weight: 500;
		color: $color-bg-hero;

		@include media('<=phone') {
			font-size: 1.4em;
		}
	}
}

.review__page {
	margin-bottom: 35px;
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
	padding: 25px 15px;
}


.review__quote {
	font-style: italic;
	font-weight: 400;
	text-align: center;
	max-width: 980px;
	margin: auto;
	padding: 0 35px;

	@include media('<=phone') {
		font-size: 0.9em;
	}
}

.review__stars {
	margin: 35px auto 0;
	text-align: center;
	color: $color-yellow;

	i {
		margin-right: 2px;
	}
}

.review__name {
	text-align: center;
	color: $color-primary;
	font-weight: 600;
}

.review--name {
	color: $color-bg-hero;
	font-weight: 600;
	margin-bottom: 10px;
}

.review--stars {
	margin: 0 auto 15px;
	min-height: 30px;
	font-size: 1em;

	i {
		color: $color-stars-review;
	}
}

.review--quote {
	font-style: italic;
	font-weight: 400;
	max-width: 980px;
	margin: auto;

	@include media('<=phone') {
		font-size: 0.9em;
	}
}










.review-stars {
	position: relative;
	width: 125px;
	height: 25px;
	line-height: 25px;
	font-size: 1em;
	display: block;
	float: left;
}


.review-stars .stars {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	overflow: hidden;
}

.review-stars .stars.blank {
	width: 100%;
	color: $color-light-text;
}

.review-stars .stars.full {
	color: $color-yellow;
}

.review-stars .star-connect {
	width: 125px;
	display: block;
}

.review-stars .stars i {
	width: 25px;
	height: 25px;
	line-height: 25px;
	float: left;
}

#review {
	h1 {
		font-weight: 400;
	}

	span {
		color: $color-text;
		margin: 15px 0;
		display: block;
	}
}

.reviews__new {
	background-color: $color-bg-hero;
	border: 1px solid $color-light-grey;
	padding: 15px 15px 0;
	border-radius: 2px;
	display: block !important;

	.editor-label.no--comment {
		line-height: 40px;
	}

	.editor-label {
		color: $color-text;
	}

	.editor-label.comment {
		margin-bottom: 10px;
		max-height: 60px;
		overflow: hidden;
	}

	.btn {
		margin: 30px auto;
		max-width: 300px;
	}

	h2 {
		font-size: 1.4em;
		margin-bottom: 15px;
	}

	a {
		display: none;
	}
}

.reviews__new.hiding {
	a {
		display: block;
		margin-bottom: 10px;
		font-size: 0.8em;
		text-transform: uppercase;
		text-decoration: underline;
		font-weight: 600;
	}
}

.rev-view {
	width: 100%;
	max-width: 600px;

	.fancybox-title-float-wrap .child {
		display: none;
	}

	.editor-label.comment {
		max-height: none;
	}
}

.rev-view .reviews__new {
	width: 100%;
	max-width: 560px;
}
.rev-view a {
	display: none !important;
}


.reviews--g-cap {
	position: relative;
}

.review--split {
	padding: 0;
}

.review--left {
	position: static;
}

.review--right {
	float: right;
}

.review--help {
	padding: 15px;
	color: $color-white;
	background-color: $color-primary;
	font-weight: 700;
	margin: 15px 10px;
	border-radius: 2px;
}

.review--rate {
	vertical-align: middle;
	line-height: 35px;
}

.review--full, .review--selected {
	color: $color-yellow;
}

.review--capt {
	@include media('>desktop') {
		width: 32%;
	}

	@include media('>phone') {
		float: left;
		float: left;
	}
}
.review--complete {
	@include media('>desktop') {
		width: 16%;
		margin: 12px 0;
	}

	@include media('>phone') {
		float: right;
	}
}

.review__rating {
	padding: 15px 30px;

	.section {
		background-color: $color-light-grey;
		border-radius: 2px;
	}

	.title {
		text-align: center;
		padding-top: 23px;
	}
}

.review__rating--key {
	padding: 15px 0;
}

.rev-pnt {
	padding-left: 25px;
	margin: 15px 0;

	li {
		color: $color-primary;

		span {
			color: $color-text;
		}
	}
}

.review--capt {
	.g-recaptcha {
		float: right;
		margin-top: 35px;

		@include media('<=phone') {
			float: none;
			margin: 35px auto;
		}
	}
}

.review--box {
	margin-bottom: 20px;

/*	@include media('>phone') {
		padding: 0 10px !important;
	}*/

}

.review__customers {
	h2{ margin-bottom: 25px;}
}

.cust-revs {
	font-size: 0.85em;

	.sun {
		margin: 10px 0;
	}

	i {
		width: 20px !important;
	}

	.review-stars {
		font-size: 1.2em;
	}
}
.review--box:nth-child(4n+1) {
	clear: both;
}