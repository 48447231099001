﻿.conpage{
    border: 0.5px solid #707070;
    background-color: #EAEAEA;
    border-radius: 5px;
    margin-top:10px;
    padding:25px 25px 15px 25px;
    strong{ color:$color-bg-hero;  font-size:1.3em; display:block; font-weight: 500; }
    span.toptext{ display:block; margin:10px 0 15px 0; font-size:0.9em; }
    .hide{ display:none; }
    .btn{ margin-top:0; }
}

.all-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer {
	background-color: $color-grey;
	color: $color-text;
	font-size: 1em;


	h2 {
		color: $color-white;
		text-align: center;
		margin-bottom: 15px;
	}

	.sml-full{
		@include media("<=sm-phone"){
			width: 100% !important;
			text-align: left;
			padding-bottom: 15px;
		}
	}
}

.footer--logo {
	img {
		margin: auto;
		display: block;
	}
}

.footer--info {
	display: block;
	margin: 10px 0 0;
	font-size: 0.85em;

	i {
		margin-right: 15px;
	}

	.foot--left {
		margin-left: 30px;
	}

	.custom--highlight {
		font-weight: 600;
	}
}
.footer--info.bottom {
	margin-top: 2px;
}

.footer--info.bottomer{
	margin-top: 10px;
}

.footer--locations {
	display: flex;
	gap: 40px;
}


.footer--tel {
	a {
		font-weight: 600;
		color: $color-orange;
		display: block;
		font-size: 1.6em;
		text-decoration: none;
		@include media('<desktop','>tablet') {
			font-size: 1.2em;
		}

		@include media('<=tablet') {
			font-size: 1em;
		}

		&:hover {
			color: $color-primary;
		}
	}
}

.footer--email {
	a {
		font-weight: 600;
		color: $color-ligh-blue;
		display: block;
		font-size: 1.1em;
		text-decoration: none;

		@include media('<desktop','>tablet') {
			font-size: 1em;
		}

		@include media('<=tablet') {
			font-size: 1em;
		}

		&:hover {
			color: $color-primary;
		}
	}
}

.footer--legal {
	text-align: center;

	a {
		font-size: 0.6em;
		color: $color-text;
		text-decoration: none;

		@include media('<desktop','>tablet') {
			font-size: 0.55em;
		}

		@include media('<=tablet') {
			font-size: 0.55em;
		}

		&:hover {
			color: $color-primary;
		}
	}

	.dash--break {
		color: $color-text;

		@include media('<desktop','>tablet') {
			margin: 0 3px;
		}
	}
}

.social__media {
	margin: auto;
	text-align: center;

    &.confirm{
        text-align:left;
        margin-top:-20px;
        .footer--icons {
            height:50px; width:50px;line-height: 50px; font-size:1.5em;
        }
    }

	.footer--icons {
		background-color: $color-ligh-blue;
		color: $color-white;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 50px;
		margin: 15px auto;
		text-decoration: none;

		&:hover {
			background-color: $color-primary;
		}
	}
}

.footer--title.finance{ margin:10px 0; }

.footer--title {
	font-size: 1.1em; 
	color: $color-bg-hero;
	font-weight: 500;

	@include media('<=tablet') { 
		font-size: 1em;
	}

	i {
		margin-right: 15px;
		vertical-align: middle;
		font-size: 1.6em;
	}
}


.foot--links {
	color: $color-text;
	display: block;
	margin: 5px 0;
	font-size: 0.9em;
	text-decoration: none;
	i{
		color: $color-orange; 
		margin-right: 10px;
	}

	&:hover {
		color: $color-primary;
	}
}

.footer--col-last {
	@include media('<=phone') {
		margin-bottom: 15px;
	}
}

.footer--link-smaller {
	margin-bottom: 15px;

	@include media('<=phone') {
		margin-bottom: 30px;
	}

    @media screen and (max-width:420px) {
        width:100%;
        margin-bottom:20px;
    }

	.foot--links {
		@include media('<=phone') {
			margin: 20px 0;
		}
	}
}

.footer--title.drop {
	i {
		@include media('>=phone') {
			display: none;
		}

		@include media('<=phone') {
			display: block;
			float: right;
			color: $color-orange;
		}
	}
}

.foot--links.closed {
	@include media('<=phone') {
		display: none;
	}
}

@include media("<=phone") {
	.footer--logo {
		margin-top: 30px;

		@include media('<=desktop') {
			margin-top: 0px
		}
	}

	@media screen and (max-width:420px) {
		.footer__link {
			padding: 50px 5px;
		}
	}
}

.footer__link {
	padding: 50px 0;
	display: flex;
	justify-content: space-between;
	grid-gap: 10px;

	@include media('<=desktop') {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	@include media('<=phone') {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	@include media('<=sm-phone') {
		display: grid;
		grid-template-columns: 1fr;
	}


	@include media('<=phone') {
		padding: 50px 15px 20px 15px;
	}

	.dw-md-3 {
		@include media('>desktop') {
		}
	}

	.footer--below {

		@include media('<=phone') {
			clear: both;
		}
	}

	.footer--link-smaller {
		/*@include media('<=phone') {
			display: none;
		}*/
	}



	.dw-sm-4:nth-child(3n+1) {
		@include media('<=tablet') {
			clear: both;
		}
	}

	.links {
		margin-top: 10px;

		@include media('<=tablet') {
			margin-top: 10px;
		}
	}
}

	.btm--links {
		margin: 15px 0;
		font-size: 0.65em;
		color: $color-footer-grey;

		@include media('<=phone') {
			text-align: center;
		}

		.right {
			@include media('>tablet') {
				text-align: right;
			}
		}
	}

	.dw--link {
		color: $color-footer-grey;

		&:hover {
			color: $color-primary;
		}
	}

	.footer--line {
		border: 1px solid $color-bg-hero;
		margin-top: 50px;
	}

	.footer__newsletter {
		background-color: $color-bg-hero;
		margin: auto;

		@include media('<=phone') {
			padding-bottom: 25px;
		}

		.footer__news {
			font-weight: 400;
			color: $color-white;
			display: inline-block;
			padding: 20px 15px 15px;

			@include media('<desktop','>tablet') {
				padding: 20px 6px 15px;
			}

			@include media('<=phone') {
				text-align: center;
			}

			i {
				color: $color-orange;
				margin-right: 15px;
				font-size: 2.2em;
				vertical-align: middle;

				@include media('<desktop','>tablet') {
					font-size: 1.6em;
					margin-right: 8px;
				}
			}

			span {
				font-size: 0.9em;

				@include media('<desktop','>tablet') {
					font-size: 0.75em;
				}

				@include media('<=tablet') {
					font-size: 1.15em;
				}

				@include media('<=phone') {
					display: block;
					margin-top: 10px;
					font-size: 1.2em;
				}
			}
		}

		.grecaptcha-badge {
			/*display: none;*/
		}

		input[type=text] {
			height: 40px;
			line-height: 40px;
			max-width: 380px;
			color: $color-orange;
			margin: 15px 0;
			@include primary-font-stack;

			@include media('<=tablet') {
				max-width: 480px;
			}
		}

		.btn {
			height: 40px;
			line-height: 40px;
			max-width: 200px;
			@include primary-font-stack;

			@include media('<=phone') {
				margin: auto;
				max-width: none;
			}
		}

		.loading-help .loading-special {
			background-color: $color-orange;
			margin: 15px auto;
		}
	}
