﻿.popup {
     * {
        transition: none !important;
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: #000;
        opacity: 0.3;
        top: 0px;
        left: 0px;
        position: fixed;
        z-index: 99999;
    }

    .pop-content {
        position: fixed;
        top: 50%;
        left: 50%;
        background-color: #fff;
        border-radius: 3px;
        z-index: 999999;
        transform: translate(-50%, -50%);
        width: calc(100% - 30px);
        max-width: 800px;
        overflow-y: auto;
        max-height: 80%;

        .inner-rel {
            position: relative;
            padding: 60px 15px 15px 15px;

            img{
                max-width: 300px;
                width: 100%;
            }
            .step__text{
                margin: 0 auto;
                .row{
                    margin: 0px auto;
                    padding: 5px 10px;

                    &:nth-child(odd){
                        background-color: #eee;
                    }

                    .section{
                        position: relative;
                        .titl{
                            width: calc(100% - 90px);
                        }
                        .pri{
                            position: absolute;
                            right: 15px;
                            padding: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                            text-align: right;
                            width: 90px;
                            span{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

            .fa-times {
                position: absolute;
                top: 15px;
                right: 15px;
                width: 35px;
                line-height: 35px;
                text-align: center;
                border-radius: 100%;
                background-color: $color-primary;
                color: #fff;
                cursor: pointer;
                font-size: 1.2em;
                display: block;
                z-index: 10;

                &:hover {
                    background-color: $color-orange;
                }
            }
        }
    }
}
