﻿.faqs {
	padding-bottom: 10px;
    @include media('<=phone') {
		padding-bottom: 0px;
	}
}

.faq__top {
	cursor: pointer;
	padding: 17px 10px;
	background-color: $color-faqs;
	color: $color-bg-hero;
	position: relative;
	margin: 15px 0;
	border-radius: 2px;
	

	i {
		position: absolute;
		transform: translateY(-50%);
		right: 15px;
		top: 50%;
		text-align: center;
		font-size: 1.8em;
		color: $color-bg-hero;
		line-height: 60px;
		vertical-align: middle;
	}
}

.faq--title {
	font-weight: 600;
	font-size: 1.1em;
    padding: 0 50px 0 0;
}

.faq__block.closed {
	height: auto;
}

.faq__content.closed {
	height: 0;
	overflow: hidden;
}

.faq__content {
	padding: 0 15px;

	ul {
		li {
			span {
				font-weight: 700;
			}
		}
	}

	img {
		float: right;

		@include media('<=phone') {
			margin: auto;
			float: none;
		}
	}

	a {
		color: $color-primary;
		font-weight: 600;
		text-decoration: underline;

		&:hover {
			color: $color-orange;
			text-decoration: none;
		}
	}
}

.faq--info {
	color: $color-text;
	display: block;
	font-size: 0.9em;
    &.center-block strong a{ font-size:1.3em; }

	ol { padding-left: 30px; }

}
